import { WholesalerInput } from "../types";

export default class Wholesaler {
  public id?: number;
  public name: string;
  public dea: string;
  public hin: string;
  public otherId: string;
  public businessUnitRef: string;
  public archived: boolean;
  public createdAt?: Date;

  constructor({
    id,
    name,
    dea,
    hin,
    otherId,
    businessUnitRef,
    archived,
    createdAt,
  }: WholesalerInput) {
    this.id = id;
    this.name = name;
    this.dea = dea;
    this.hin = hin;
    this.otherId = otherId;
    this.businessUnitRef = businessUnitRef;
    this.archived = archived;
    this.createdAt = createdAt;
  }

  static createEmpty() {
    return new Wholesaler({
      name: "",
      dea: "",
      hin: "",
      otherId: "",
      businessUnitRef: "",
      archived: false,
    });
  }
}
