import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { isNil } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { UseMutationResult } from "react-query";

import { ErrorResult, FilterOptionType } from "../types";

type Props<Type, TypeInput> = {
  createInput: TypeInput;
  dialogContent: JSX.Element;
  dialogTitle: string;
  label: string;
  noOptionsText: string;
  onInputChange: (event: any, newInputValue: string) => void;
  options: any[];
  setModel: (model: Type) => void;
  setValue: (arg0: Type | string) => void;
  useSave: (
    input: any,
  ) => UseMutationResult<Type | ErrorResult, unknown, void, unknown>; //QueryObserverResult<Type>;
  value: string | Type;
};

export default function CreatableAutocomplete<Type, TypeInput>({
  createInput,
  dialogTitle,
  dialogContent,
  label,
  noOptionsText,
  onInputChange,
  options,
  setModel,
  setValue,
  useSave,
  value,
}: Props<Type, TypeInput>) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, toggleOpen] = React.useState(false);

  const { mutateAsync } = useSave(createInput);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const res = await mutateAsync();
    if (typeof res === "object" && !isNil(res) && "errors" in res) {
      const errorStr = res.errors.map((message: string) => message).join(", ");
      enqueueSnackbar(errorStr, { variant: "error" });
      // handleClose();
    } else {
      setModel(res as Type);
      toggleOpen(false);
    }
  };

  const handleClose = () => {
    setValue("");
    toggleOpen(false);
  };

  const filter = createFilterOptions<FilterOptionType<Type>>();

  return (
    <React.Fragment>
      <Autocomplete
        id="searchbar-autocomplete"
        sx={{ width: "100%", "MuiAutocomplete-root": { width: "100%" } }}
        options={options}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              label: `Add "${params.inputValue}"`,
              value: params.inputValue,
            });
          }
          return filtered;
        }}
        renderInput={(params) => <TextField {...params} label={label} />}
        getOptionLabel={(option) => {
          return typeof option === "string" ? option : option.label;
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setValue(newValue);
            });
          } else if (newValue && newValue.inputValue) {
            // when it does not find the value in the search and you click add
            toggleOpen(true);
            setValue(newValue.inputValue);
          } else if (newValue && newValue?.value) {
            setModel(newValue.value);
            setValue(newValue);
          }
        }}
        onInputChange={onInputChange}
        value={value}
        fullWidth
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          {dialogContent}
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
