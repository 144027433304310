import { Box, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import ContractProductPriceAPI from "../api/ContractProductPrice.api";
import { ContractProductPriceInput, PriceClassification } from "../types";
import CurrencyTextField from "./CurrencyTextField";
import PriceClassificationSelector from "./PriceClassificationSelector";

type Props = {
  contractId: number;
  productId: number;
  contractProductPrice: ContractProductPriceInput;
  handleClose: () => void;
};

export default function ContractProductPriceForm({
  contractId,
  productId,
  contractProductPrice,
  handleClose,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [priceClassification, setPriceClassification] =
    React.useState<PriceClassification>(
      contractProductPrice.priceClassification || "unit",
    );
  const [price, setPrice] = React.useState(contractProductPrice.price);
  const [effectiveDate, setEffectiveDate] = React.useState<Dayjs | null>(
    contractProductPrice.effectiveDate
      ? dayjs(contractProductPrice.effectiveDate, "YYYY-MM-DD")
      : null,
  );
  const [endDate, setEndDate] = React.useState(
    contractProductPrice.endDate
      ? dayjs(contractProductPrice.endDate, "YYYY-MM-DD")
      : null,
  );

  const { mutateAsync, isLoading } = ContractProductPriceAPI.useSave({
    ...contractProductPrice,
    priceCents: Math.round(parseFloat(price) * 100),
    priceClassification,
    effectiveDate: effectiveDate ? effectiveDate.toDate() : effectiveDate,
    endDate: endDate ? endDate.toDate() : endDate,
    productId,
    contractId,
  });

  const onSave = async () => {
    const res = await mutateAsync();
    if ("errors" in res) {
      res.errors.forEach((message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      enqueueSnackbar("Saved successfully", { variant: "success" });
      handleClose();
    }
  };

  const throttledOnSave = React.useCallback(debounce(onSave, 1000), []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <CurrencyTextField
          fullWidth={true}
          variant="outlined"
          label="Amount"
          value={price}
          setValue={(value) => setPrice(value)}
        />
        <PriceClassificationSelector
          priceClassification={priceClassification}
          setPriceClassification={setPriceClassification}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <DatePicker
          label="Effective Date"
          value={effectiveDate}
          onChange={(value: any) => {
            setEffectiveDate(value);
          }}
          sx={{ width: "100%" }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(value: any) => {
            setEndDate(value);
          }}
          sx={{ width: "100%" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "4rem",
          marginBottom: "1rem",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={throttledOnSave}
          disabled={isLoading}
        >
          Save
        </Button>
        {contractProductPrice.id && (
          <DeleteButton
            id={contractProductPrice.id}
            handleClose={handleClose}
          />
        )}
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Box>
  );
}

type PropsDeleteButton = {
  id: number;
  handleClose: () => void;
};

function DeleteButton({ id, handleClose }: PropsDeleteButton) {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, mutateAsync } = ContractProductPriceAPI.useDelete(id);

  const handleDelete = async () => {
    const res = await mutateAsync();
    if ("errors" in res) {
      res.errors.forEach((message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      enqueueSnackbar("Deleted successfully", { variant: "success" });
      handleClose();
    }
  };

  const throttledHandleDelete = React.useCallback(
    debounce(handleDelete, 1000),
    [],
  );

  return (
    <Button
      variant="contained"
      color="error"
      onClick={throttledHandleDelete}
      disabled={isLoading}
    >
      Delete
    </Button>
  );
}
