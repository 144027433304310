import { Box, Typography } from "@mui/material";
import React from "react";

import ChargebackDebitMemoInvestigatedListTable from "../components/ChargebackDebitMemoInvestigatedListTable";
import Layout from "../components/Layout";
import LogoBox from "../components/LogoBox";

export default function ChargebackDebitMemoInvestigatedList() {
  return (
    <Layout>
      <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography variant="h2">Investigated</Typography>
            <Typography variant="h6">Ready for final approval</Typography>
          </Box>
          <LogoBox />
        </Box>
        <ChargebackDebitMemoInvestigatedListTable />
      </Box>
    </Layout>
  );
}
