import { isEmpty, isNil } from "lodash";
import React from "react";

import { BusinessUnit } from "../types";

type Props = {
  children: JSX.Element;
};

interface IBusinessUnitContext {
  currentBusinessUnit?: BusinessUnit;
  loadedLocalStorage: boolean;
  setCurrentBusinessUnit: (b?: BusinessUnit) => void;
}

const BusinessUnitContext = React.createContext<Partial<IBusinessUnitContext>>(
  {},
);

export function BusinessUnitProvider({ children }: Props) {
  const [loadedLocalStorage, setLoadedLocalStorage] = React.useState(false);
  const [currentBusinessUnit, setCurrentBusinessUnit] = React.useState<
    BusinessUnit | undefined
  >(undefined);

  React.useEffect(() => {
    const data = window.localStorage.getItem(
      process.env.REACT_APP_LOCAL_STORAGE!,
    );
    if (!(isNil(data) || isEmpty(data))) {
      setCurrentBusinessUnit(JSON.parse(data));
    }
    setLoadedLocalStorage(true);
  }, []);

  React.useEffect(() => {
    if (currentBusinessUnit) {
      window.localStorage.setItem(
        process.env.REACT_APP_LOCAL_STORAGE!,
        JSON.stringify(currentBusinessUnit),
      );
    }
  }, [currentBusinessUnit]);

  return (
    <BusinessUnitContext.Provider
      value={{
        currentBusinessUnit,
        loadedLocalStorage,
        setCurrentBusinessUnit: (b?: BusinessUnit) => setCurrentBusinessUnit(b),
      }}
    >
      {children}
    </BusinessUnitContext.Provider>
  );
}

export function useBusinessUnitContext() {
  return React.useContext(BusinessUnitContext);
}
