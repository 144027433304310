import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  ErrorResult,
  GroupMemberEligibility,
  GroupMemberEligibilityInput,
  ListResponseData,
  Order,
  Param,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleQueryError,
  handleSave,
} from "./handler";

const methods = {
  useList: ({
    groupId,
    order,
    orderBy,
    page,
    query,
  }: {
    groupId: number;
    order: Order;
    orderBy: keyof GroupMemberEligibility;
    page?: number;
    query?: string;
  }) => {
    const navigate = useNavigate();
    const params: Param[] = [];
    if (groupId) {
      params.push({ key: "group_id", value: groupId });
    }
    return useQuery<ListResponseData<GroupMemberEligibility>>({
      cacheTime: 0,
      queryKey: [
        "group_member_eligibilities",
        groupId,
        order,
        orderBy,
        page,
        query,
      ],
      queryFn: () =>
        handleList({
          baseUrl: "group_member_eligibilities",
          params,
          order,
          orderBy,
          page,
          query,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<GroupMemberEligibility>({
      cacheTime: 0,
      queryKey: ["group_member_eligibilities", id],
      queryFn: () =>
        handleDetail({ id, baseUrl: "group_member_eligibilities" }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (groupMemberEligibilityInput: GroupMemberEligibilityInput) => {
    const navigate = useNavigate();
    return useMutation<GroupMemberEligibilityInput | ErrorResult>({
      mutationFn: () =>
        handleSave({
          baseUrl: "group_member_eligibilities",
          input: groupMemberEligibilityInput,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number } | ErrorResult>({
      mutationFn: () =>
        handleDelete({ id, baseUrl: "group_member_eligibilities" }),
      onError: handleQueryError(navigate),
    });
  },
};

export default methods;
