import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  ErrorResult,
  ListResponseData,
  Order,
  Param,
  Product,
  ProductWacPrice,
  ProductWacPriceInput,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleQueryError,
  handleSave,
} from "./handler";

const methods = {
  useList: ({
    product,
    order,
    orderBy,
    page,
    query,
  }: {
    product: Product;
    order: Order;
    orderBy: keyof ProductWacPrice;
    page?: number;
    query?: string;
  }) => {
    const navigate = useNavigate();
    const params: Param[] = [];
    if (product) {
      params.push({ key: "product_id", value: product.id });
    }
    return useQuery<ListResponseData<ProductWacPrice>>({
      cacheTime: 0,
      queryKey: ["product_wac_prices", product, order, orderBy, page, query],
      queryFn: () =>
        handleList({
          baseUrl: "product_wac_prices",
          params,
          order,
          orderBy,
          page,
          query,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<ProductWacPrice>({
      cacheTime: 0,
      queryKey: ["product_wac_prices", id],
      queryFn: () => handleDetail({ id, baseUrl: "product_wac_prices" }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (productWacPriceInput: ProductWacPriceInput) => {
    const navigate = useNavigate();
    return useMutation<ProductWacPrice | ErrorResult>({
      mutationFn: () =>
        handleSave({
          baseUrl: "product_wac_prices",
          input: productWacPriceInput,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number } | ErrorResult>({
      mutationFn: () => handleDelete({ id, baseUrl: "product_wac_prices" }),
      onError: handleQueryError(navigate),
    });
  },
};
export default methods;
