import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import UserAPI from "../api/User.api";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import UserForm from "../components/UserForm";

export default function GroupEdit() {
  const { id } = useParams();
  const { data, isLoading } = UserAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">User Edit</Typography>

        {isLoading ? <Loading /> : data && <UserForm user={data} />}
      </Box>
    </Layout>
  );
}
