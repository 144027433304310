import { Box, Button, Typography } from "@mui/material";
import React from "react";

import AutoImportedFileAPI from "../api/AutoImportedFile.api";
import AutoImportedFileListTable from "../components/AutoImportedFileListTable";
import EmptyLayout from "../components/EmptyLayout";

export default function AutoImportedFileList() {
  const { mutate, isLoading } = AutoImportedFileAPI.useReprocessAllErrors();
  return (
    <EmptyLayout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "stretch",
          }}
        >
          <Typography variant="h2">Auto Imported Files</Typography>
        </Box>
        <Box>
          <Button
            variant={"contained"}
            onClick={() => mutate()}
            disabled={isLoading}
          >
            Reprocess Files with Errors
          </Button>
        </Box>
        <AutoImportedFileListTable />
      </Box>
    </EmptyLayout>
  );
}
