import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import Layout from "../components/Layout";
import WholesalerDistributionCenterForm from "../components/WholesalerDistributionCenterForm";
import WholesalerDistributionCenterModel from "../models/WholesalerDistributionCenter.model";

export default function WholesalerDistributionCenterNew() {
  const { wholesalerId } = useParams();
  const emptyWholesalerDistributionCenter =
    WholesalerDistributionCenterModel.createEmpty();
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">New Wholesaler Distribution Center</Typography>
        {wholesalerId && (
          <WholesalerDistributionCenterForm
            addressInput={emptyWholesalerDistributionCenter.address}
            wholesalerId={wholesalerId}
            wholesalerDistributionCenter={emptyWholesalerDistributionCenter}
          />
        )}
      </Box>
    </Layout>
  );
}
