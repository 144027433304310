import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, Modal, TableCell, Typography } from "@mui/material";
import { startCase } from "lodash";
import React from "react";
import CurrencyFormat from "react-currency-format";

import ProductWholesalerPriceAPI from "../api/ProductWholesalerPrice.api";
import {
  Order,
  ProductWholesalerPrice,
  TableColumn,
  Wholesaler,
} from "../types";
import { extractDate } from "../utils";
import ListTable from "./ListTable";
import Loading from "./Loading";
import ProductWholesalerPriceModal from "./ProductWholesalerPriceModal";

const columns: TableColumn<ProductWholesalerPrice>[] = [
  { field: "productNdc", label: "Product NDC" },
  { field: "priceCents", label: "Price" },
  { field: "priceClassification", label: "Price Classification" },
  { field: "effectiveDate", label: "Effective Date" },
  { field: "endDate", label: "End Date" },
];

function buildTableRow(
  productWholesalerPrice: ProductWholesalerPrice,
): JSX.Element[] {
  return [
    <TableCell key={"productNdc"}>
      {productWholesalerPrice.productNdc}
    </TableCell>,
    <TableCell key={"priceCents"}>
      <CurrencyFormat
        value={productWholesalerPrice.price}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    </TableCell>,
    <TableCell key={"priceClassification"}>
      {startCase(productWholesalerPrice.priceClassification)}
    </TableCell>,
    <TableCell key={"effectiveDate"}>
      {extractDate(productWholesalerPrice.effectiveDate)}
    </TableCell>,
    <TableCell key={"endDate"}>
      {extractDate(productWholesalerPrice.endDate)}
    </TableCell>,
  ];
}

type Props = {
  wholesaler: Wholesaler;
};

export default function ProductWholesalerPriceManagement({
  wholesaler,
}: Props) {
  const [productWholesalerPriceId, setProductWholesalerPriceId] =
    React.useState<number>();

  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof ProductWholesalerPrice>("endDate");

  const { data, refetch } = ProductWholesalerPriceAPI.useList({
    wholesaler,
    order,
    orderBy,
    page,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ProductWholesalerPrice,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick =
    (productWholesalerPrice: ProductWholesalerPrice) => (event: unknown) => {
      handleProductWholesalerPriceClick(productWholesalerPrice);
    };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setProductWholesalerPriceId(undefined);
    setOpen(false);
    refetch();
  };

  const handleProductWholesalerPriceClick = (
    productWholesalerPrice: ProductWholesalerPrice,
  ) => {
    setOpen(true);
    setProductWholesalerPriceId(productWholesalerPrice.id);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginTop: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="h4">Product WAC Exceptions</Typography>
        <IconButton
          aria-label="Create a Group"
          size="large"
          onClick={handleOpen}
        >
          <AddCircleIcon color="primary" />
        </IconButton>
      </Box>

      {data ? (
        <ListTable
          columns={columns}
          buildTableRow={buildTableRow}
          handleChangePage={handleChangePage}
          handleRowClick={handleRowClick}
          handleRequestSort={handleRequestSort}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        />
      ) : (
        <Loading />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <ProductWholesalerPriceModal
            id={productWholesalerPriceId}
            wholesalerId={wholesaler.id}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </Box>
  );
}
