import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BusinessUnit,
  ChargebackDebitMemoWithTags,
  ListResponseData,
  Order,
  Param,
  Tag,
  TagInput,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleQueryError,
  handleSave,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useList: ({
    businessUnit,
    order,
    orderBy,
    page,
    query,
  }: {
    businessUnit?: BusinessUnit;
    order: Order;
    orderBy?: keyof Tag;
    page?: number;
    query?: string;
  }) => {
    const params: Param[] = [];
    if (businessUnit) {
      params.push({
        key: "business_unit_id",
        value: businessUnit.id,
      });
    }
    return useQuery<ListResponseData<Tag>>({
      cacheTime: 0,
      queryKey: ["tags", businessUnit?.id, order, orderBy, page, query],
      queryFn: () =>
        handleList({
          baseUrl: "tags",
          order,
          orderBy,
          page,
          query,
          params,
        }),
    });
  },
  useChargebackDebitMemoList: ({
    businessUnit,
    order,
    orderBy,
    page,
    query,
  }: {
    businessUnit?: BusinessUnit;
    order: Order;
    orderBy?: keyof ChargebackDebitMemoWithTags;
    page?: number;
    query?: string;
  }) => {
    const params: Param[] = [];
    if (businessUnit) {
      params.push({
        key: "business_unit_id",
        value: businessUnit.id,
      });
    }
    return useQuery<ListResponseData<ChargebackDebitMemoWithTags>>({
      cacheTime: 0,
      queryKey: [
        "tags_chargeback_debit_memos",
        businessUnit?.id,
        order,
        orderBy,
        page,
        query,
      ],
      queryFn: () =>
        handleList({
          baseUrl: "chargeback_debit_memos/with_tags",
          order,
          orderBy,
          page,
          query,
          params,
        }),
    });
  },
  useSearch: ({
    businessUnit,
    query,
  }: {
    businessUnit?: BusinessUnit;
    query: string;
  }) => {
    const navigate = useNavigate();
    const params: Param[] = [];
    if (businessUnit) {
      params.push({
        key: "business_unit_id",
        value: businessUnit.id,
      });
    }
    if (query) {
      params.push({
        key: "query",
        value: query,
      });
    }
    return useQuery<Tag[]>({
      queryKey: ["tags_search", businessUnit?.id, query],
      queryFn: () =>
        handleSimpleList({
          url: `tags/search`,
          params,
        }),
      enabled: !!query && !!businessUnit,
      onError: handleQueryError(navigate),
    });
  },
  useChargebackDebitMemoByTagList: ({
    tagId,
    order,
    orderBy,
    page,
    query,
  }: {
    tagId: number;
    order: Order;
    orderBy?: keyof ChargebackDebitMemoWithTags;
    page?: number;
    query?: string;
  }) => {
    return useQuery<ListResponseData<ChargebackDebitMemoWithTags>>({
      cacheTime: 0,
      queryKey: ["tag_with_chargeback_debit_memos", tagId],
      queryFn: () =>
        handleList({
          baseUrl: `tags/${tagId}/debit_memos`,
          order,
          orderBy,
          page,
          query,
          params: [],
        }),
    });
  },
  useShow: (id?: string) => {
    return useQuery<Tag>({
      cacheTime: 0,
      queryKey: ["tags", id],
      queryFn: () =>
        handleDetail({
          id: id,
          baseUrl: "tags",
        }),
    });
  },
  useSave: (input: TagInput) => {
    return useMutation<Tag>({
      mutationFn: () =>
        handleSave({
          baseUrl: "tags",
          input,
        }),
      retry: 1,
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({ queryKey: ["tags"] });
      },
    });
  },
  useAttach: ({
    tagId,
    chargebackDebitMemoIds,
  }: {
    tagId?: number;
    chargebackDebitMemoIds: number[];
  }) => {
    return useMutation({
      mutationFn: () =>
        handleSave({
          baseUrl: `tags/${tagId}/attach`,
          input: { chargeback_debit_memo_ids: chargebackDebitMemoIds },
        }),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ["tags_chargeback_debit_memos"],
        });
      },
    });
  },
  useDetach: ({
    tagId,
    chargebackDebitMemoIds,
  }: {
    tagId?: number;
    chargebackDebitMemoIds: number[];
  }) => {
    return useMutation({
      mutationFn: () =>
        handleSave({
          baseUrl: `tags/${tagId}/detach`,
          input: { chargeback_debit_memo_ids: chargebackDebitMemoIds },
        }),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ["tags_chargeback_debit_memos"],
        });
      },
    });
  },
  useDelete: (id: number) => {
    return useMutation<{ id: number }>({
      mutationFn: () =>
        handleDelete({
          baseUrl: "tags",
          id,
        }),
    });
  },
};

export default methods;
