import { Box, Typography } from "@mui/material";

import GroupForm from "../components/GroupForm";
import Layout from "../components/Layout";
import GroupModel from "../models/Group.model";

export default function GroupNew() {
  const emptyGroup = GroupModel.createEmpty();
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">Group New</Typography>
        <GroupForm group={emptyGroup} />
      </Box>
    </Layout>
  );
}
