import { Collapse, TableCell, TableRow, TextField } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import ChargebackDebitMemoAPI from "../api/ChargebackDebitMemo.api";
import ArchiveButton from "../components/ArchiveButton";
import Loading from "../components/Loading";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { ChargebackDebitMemo, Order, TableColumn } from "../types";
import { centsToDollars, extractDate } from "../utils";
import ChargebackDebitMemoFeedbackLineitems from "./ChargebackDebitMemoFeedbackLineitems";
import ListTableWithCustomRow from "./ListTableWithCustomRow";

const columns: TableColumn<ChargebackDebitMemo>[] = [
  { field: "debitMemo", label: "Debit Memo" },
  { field: "approvedOn", label: "Approved On" },
  { field: "wholesaler", label: "Wholesaler", orderBy: false },
  { field: "totalChargebackLineitems", label: "Lines #", orderBy: false },
  { field: "requiresActionTotal", label: "Errors #", orderBy: false },
  { field: "requestedAmount", label: "Requested $", orderBy: false },
  { field: "calculatedAmount", label: "Calculated $", orderBy: false },
  { field: "payoutAmount", label: "Approved $", orderBy: false },
  { field: "id", label: "", orderBy: false },
];

function buildTableRow(
  chargebackDebitMemo: ChargebackDebitMemo,
): JSX.Element[] {
  return [
    <TableCell key={"debitMemo"}>
      {chargebackDebitMemo.highlight ? (
        <div
          dangerouslySetInnerHTML={{
            __html: chargebackDebitMemo.highlight,
          }}
          className="[&>em]:backgroundColor:yellow"
        />
      ) : (
        chargebackDebitMemo.debitMemo
      )}
    </TableCell>,
    <TableCell key={"approvedOn"}>
      {extractDate(chargebackDebitMemo.approvedOn)}
    </TableCell>,
    <TableCell key={"wholesaler"}>
      {chargebackDebitMemo.wholesaler && chargebackDebitMemo.wholesaler.name}
    </TableCell>,
    <TableCell key={"totalChargebackLineitems"}>
      {chargebackDebitMemo.totalChargebackLineitems}
    </TableCell>,
    <TableCell key={"requiresActionTotal"}>
      {chargebackDebitMemo.requiresActionTotal}
    </TableCell>,
    <TableCell key={"requestedAmount"}>
      {centsToDollars(chargebackDebitMemo.requestedAmount)}
    </TableCell>,
    <TableCell key={"calculatedAmount"}>
      {centsToDollars(chargebackDebitMemo.calculatedAmount)}
    </TableCell>,
    <TableCell key={"payoutAmount"}>
      {centsToDollars(chargebackDebitMemo.payoutAmount)}
    </TableCell>,
    <TableCell key={"id"}>
      <UnapproveChargebackDebitMemoButton id={chargebackDebitMemo.id} />
    </TableCell>,
  ];
}

export default function ChargebackDebitMemoApprovedListTable() {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [query, setQuery] = React.useState<string>("");
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof ChargebackDebitMemo>("approvedOn");
  const [page, setPage] = React.useState<number>(0);

  const { data, isLoading } = ChargebackDebitMemoAPI.useList({
    state: "approved",
    page,
    query,
    order,
    orderBy,
    businessUnit: currentBusinessUnit,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ChargebackDebitMemo,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <TextField
        id="ChargebackDebitMemoApproved-search"
        label="Search field"
        type="search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        fullWidth
      />
      {data && !isLoading ? (
        <ListTableWithCustomRow
          columns={columns}
          handleChangePage={handleChangePage}
          handleRequestSort={handleRequestSort}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        >
          {(data.data || []).map((chargebackDebitMemo) => (
            <ChargebackDebitMemoRow
              chargebackDebitMemo={chargebackDebitMemo}
              key={chargebackDebitMemo.id}
            />
          ))}
        </ListTableWithCustomRow>
      ) : (
        <Loading />
      )}
    </>
  );
}

function ChargebackDebitMemoRow({
  chargebackDebitMemo,
}: {
  chargebackDebitMemo: ChargebackDebitMemo;
}) {
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <React.Fragment>
      <TableRow
        hover
        onClick={() => setOpen((open) => !open)}
        tabIndex={-1}
        sx={{ cursor: "poniter" }}
      >
        {buildTableRow(chargebackDebitMemo)}
      </TableRow>
      <TableRow sx={{ backgroundColor: grey[50] }}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columns.length}
        >
          <Collapse in={open} timeout={500} mountOnEnter>
            <ChargebackDebitMemoFeedbackLineitems
              chargebackDebitMemoId={chargebackDebitMemo.id}
              chargebackDebitMemoApproved={true}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

type UnapproveChargebackDebitMemoButtonProps = {
  id: number;
};

function UnapproveChargebackDebitMemoButton({
  id,
}: UnapproveChargebackDebitMemoButtonProps) {
  const navigate = useNavigate();
  const { mutateAsync } = ChargebackDebitMemoAPI.useUnapprove(id);
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Unapproved successfully", { variant: "success" });
      navigate("/chargebacks/investigated");
    } catch (error) {
      enqueueSnackbar("Unable to unapprove Debit Memo", { variant: "error" });
    }
  };

  return (
    <ArchiveButton
      onDelete={onDelete}
      buttonText={"Unapprove"}
      popupText={
        "This will kick back the debit memo to a require approval state"
      }
    />
  );
}
