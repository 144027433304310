import {
  ChargebackExportGroupReportType,
  ChargebackExportGroupState,
} from "../types";

export default class ChargebackExportGroupModel {
  static stateName(state: ChargebackExportGroupState) {
    if (state === "unprocessed") {
      return "Waiting in queue to be processed";
    } else if (state === "processing") {
      return "Processing";
    } else if (state === "completed") {
      return "Ready to download";
    } else {
      return "Internal Server Error";
    }
  }

  static reportTypeName(reportType: ChargebackExportGroupReportType) {
    if (reportType === "search_by_date") {
      return "By Date Range";
    } else if (reportType === "debit_memo_ids") {
      return "Debit Memos Selected";
    } else {
      return "N/A";
    }
  }
}
