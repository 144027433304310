import { AddressInput, WholesalerDistributionCenterInput } from "../types";
import AddressModel from "./Address.model";

export default class WholesalerDistributionCenter {
  public id?: number;
  public name: string;
  public dea: string;
  public hin: string;
  public dcId: string;
  public effectiveDate?: Date | null;
  public endDate?: Date | null;
  public archived: boolean;
  public createdAt?: Date;
  public address: AddressInput;

  constructor({
    id,
    name,
    dea,
    hin,
    dcId,
    effectiveDate,
    endDate,
    archived,
    createdAt,
    address,
  }: WholesalerDistributionCenterInput) {
    this.id = id;
    this.name = name;
    this.dea = dea;
    this.hin = hin;
    this.dcId = dcId;
    this.effectiveDate = effectiveDate;
    this.endDate = endDate;
    this.archived = archived;
    this.createdAt = createdAt;
    this.address = address;
  }

  static createEmpty() {
    return new WholesalerDistributionCenter({
      name: "",
      dea: "",
      hin: "",
      dcId: "",
      archived: false,
      address: AddressModel.createEmpty(),
    });
  }
}
