import camelcaseKeys from "camelcase-keys";
import { isEmpty, isNil, snakeCase } from "lodash";
import snakeCaseKeys from "snakecase-keys";

import {
  HandleDeleteProps,
  HandleDetailProps,
  HandleSaveProps,
  ListQueryProps,
  ListResponseData,
  MetaPagination,
  Param,
} from "../types";
import { HttpError } from "../types/http-error";
import { convertDatesToStrings, handleDates } from "../utils";
import client from "./client";

export const handleQueryError = (navigate: any) => (err: any) => {
  if (err instanceof HttpError && err.code === 401) {
    navigate("/login");
  }
};

export const handleMutationError =
  (enqueueSnackbar: any) => async (err: any) => {
    if (err instanceof HttpError && err.code === 404) {
      enqueueSnackbar("Oops. Something went wrong when deleting");
    } else if (err instanceof HttpError && err.code === 413) {
      enqueueSnackbar("Oops. Total file size is to large.", {
        variant: "warning",
      });
    } else if (err instanceof HttpError && err.code === 406) {
      enqueueSnackbar(err.message, { variant: "error" });
    } else if (err instanceof HttpError && err.code >= 400) {
      // const text = await reader.read()
      // enqueueSnackbar(`Error: ${text.value}`)
    } else if (err instanceof TypeError) {
      enqueueSnackbar("Oops. Total file size is to large.", {
        variant: "warning",
      });
    }
    // console.warn("handleMutationError", err, err.constructor.name);
  };

export async function handleList<Type>({
  order,
  orderBy,
  page,
  query,
  baseUrl,
  params,
}: ListQueryProps<Type>): Promise<ListResponseData<Type>> {
  try {
    const searchParams = new URLSearchParams();
    if (page) {
      searchParams.set("page", page.toString());
    }
    if (query) {
      searchParams.set("query", query);
    }
    if (order) {
      searchParams.set("order", order);
    }
    if (orderBy) {
      searchParams.set("order_by", snakeCase(orderBy.toString()));
    }
    if (params && !isEmpty(params)) {
      params.forEach((param) => {
        if (!isNil(param["value"])) {
          searchParams.set(param["key"], param["value"]);
        }
      });
    }

    const response: { data: Type[]; meta: MetaPagination } = await client
      .get(baseUrl, {
        searchParams,
      })
      .json();
    const parsedData = response.data
      ? response.data.map((obj) => handleDates(obj))
      : [];
    return camelcaseKeys(
      {
        data: parsedData || ([] as Type[]),
        meta: response.meta as MetaPagination,
      },
      { deep: true },
    ) as ListResponseData<Type>;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleSimpleList<Type>({
  url,
  params,
}: {
  url: string;
  params?: Param[];
}): Promise<Type[]> {
  try {
    const searchParams = new URLSearchParams();
    if (params) {
      params.forEach(({ key, value }) => {
        searchParams.set(key, value);
      });
    }
    const data: Type[] = await client.get(url, { searchParams }).json();
    const parsedData = data ? data.map((obj) => handleDates(obj)) : [];
    return camelcaseKeys(parsedData || ([] as Type[]), {
      deep: true,
    }) as Type[];
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleDetail<Type>({
  id,
  baseUrl,
}: HandleDetailProps): Promise<Type> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data = await client.get(`${baseUrl}/${id}`).json();
    const parsedData = handleDates(data);
    return camelcaseKeys(parsedData as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleDelete<Type>({
  baseUrl,
  id,
}: HandleDeleteProps): Promise<Type> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data: Type = await client.delete(`${baseUrl}/${id}`).json();
    return camelcaseKeys(data as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleSave<Type>({
  baseUrl,
  input,
}: HandleSaveProps): Promise<Type> {
  try {
    // @ts-expect-error this is a hack to make the type checker happy
    const inputWithCorrectDates: Record<string, unknown> =
      convertDatesToStrings(input);
    const inputSnaked = snakeCaseKeys(inputWithCorrectDates, {
      deep: true,
    });
    let data: Type;
    if (typeof input.id === "undefined") {
      data = await client.post(baseUrl, { json: inputSnaked }).json();
    } else {
      data = await client
        .put(`${baseUrl}/${input.id}`, { json: inputSnaked })
        .json();
    }
    const parsedData = handleDates(data);
    return camelcaseKeys(parsedData as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleBasicPost<Type>({
  baseUrl,
  input,
}: HandleSaveProps): Promise<Type> {
  try {
    const inputSnaked = snakeCaseKeys(input, {
      deep: true,
    });
    const data: Type = await client.post(baseUrl, { json: inputSnaked }).json();
    return camelcaseKeys(data as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function handleBasicFetch<Type>({
  url,
  params,
}: {
  url: string;
  params: Param[];
}): Promise<Type> {
  try {
    const searchParams = new URLSearchParams();
    params.forEach(({ key, value }: Param) => {
      searchParams.set(key, value);
    });

    const data: Type = await client.get(url, { searchParams }).json();
    return camelcaseKeys(data as Record<string, unknown>, {
      deep: true,
    }) as Type;
  } catch (error) {
    return Promise.reject(error);
  }
}
