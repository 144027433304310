import { Box, DialogContent, TextField } from "@mui/material";
import React from "react";

import ProductAPI from "../api/Product.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { Product } from "../types";
import CreatableAutocomplete from "./CreatableAutocomplete";

type Props = {
  product?: Product;
  setCurrentProduct: (product: Product) => void;
};

export default function ProductSearchBarAutocomplete({
  setCurrentProduct,
  product,
}: Props) {
  const { currentBusinessUnit } = useBusinessUnitContext();

  const [searchValue, setSearchValue] = React.useState(product?.ndc);
  const [value, setValue] = React.useState<Product | string>(
    product ? product.ndc : "",
  );

  const { data } = ProductAPI.useSearch({
    businessUnit: currentBusinessUnit,
    query: searchValue,
  });

  const [name, setName] = React.useState<string>("");
  const [ndc, setNdc] = React.useState<string>("");
  const [upc, setUpc] = React.useState<string>("");
  const [gtin, setGtin] = React.useState<string>("");
  const [wholesalerProductNumber, setWholesalerProductNumber] =
    React.useState<string>("");
  const [wholesaler, setWholesaler] = React.useState<string>("");
  const [genericName, setGenericName] = React.useState<string>("");
  const [strength, setStrength] = React.useState<string>("");
  const [productFamily, setProductFamily] = React.useState<string>("");
  const [color, setColor] = React.useState<string>("");
  const [shape, setShape] = React.useState<string>("");
  const [productType, setProductType] = React.useState<string>("");

  const options = data ? data.map((o) => ({ label: o.ndc, value: o })) : [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
        flexGrow: 1,
      }}
    >
      <CreatableAutocomplete
        label="Find a Product"
        dialogTitle="Add a new Product"
        noOptionsText="No results"
        setModel={(product: Product) => {
          setCurrentProduct(product);
          setSearchValue(product.ndc);
          setValue(product.ndc);
        }}
        onInputChange={(event, newSearchValue) => {
          setSearchValue(newSearchValue);
        }}
        options={options || []}
        value={value}
        setValue={setValue}
        useSave={ProductAPI.useSave}
        createInput={{
          name,
          ndc,
          upc,
          gtin,
          wholesalerProductNumber,
          wholesaler,
          genericName,
          strength,
          productFamily,
          color,
          shape,
          productType,
          businessUnitId: currentBusinessUnit && currentBusinessUnit.id,
        }}
        dialogContent={
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "1rem",
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              <TextField
                label="Name"
                variant="outlined"
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setName(event.target.value);
                }}
                fullWidth
              />
              <TextField
                label="NDC"
                variant="outlined"
                value={ndc}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNdc(event.target.value);
                }}
                fullWidth
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "1rem",
                marginBottom: "1rem",
              }}
            >
              <TextField
                label="Product Type"
                variant="outlined"
                value={productType}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setProductType(event.target.value);
                }}
                fullWidth
              />
              <TextField
                label="UPC"
                variant="outlined"
                value={upc}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setUpc(event.target.value);
                }}
                fullWidth
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "1rem",
                marginBottom: "1rem",
              }}
            >
              <TextField
                label="GTIN"
                variant="outlined"
                value={gtin}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setGtin(event.target.value);
                }}
                fullWidth
              />
              <TextField
                label="Wholesaler Product Number"
                variant="outlined"
                value={wholesalerProductNumber}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setWholesalerProductNumber(event.target.value);
                }}
                fullWidth
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "1rem",
                marginBottom: "1rem",
              }}
            >
              <TextField
                label="Generic Name"
                variant="outlined"
                value={genericName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setGenericName(event.target.value);
                }}
                fullWidth
              />
              <TextField
                label="Wholesaler"
                variant="outlined"
                value={wholesaler}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setWholesaler(event.target.value);
                }}
                fullWidth
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "1rem",
                marginBottom: "1rem",
              }}
            >
              <TextField
                label="Strength"
                variant="outlined"
                value={strength}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setStrength(event.target.value);
                }}
                fullWidth
              />
              <TextField
                label="Product Family"
                variant="outlined"
                value={productFamily}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setProductFamily(event.target.value);
                }}
                fullWidth
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "1rem",
                marginBottom: "1rem",
              }}
            >
              <TextField
                label="Color"
                variant="outlined"
                value={color}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setColor(event.target.value);
                }}
                fullWidth
              />
              <TextField
                label="Shape"
                variant="outlined"
                value={shape}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setShape(event.target.value);
                }}
                fullWidth
              />
            </Box>
          </DialogContent>
        }
      />
    </Box>
  );
}
