import { ChargebackFileState } from "../types";

export default class ChargebackFile {
  static stateName(state: ChargebackFileState) {
    if (state === "uploaded") {
      return "Uploaded";
    } else if (state === "processing_lineitems") {
      return "Processing";
    } else if (state === "processed_lineitems") {
      return "Finished processing";
    } else if (state === "exported") {
      return "Completed";
    } else {
      return "Error";
    }
  }
}
