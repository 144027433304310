import { TableCell, TextField } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import WholesalerDistributionCenterAPI from "../api/WholesalerDistributionCenter.api";
import {
  Order,
  TableColumn,
  Wholesaler,
  WholesalerDistributionCenterWithAddress,
} from "../types";
import ListTable from "./ListTable";
import Loading from "./Loading";

const columns: TableColumn<WholesalerDistributionCenterWithAddress>[] = [
  { field: "name", label: "Name" },
  { field: "dea", label: "DEA" },
  { field: "hin", label: "HIN" },
  { field: "dcId", label: "DC ID" },
  { field: "city", label: "City", orderBy: false },
  { field: "state", label: "State", orderBy: false },
];

function buildTableRow(
  wholesalerDistributionCenter: WholesalerDistributionCenterWithAddress,
): JSX.Element[] {
  return [
    <TableCell key={"name"}>{wholesalerDistributionCenter.name}</TableCell>,
    <TableCell key={"dea"}>{wholesalerDistributionCenter.dea}</TableCell>,
    <TableCell key={"hin"}>{wholesalerDistributionCenter.hin}</TableCell>,
    <TableCell key={"dcId"}>{wholesalerDistributionCenter.dcId}</TableCell>,
    <TableCell key={"city"}>{wholesalerDistributionCenter.city}</TableCell>,
    <TableCell key={"state"}>{wholesalerDistributionCenter.state}</TableCell>,
  ];
}

type Props = {
  wholesaler: Wholesaler;
};

export default function WholesalerDistributionCenterTable({
  wholesaler,
}: Props) {
  const navigate = useNavigate();

  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof WholesalerDistributionCenterWithAddress>("name");
  const [query, setQuery] = React.useState<string>("");

  const { data } = WholesalerDistributionCenterAPI.useList({
    wholesaler,
    query,
    order,
    orderBy,
    page,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof WholesalerDistributionCenterWithAddress,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick =
    (wholesalerDistributionCenter: WholesalerDistributionCenterWithAddress) =>
    (event: unknown) => {
      navigate(
        `/wholesalers/${wholesaler.id}/wholesaler_distribution_centers/${wholesalerDistributionCenter.id}`,
      );
    };

  return (
    <>
      <TextField
        id="wholesalerDistributionCenter-search"
        label="Search field"
        type="search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        fullWidth
      />
      {data ? (
        <ListTable
          columns={columns}
          buildTableRow={buildTableRow}
          handleChangePage={handleChangePage}
          handleRowClick={handleRowClick}
          handleRequestSort={handleRequestSort}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}
