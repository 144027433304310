import { css } from "@emotion/css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import ChargebackLineitemModel from "../models/ChargebackLineitem.model";
import {
  ChargebackLineitemState,
  ChargebackLineitemWithAssociations,
} from "../types";
import { centsToDollars, extractDate } from "../utils";

type Props = {
  chargebackLineitem: ChargebackLineitemWithAssociations;
};
export default function ChargebackDebitMemoApprovedLineitem({
  chargebackLineitem,
}: Props) {
  const [open, setOpen] = React.useState<boolean>(false);

  const selectBackgroundColor = (state: ChargebackLineitemState) => {
    if (state === "rejected") {
      return "custom.red";
    } else if (state === "pay_calculated_amount") {
      return "custom.green";
    } else if (state === "pay_requested_amount") {
      return "custom.lightBlue";
    }
    return "";
  };

  const cssChargebackLineitemAttributeClassName = css`
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  `;

  return (
    <>
      <TableRow
        key={chargebackLineitem.id}
        sx={{
          "& > *": { borderBottom: "unset" },
          backgroundColor: selectBackgroundColor(chargebackLineitem.state),
          border: "5px solid white",
          borderBottom: open ? 0 : null,
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Link
            to={`/chargebacks/files/${chargebackLineitem.chargebackFileId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {chargebackLineitem.fileLineNum}
          </Link>
        </TableCell>
        <Tooltip
          title={chargebackLineitem.rejectCodeDescriptions}
          placement="bottom"
        >
          <TableCell>{chargebackLineitem.rejectCodes}</TableCell>
        </Tooltip>
        <TableCell>
          {ChargebackLineitemModel.stateName(chargebackLineitem.state)}
        </TableCell>
        {chargebackLineitem.wholesalerDistributionCenter ? (
          <TableCell align="right" style={{ borderBottom: "none" }}>
            <Link
              to={`/wholesalers/${chargebackLineitem.wholesalerDistributionCenter.wholesalerId}`}
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {chargebackLineitem.wholesalerDistributionCenter.wholesalerName}
            </Link>
          </TableCell>
        ) : (
          <TableCell align="right">None</TableCell>
        )}
        {chargebackLineitem.contract ? (
          <Link
            to={`/contracts/${chargebackLineitem.contract.id}`}
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TableCell align="right" style={{ borderBottom: "none" }}>
              {chargebackLineitem.contract && chargebackLineitem.contract.name}
            </TableCell>
          </Link>
        ) : (
          <TableCell align="right" style={{ borderBottom: "none" }}>
            None
          </TableCell>
        )}
        {chargebackLineitem.member ? (
          <Link
            to={`/members/${chargebackLineitem.member.id}`}
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TableCell align="right" style={{ borderBottom: "none" }}>
              {chargebackLineitem.member &&
                chargebackLineitem.member.facilityName}
            </TableCell>
          </Link>
        ) : (
          <TableCell align="right">None</TableCell>
        )}
        {chargebackLineitem.product ? (
          <Link
            to={`/products/${chargebackLineitem.product.id}`}
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TableCell align="right" style={{ borderBottom: "none" }}>
              {chargebackLineitem.ndc}
            </TableCell>
          </Link>
        ) : (
          <TableCell align="right">{chargebackLineitem.ndc}</TableCell>
        )}
        <TableCell align="right">{chargebackLineitem.quantity}</TableCell>
        <TableCell align="right">
          {centsToDollars(chargebackLineitem.wac)}
        </TableCell>
        <TableCell align="right">
          {centsToDollars(chargebackLineitem.systemWac)}
        </TableCell>
        <TableCell align="right">
          {centsToDollars(chargebackLineitem.contractPrice)}
        </TableCell>
        <TableCell align="right">
          {centsToDollars(chargebackLineitem.systemContractPrice)}
        </TableCell>
        <TableCell align="right">
          {centsToDollars(chargebackLineitem.requestedAmount)}
        </TableCell>
        <TableCell align="right">
          {centsToDollars(chargebackLineitem.calculatedAmount)}
        </TableCell>
        <TableCell align="right"></TableCell>
      </TableRow>
      <TableRow
        sx={{
          backgroundColor: selectBackgroundColor(chargebackLineitem.state),
          border: "5px solid white",
          borderTop: 0,
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={16}>
          <Collapse in={open} timeout={500} unmountOnExit>
            <Box sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "0.5rem",
                  justifyContent: "flex-start",
                }}
              >
                <TextField
                  label="Debit Memo Date"
                  variant="standard"
                  value={extractDate(chargebackLineitem.debitMemoDate)}
                  disabled
                  className={cssChargebackLineitemAttributeClassName}
                />
                <TextField
                  label="Vendor Num"
                  variant="standard"
                  value={chargebackLineitem.vendorNum}
                  disabled
                  className={cssChargebackLineitemAttributeClassName}
                />
                <TextField
                  label="Vendor Name"
                  variant="standard"
                  value={chargebackLineitem.vendorName}
                  disabled
                  className={cssChargebackLineitemAttributeClassName}
                />
                <TextField
                  label="Wholesaler Distribution Name"
                  variant="standard"
                  value={
                    chargebackLineitem?.wholesalerDistributionCenter?.name || ""
                  }
                  disabled
                  className={cssChargebackLineitemAttributeClassName}
                />
                <TextField
                  label="Wholesaler Distribution ID"
                  variant="standard"
                  value={chargebackLineitem?.dcId || ""}
                  disabled
                  className={cssChargebackLineitemAttributeClassName}
                />
                <TextField
                  label="NDC"
                  variant="standard"
                  value={chargebackLineitem.ndc || ""}
                  disabled
                  className={cssChargebackLineitemAttributeClassName}
                />
                <TextField
                  label="Wholesaler Product Code"
                  variant="standard"
                  value={chargebackLineitem.wholesalerProductCode || ""}
                  disabled
                  className={cssChargebackLineitemAttributeClassName}
                />
                <TextField
                  label="U O M"
                  variant="standard"
                  value={chargebackLineitem.uom || ""}
                  disabled
                  className={cssChargebackLineitemAttributeClassName}
                />
                <TextField
                  label="Member Name"
                  variant="standard"
                  value={chargebackLineitem?.memberName || ""}
                  disabled
                  className={cssChargebackLineitemAttributeClassName}
                />
                <TextField
                  label="Member HIN"
                  variant="standard"
                  value={chargebackLineitem.memberHin || ""}
                  disabled
                  className={cssChargebackLineitemAttributeClassName}
                />
                <TextField
                  label="Member DEA"
                  variant="standard"
                  value={chargebackLineitem.memberDea || ""}
                  disabled
                  className={cssChargebackLineitemAttributeClassName}
                />
                <TextField
                  label="Member 340B"
                  variant="standard"
                  value={chargebackLineitem.memberId340B || ""}
                  disabled
                  className={cssChargebackLineitemAttributeClassName}
                />
                <TextField
                  label="Member Facility ID"
                  variant="standard"
                  value={chargebackLineitem.memberFacilityId || ""}
                  disabled
                  className={cssChargebackLineitemAttributeClassName}
                />
                <TextField
                  label="Invoice Num"
                  variant="standard"
                  value={chargebackLineitem.invoiceNum || ""}
                  disabled
                  className={cssChargebackLineitemAttributeClassName}
                />
                <TextField
                  label="Invoice Date"
                  variant="standard"
                  value={extractDate(chargebackLineitem.invoiceDate)}
                  disabled
                  className={cssChargebackLineitemAttributeClassName}
                />
                <TextField
                  label="Contract Num"
                  variant="standard"
                  value={chargebackLineitem.contractNum || ""}
                  disabled
                  className={cssChargebackLineitemAttributeClassName}
                />
                <TextField
                  label="GLN"
                  variant="standard"
                  value={chargebackLineitem.gln || ""}
                  disabled
                  className={cssChargebackLineitemAttributeClassName}
                />
              </Box>
              <Box>
                <TextField
                  disabled
                  fullWidth
                  label="Comments"
                  multiline
                  rows={3}
                  value={chargebackLineitem["comments"] || ""}
                />
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
