import TagIcon from "@mui/icons-material/Tag";
import {
  Box,
  Button,
  DialogContent,
  Modal,
  TableCell,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";

import TagAPI from "../api/Tag.api";
import CreatableAutocomplete from "../components/CreatableAutocomplete";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import LogoBox from "../components/LogoBox";
import SelectTableWithCustomRow from "../components/SelectTableWithCustomRow";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { ChargebackDebitMemoWithTags, Order, TableColumn, Tag } from "../types";

const columns: TableColumn<ChargebackDebitMemoWithTags>[] = [
  {
    field: "debitMemo",
    label: "Debit Memo",
    orderBy: false,
  },
  {
    field: "tags",
    label: "Tags",
    orderBy: false,
  },
];

const buildTableRow =
  ({ refetchTable }: { refetchTable: () => void }) =>
  (chargebackDebitMemo: ChargebackDebitMemoWithTags): JSX.Element[] => {
    return [
      <TableCell
        key={"debitMemo"}
        component="th"
        id={chargebackDebitMemo.debitMemo.toString()}
        scope="row"
      >
        {chargebackDebitMemo.debitMemo}
      </TableCell>,
      <TableCell key={"tags"}>
        {chargebackDebitMemo.tags.map((tag) => tag.name).join(", ")}
      </TableCell>,
    ];
  };

export default function ChargebackDebitMemoTagsPage() {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [query, setQuery] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof ChargebackDebitMemoWithTags>("debitMemo");
  const [selected, setSelected] = React.useState<number[]>([]);
  const [open, setOpen] = React.useState<boolean>(false);

  const { data, isLoading, refetch } = TagAPI.useChargebackDebitMemoList({
    businessUnit: currentBusinessUnit,
    query,
    page,
    order,
    orderBy,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ChargebackDebitMemoWithTags,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSelected = async () => {
    setOpen(true);
  };

  const handleSuccess = async () => {
    await refetch();
    setSelected([]);
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">Tag Investigating Debit Memos</Typography>
          <LogoBox />
        </Box>
        <Box>
          <TextField
            label={"Search Debit Memos"}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            fullWidth
          />
        </Box>
        {data && !isLoading ? (
          <SelectTableWithCustomRow
            buildTableRow={buildTableRow({ refetchTable: refetch })}
            columns={columns}
            handleChangePage={handleChangePage}
            handleRequestSort={handleRequestSort}
            handleSelected={handleSelected}
            meta={data.meta}
            order={order}
            orderBy={orderBy}
            rows={data.data}
            selected={selected}
            setSelected={setSelected}
            selectedIcon={<TagIcon />}
          />
        ) : (
          <Loading />
        )}
        <SetTagModal
          selected={selected}
          open={open}
          onClose={() => setOpen(false)}
          onSuccess={handleSuccess}
        />
      </Box>
    </Layout>
  );
}

function SetTagModal({
  selected,
  open,
  onClose,
  onSuccess,
}: {
  selected: number[];
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [currentTag, setCurrentTag] = React.useState<Tag | null>(null);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [value, setValue] = React.useState<Tag | string>("");

  const { data } = TagAPI.useSearch({
    businessUnit: currentBusinessUnit,
    query: searchValue,
  });

  const { mutateAsync: mutateAsyncAttach } = TagAPI.useAttach({
    tagId: currentTag?.id,
    chargebackDebitMemoIds: selected,
  });

  const { mutateAsync: mutateAsyncDetach } = TagAPI.useDetach({
    tagId: currentTag?.id,
    chargebackDebitMemoIds: selected,
  });

  const handleAttach = async () => {
    if (!currentTag) {
      enqueueSnackbar("Must select a Tag");
    }
    try {
      await mutateAsyncAttach();
      enqueueSnackbar("Tag attached successfully", { variant: "success" });
      onClose();
      await onSuccess();
    } catch (e) {
      console.error(e);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const handleDetach = async () => {
    if (!currentTag) {
      enqueueSnackbar("Must select a Tag");
    }
    try {
      await mutateAsyncDetach();
      enqueueSnackbar("Tag detached successfully", { variant: "success" });
      onClose();
      await onSuccess();
    } catch (e) {
      console.error(e);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const [name, setName] = React.useState<string>("");

  const options = data ? data.map((o) => ({ label: o.name, value: o })) : [];

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <CreatableAutocomplete
          createInput={{
            businessUnitId: currentBusinessUnit && currentBusinessUnit.id,
            name,
          }}
          dialogContent={
            <DialogContent>
              <Box>
                <TextField
                  label={"Name"}
                  value={name}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setName(event.target.value);
                  }}
                  fullWidth
                />
              </Box>
            </DialogContent>
          }
          dialogTitle={"Add a new Tag"}
          label={"Find a Tag"}
          noOptionsText={"No Results"}
          onInputChange={(event, newSearchValue) => {
            setSearchValue(newSearchValue);
          }}
          options={options || []}
          setModel={(tag: Tag) => {
            setCurrentTag(tag);
            setSearchValue(tag.name);
            setValue(tag.name);
          }}
          setValue={setValue}
          useSave={TagAPI.useSave}
          value={value}
        />
        {currentTag && selected.length > 0 && (
          <Box
            sx={{
              mt: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography variant={"body1"}>
              Attach or detatch <strong>{currentTag.name}</strong> to or from{" "}
              <strong>{selected.length}</strong> chargeback debit memos.
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
              <Button
                onClick={handleAttach}
                color={"primary"}
                variant={"contained"}
                fullWidth
              >
                Attach
              </Button>
              <Button
                onClick={handleDetach}
                color={"secondary"}
                variant={"contained"}
                fullWidth
              >
                Detach
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
