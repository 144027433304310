import { BusinessUnitInput, BusinessUnitState } from "../types";

export default class BusinessUnit {
  public id?: number;
  public state: BusinessUnitState;
  public name: string;
  public fdaNumber: string;
  public labelerCode: string;
  public dea: string;
  public hin: string;

  constructor({
    id,
    state,
    name,
    fdaNumber,
    labelerCode,
    dea,
    hin,
  }: BusinessUnitInput) {
    this.id = id;
    this.state = state;
    this.name = name;
    this.fdaNumber = fdaNumber;
    this.labelerCode = labelerCode;
    this.dea = dea;
    this.hin = hin;
  }

  static createEmpty() {
    return new BusinessUnit({
      name: "",
      state: "stable",
      fdaNumber: "",
      labelerCode: "",
      dea: "",
      hin: "",
    });
  }
}
