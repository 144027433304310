import { Box, Button, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import GroupAPI from "../api/Group.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { GroupInput } from "../types";
import ArchiveButton from "./ArchiveButton";
import GroupMemberEligibilityManagement from "./GroupMemberEligibilityManagement";

type Props = {
  group: GroupInput;
};

export default function GroupForm({ group }: Props) {
  const navigate = useNavigate();

  const [name, setName] = React.useState<string>(group.name);
  const [description, setDescription] = React.useState<string>(
    group.description,
  );
  const [dea, setDea] = React.useState<string>(group.dea);
  const [hin, setHin] = React.useState<string>(group.hin);
  const [effectiveDate, setEffectiveDate] = React.useState<Dayjs | null>(
    group.effectiveDate ? dayjs(group.effectiveDate, "YYYY-MM-DD") : null,
  );
  const [endDate, setEndDate] = React.useState(
    group.endDate ? dayjs(group.endDate, "YYYY-MM-DD") : null,
  );

  const { enqueueSnackbar } = useSnackbar();
  const { currentBusinessUnit } = useBusinessUnitContext();
  const { mutateAsync, isLoading } = GroupAPI.useSave({
    ...group,
    name,
    description,
    dea,
    hin,
    effectiveDate: effectiveDate ? effectiveDate.toDate() : effectiveDate,
    endDate: endDate ? endDate.toDate() : endDate,
    businessUnitId: currentBusinessUnit && currentBusinessUnit.id,
  });

  const onSave = async () => {
    const res = await mutateAsync();
    if ("errors" in res) {
      res.errors.forEach((message) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      enqueueSnackbar("Saved successfully", { variant: "success" });
      if (group.id) {
        navigate(`/groups`);
      } else {
        navigate(`/groups/${res.id}`);
      }
    }
  };

  const throttledOnSave = React.useCallback(debounce(onSave, 1000), []);

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginTop: "1rem",
      }}
    >
      <TextField
        label="Name"
        variant="outlined"
        value={name}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setName(event.target.value);
        }}
        sx={{ marginBottom: "1rem" }}
      />
      <TextField
        label="Description"
        multiline
        rows={4}
        value={description}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setDescription(event.target.value);
        }}
        sx={{ marginBottom: "1rem" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <DatePicker
          label="Effective Date"
          value={effectiveDate}
          onChange={(value: any) => {
            setEffectiveDate(value);
          }}
          sx={{ width: "100%" }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(value: any) => {
            setEndDate(value);
          }}
          sx={{ width: "100%" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="DEA"
          variant="outlined"
          value={dea}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDea(event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="HIN"
          variant="outlined"
          value={hin}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setHin(event.target.value);
          }}
          fullWidth
        />
      </Box>
      {group.id && (
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            marginTop: "1rem",
          }}
        >
          <GroupMemberEligibilityManagement groupId={group.id} />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "4rem",
          marginBottom: "1rem",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={throttledOnSave}
          disabled={isLoading}
        >
          Save
        </Button>
        {group.id && <ArchiveGroupButton id={group.id} />}
      </Box>
    </Box>
  );
}

type ArchiveGroupButtonProps = {
  id: number;
};

function ArchiveGroupButton({ id }: ArchiveGroupButtonProps) {
  const navigate = useNavigate();

  const { mutateAsync } = GroupAPI.useDelete(id);
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = async () => {
    const res = await mutateAsync();
    if ("errors" in res) {
      res.errors.forEach((message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      enqueueSnackbar("Archived successfully", { variant: "success" });
      navigate("/groups");
    }
  };

  return <ArchiveButton onDelete={onDelete} />;
}
