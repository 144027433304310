import { css } from "@emotion/css";
import ArticleIcon from "@mui/icons-material/Article";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import GroupIcon from "@mui/icons-material/Group";
import InventoryIcon from "@mui/icons-material/Inventory";
import PersonIcon from "@mui/icons-material/Person";
import PublishIcon from "@mui/icons-material/Publish";
import StoreIcon from "@mui/icons-material/Store";
import TagIcon from "@mui/icons-material/Tag";
import ViewListIcon from "@mui/icons-material/ViewList";
import { Box, Typography } from "@mui/material";
import React from "react";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";

export default function NavigationMenu() {
  const { collapsed } = useProSidebar();
  const cssClassName = css`
    color: #254061;
  `;
  return (
    <Sidebar defaultCollapsed={false}>
      <Menu>
        <SubMenu
          defaultOpen={true}
          label={
            collapsed ? (
              <ViewListIcon color={"primary"} />
            ) : (
              <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
                <ViewListIcon color={"primary"} />
                <Typography className={cssClassName}>Chargebacks</Typography>
              </Box>
            )
          }
        >
          <Link to={"/chargebacks/new"}>
            <MenuItem>
              <Typography className={cssClassName}>Upload</Typography>
            </MenuItem>
          </Link>
          <Link to={"/chargebacks/files"}>
            <MenuItem>
              <Typography className={cssClassName}>Files</Typography>
            </MenuItem>
          </Link>
          <Link to={"/chargebacks/tags"}>
            <MenuItem>
              <Typography className={cssClassName}>Tags</Typography>
            </MenuItem>
          </Link>
          <Link to={"/chargebacks/investigating"}>
            <MenuItem>
              <Typography className={cssClassName}>Investigating</Typography>
            </MenuItem>
          </Link>
          <Link to={"/chargebacks/investigated"}>
            <MenuItem>
              <Typography className={cssClassName}>Require Approval</Typography>
            </MenuItem>
          </Link>
          <Link to={"/chargebacks/approved"}>
            <MenuItem>
              <Typography className={cssClassName}>Approved</Typography>
            </MenuItem>
          </Link>
        </SubMenu>
        <Link to={"/wholesalers"}>
          {collapsed ? (
            <MenuItem>
              <StoreIcon color={"primary"} />
            </MenuItem>
          ) : (
            <MenuItem>
              <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
                <StoreIcon color={"primary"} />
                <Typography className={cssClassName}>Wholesalers</Typography>
              </Box>
            </MenuItem>
          )}
        </Link>
        <Link to={`/contracts`}>
          {collapsed ? (
            <MenuItem>
              <ArticleIcon color={"primary"} />
            </MenuItem>
          ) : (
            <MenuItem>
              <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
                <ArticleIcon color={"primary"} />
                <Typography className={cssClassName}>Contracts</Typography>
              </Box>
            </MenuItem>
          )}
        </Link>
        <Link to={`/groups`}>
          {collapsed ? (
            <MenuItem>
              <GroupIcon color={"primary"} />
            </MenuItem>
          ) : (
            <MenuItem>
              <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
                <GroupIcon color={"primary"} />
                <Typography className={cssClassName}>Groups</Typography>
              </Box>
            </MenuItem>
          )}
        </Link>
        <Link to={"/members"}>
          {collapsed ? (
            <MenuItem>
              <PersonIcon color={"primary"} />
            </MenuItem>
          ) : (
            <MenuItem>
              <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
                <PersonIcon color={"primary"} />
                <Typography className={cssClassName}>Members</Typography>
              </Box>
            </MenuItem>
          )}
        </Link>
        <Link to={"/products"}>
          {collapsed ? (
            <MenuItem>
              <InventoryIcon color={"primary"} />
            </MenuItem>
          ) : (
            <MenuItem>
              <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
                <InventoryIcon color={"primary"} />
                <Typography className={cssClassName}>Products</Typography>
              </Box>
            </MenuItem>
          )}
        </Link>
        <Link to={"/tags"}>
          {collapsed ? (
            <MenuItem>
              <TagIcon color={"primary"} />
            </MenuItem>
          ) : (
            <MenuItem>
              <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
                <TagIcon color={"primary"} />
                <Typography className={cssClassName}>Tags</Typography>
              </Box>
            </MenuItem>
          )}
        </Link>
        <SubMenu
          label={
            collapsed ? (
              <AttachFileIcon color={"primary"} />
            ) : (
              <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
                <AttachFileIcon color={"primary"} />
                <Typography className={cssClassName}>Bulk Edits</Typography>
              </Box>
            )
          }
        >
          <Link to={"/bulk-edits"}>
            <MenuItem>
              <Typography className={cssClassName}>
                List of Bulk Edits
              </Typography>
            </MenuItem>
          </Link>
          <Link to={"/bulk-edits/new"}>
            <MenuItem>
              <Typography className={cssClassName}>New Bulk Edit</Typography>
            </MenuItem>
          </Link>
        </SubMenu>
        <SubMenu
          label={
            collapsed ? (
              <AssessmentIcon color={"primary"} />
            ) : (
              <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
                <AssessmentIcon color={"primary"} />
                <Typography className={cssClassName}>Reports</Typography>
              </Box>
            )
          }
        >
          <Link to={"/chargebacks/search"}>
            <MenuItem>
              <Typography className={cssClassName}>
                Search Debit Memo Report
              </Typography>
            </MenuItem>
          </Link>
          <Link to={"/reports/custom"}>
            <MenuItem>
              <Typography className={cssClassName}>Custom Reports</Typography>
            </MenuItem>
          </Link>
          <Link to={"/exports"}>
            <MenuItem>
              <Typography className={cssClassName}>Finished Reports</Typography>
            </MenuItem>
          </Link>
        </SubMenu>
        <SubMenu
          label={
            collapsed ? (
              <PublishIcon color={"primary"} />
            ) : (
              <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
                <PublishIcon color={"primary"} />
                <Typography className={cssClassName}>Submitted</Typography>
              </Box>
            )
          }
        >
          <Link to={"/edi"}>
            <MenuItem>
              <Typography className={cssClassName}>EDI</Typography>
            </MenuItem>
          </Link>
          <Link to={"/non-edi"}>
            <MenuItem>
              <Typography className={cssClassName}>Manual</Typography>
            </MenuItem>
          </Link>
        </SubMenu>
      </Menu>
    </Sidebar>
  );
}
