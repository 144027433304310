import { Box, Button, TableCell, TextField } from "@mui/material";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import ChargebackDebitMemoAPI from "../api/ChargebackDebitMemo.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { ChargebackDebitMemo, Order, TableColumn } from "../types";
import { centsToDollars, extractDate } from "../utils";
import ChargebackDebitMemoApproveButton from "./ChargebackDebitMemoApproveButton";
import ChargebackDebitMemoFeedbackLineitems from "./ChargebackDebitMemoFeedbackLineitems";
import ChargebackDebitMemoReinvestigateButton from "./ChargebackDebitMemoReinvestigateButton";
import Loading from "./Loading";
import SelectTableWithCustomRow from "./SelectTableWithCustomRow";

const columns: TableColumn<ChargebackDebitMemo>[] = [
  { field: "id", label: "ID" },
  { field: "debitMemo", label: "Debit Memo", orderBy: true },
  { field: "calculatedAmount", label: "System Amount", orderBy: false },
  { field: "requestedAmount", label: "Requested Amount", orderBy: false },
  { field: "payoutAmount", label: "Payout Amount", orderBy: false },
  { field: "latestProcessedOn", label: "Processed On", orderBy: true },
  { field: "state", label: "", orderBy: false },
];

type PropsBuildTableRow = {
  refetchTable: () => void;
};

const buildTableRow =
  ({ refetchTable }: PropsBuildTableRow) =>
  (chargebackDebitMemo: ChargebackDebitMemo): JSX.Element[] => {
    return [
      <TableCell
        key={"id"}
        component="th"
        id={chargebackDebitMemo.id.toString()}
        scope="row"
        padding="none"
      >
        {chargebackDebitMemo.id}
      </TableCell>,
      <TableCell key={"debitMemo"}>
        {chargebackDebitMemo.highlight ? (
          <div
            dangerouslySetInnerHTML={{
              __html: chargebackDebitMemo.highlight,
            }}
            className="[&>em]:backgroundColor:yellow"
          />
        ) : (
          chargebackDebitMemo.debitMemo
        )}
      </TableCell>,
      <TableCell key={"calculatedAmount"}>{`${centsToDollars(
        chargebackDebitMemo.calculatedAmount,
      )}`}</TableCell>,
      <TableCell key={"requestedAmount"}>{`${centsToDollars(
        chargebackDebitMemo.requestedAmount,
      )}`}</TableCell>,
      <TableCell key={"payoutAmount"}>{`${centsToDollars(
        chargebackDebitMemo.payoutAmount,
      )}`}</TableCell>,
      <TableCell key={"latestProcessedOn"}>{`${extractDate(
        chargebackDebitMemo.latestProcessedOn,
      )}`}</TableCell>,
      <TableCell key={"action"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            justifyContent: "space-between",
          }}
        >
          <ChargebackDebitMemoReinvestigateButton
            id={chargebackDebitMemo.id}
            refetch={refetchTable}
          />
          <ChargebackDebitMemoApproveButton
            id={chargebackDebitMemo.id}
            refetch={refetchTable}
          />
        </Box>
      </TableCell>,
    ];
  };

const buildRowDropDown = (
  chargebackDebitMemo: ChargebackDebitMemo,
): JSX.Element => {
  return (
    <ChargebackDebitMemoFeedbackLineitems
      chargebackDebitMemoId={chargebackDebitMemo.id}
      chargebackDebitMemoApproved={true}
    />
  );
};

export default function ChargebackDebitMemoReadyExportListTable() {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [query, setQuery] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof ChargebackDebitMemo>("createdAt"); // TODO
  const [selected, setSelected] = React.useState<number[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const { data, refetch, isLoading } = ChargebackDebitMemoAPI.useList({
    businessUnit: currentBusinessUnit,
    state: "investigated",
    order,
    orderBy,
    page,
    query,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ChargebackDebitMemo,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const exportDebitMemos = async () => {
    enqueueSnackbar("Button has no function currently");
  };

  const throttledExportDebitMemos = React.useCallback(
    debounce(exportDebitMemos, 1000),
    [],
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <TextField
            id="ChargebackDebitMemoInvestigated-search"
            label="Search field"
            type="search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            fullWidth
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            flexBasis: "auto",
            marginLeft: "0.5rem",
            gap: "0.5rem",
          }}
        >
          <ReinvestigateAll />
          <ApproveAll />
        </Box>
      </Box>
      {data && !isLoading ? (
        <SelectTableWithCustomRow
          buildTableRow={buildTableRow({ refetchTable: refetch })}
          buildRowDropDown={buildRowDropDown}
          columns={columns}
          handleChangePage={handleChangePage}
          handleRequestSort={handleRequestSort}
          handleSelected={throttledExportDebitMemos}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
          selected={selected}
          setSelected={setSelected}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}

function ApproveAll() {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync, isLoading } =
    ChargebackDebitMemoAPI.useApproveAll(currentBusinessUnit);

  const handleApprove = async () => {
    const res = await mutateAsync();
    if ("errors" in res) {
      res.errors.forEach((message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      enqueueSnackbar("Approved all Debit Memos successfully", {
        variant: "success",
      });
      navigate(`/chargebacks/approved`);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleApprove}
      disabled={isLoading}
    >
      Approve All
    </Button>
  );
}

function ReinvestigateAll() {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync, isLoading } =
    ChargebackDebitMemoAPI.useReinvestigateAll(currentBusinessUnit);

  const handleReinvestigate = async () => {
    const res = await mutateAsync();
    if ("errors" in res) {
      res.errors.forEach((message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      enqueueSnackbar("Reinvestigating all Debit Memos successfully", {
        variant: "success",
      });
      navigate(`/chargebacks/investigating`);
    }
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleReinvestigate}
      disabled={isLoading}
    >
      Reinvestigate All
    </Button>
  );
}
