import { ReportState, ReportType } from "../types";

export default class ReportModel {
  static stateName(state: ReportState) {
    if (state === "unprocessed") {
      return "Waiting in queue to be processed";
    } else if (state === "processing") {
      return "Processing";
    } else if (state === "completed") {
      return "Ready to download";
    } else {
      return "Internal Server Error";
    }
  }

  static reportTypeName(reportType: ReportType) {
    if (reportType === "contract_dump") {
      return "Contract Dump";
    } else if (reportType === "custom_chargeback_debit_memo_search_by_date") {
      return "Custom Chargeback Debit Memo search by date";
    } else if (reportType === "member_dump") {
      return "Member Dump";
    } else if (reportType === "product_dump") {
      return "Product Dump";
    } else if (
      reportType === "summarized_chargeback_debit_memo_search_by_date"
    ) {
      return "Summarized Debit Memo Search";
    } else if (reportType === "wholesaler_dump") {
      return "Wholesaler Dump";
    } else {
      return "N/A";
    }
  }
}
