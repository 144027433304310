import { Box, Button } from "@mui/material";
import React from "react";

import GroupAPI from "../api/Group.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { Group } from "../types";
import SearchBarAutocomplete from "./SearchBarAutocomplete";

type Props = {
  setCurrentGroup: (group: Group) => void;
};

export default function GroupSearchBarAutocomplete({ setCurrentGroup }: Props) {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [value, setValue] = React.useState<any | null>(null);
  const [inputValue, setInputValue] = React.useState("");

  const { data } = GroupAPI.useSearch({
    businessUnit: currentBusinessUnit,
    query: inputValue,
  });

  const handleSelect = () => {
    if (value && value.value) {
      setCurrentGroup(value.value);
    } else {
      // TODO send a notification to customer saying they need to select one
    }
  };

  const options = data && data.map((o) => ({ label: o.name, value: o }));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
      }}
    >
      <SearchBarAutocomplete
        getOptionLabel={(option: Group) => {
          return typeof option === "string" ? option : option.name;
        }}
        label="Find a Group"
        inputValue={inputValue}
        noOptionsText="No results"
        onChange={(event: any, newValue: any | null) => {
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options || []}
        value={value}
      />
      <Button onClick={handleSelect} variant="contained">
        Select
      </Button>
    </Box>
  );
}
