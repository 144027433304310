import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BusinessUnit,
  ListResponseData,
  Order,
  Report,
  ReportType,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleMutationError,
  handleQueryError,
  handleSave,
} from "./handler";

const methods = {
  useList: ({
    businessUnit,
    order,
    orderBy,
    page,
  }: {
    businessUnit?: BusinessUnit;
    order: Order;
    orderBy: keyof Report;
    page?: number;
  }) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<Report>>({
      cacheTime: 0,
      queryKey: ["reports", businessUnit, order, orderBy, page],
      queryFn: () =>
        handleList({
          order,
          orderBy,
          page,
          baseUrl: "reports",
          params: [{ key: "business_unit_id", value: businessUnit?.id }],
        }),
      onError: handleQueryError(navigate),
      refetchInterval: 5000,
    });
  },
  useCreate: ({
    reportType,
    args,
  }: {
    args?: any;
    reportType?: ReportType;
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<Report>({
      mutationFn: () =>
        handleSave({ baseUrl: "reports", input: { reportType, ...args } }),
      onError: handleMutationError(enqueueSnackbar),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<Report>({
      cacheTime: 0,
      queryKey: ["reports", id],
      queryFn: () => handleDetail({ id, baseUrl: "reports" }),
      onError: handleQueryError(navigate),
      refetchInterval: 5000,
    });
  },
  useDelete: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number }>({
      mutationFn: () => handleDelete({ id, baseUrl: "reports" }),
      onError: handleQueryError(navigate),
    });
  },
};

export default methods;
