import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, Modal, TableCell, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

import GroupMemberAPI from "../api/GroupMember.api";
import { GroupMemberEligibility, Order, TableColumn } from "../types";
import { extractDate } from "../utils";
import GroupMemberEligibilityModal from "./GroupMemberEligibilityModal";
import ListTable from "./ListTable";
import Loading from "./Loading";

const columns: TableColumn<GroupMemberEligibility>[] = [
  {
    field: "memberFacilityName",
    label: "Member Facility Name",
    orderBy: false,
  },
  { field: "effectiveDate", label: "Effective Date" },
  { field: "endDate", label: "End Date" },
];

function buildTableRow(groupMember: GroupMemberEligibility): JSX.Element[] {
  const colorTxt = groupMember.valid ? "" : grey[500];
  return [
    <TableCell key={"memberName"} sx={{ color: colorTxt }}>
      {groupMember.memberFacilityName}
    </TableCell>,
    <TableCell key={"extractDate"} sx={{ color: colorTxt }}>
      {extractDate(groupMember.effectiveDate)}
    </TableCell>,
    <TableCell key={"endDate"} sx={{ color: colorTxt }}>
      {extractDate(groupMember.endDate)}
    </TableCell>,
  ];
}

type Props = {
  groupId: number;
};

export default function GroupMemberEligibilityManagement({ groupId }: Props) {
  const [groupMemberEligibilityId, setGroupMemberEligibilityId] =
    React.useState<number>();

  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof GroupMemberEligibility>("endDate"); // TODO

  const { data } = GroupMemberAPI.useList({
    groupId,
    order,
    orderBy,
    page,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof GroupMemberEligibility,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick =
    (groupMemberEligibility: GroupMemberEligibility) => (event: unknown) => {
      // TODO open modal for editing, edit view will have link to model
      handleGroupMemberEligibilityClick(groupMemberEligibility);
    };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setGroupMemberEligibilityId(undefined);
    setOpen(false);
  };

  const handleGroupMemberEligibilityClick = (
    groupMemberEligibility: GroupMemberEligibility,
  ) => {
    setGroupMemberEligibilityId(groupMemberEligibility.id);
    setOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          marginTop: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Typography variant="h6">Member Eligibilities</Typography>

          <IconButton
            aria-label="Create a Group"
            size="large"
            onClick={handleOpen}
          >
            <AddCircleIcon color="primary" />
          </IconButton>
        </Box>
        {data ? (
          <ListTable
            columns={columns}
            buildTableRow={buildTableRow}
            handleChangePage={handleChangePage}
            handleRowClick={handleRowClick}
            handleRequestSort={handleRequestSort}
            meta={data.meta}
            order={order}
            orderBy={orderBy}
            rows={data.data}
          />
        ) : (
          <Loading />
        )}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <GroupMemberEligibilityModal
            id={groupMemberEligibilityId}
            groupId={groupId}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </>
  );
}
