import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import React from "react";

import Layout from "../components/Layout";
import WholesalerForm from "../components/WholesalerForm";
import WholesalerListTable from "../components/WholesalerListTable";
import WholesalerModel from "../models/Wholesaler.model";

export default function WholesalerList() {
  const [openModal, setOpenModal] = React.useState(false);
  const emptyWholesaler = WholesalerModel.createEmpty();

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">WholesalerList</Typography>

          <>
            <IconButton
              aria-label="Create a Group"
              size="large"
              onClick={handleOpen}
            >
              <AddCircleIcon color="primary" />
            </IconButton>
            <Modal
              open={openModal}
              onClose={handleClose}
              aria-labelledby="modal-create-wholesaler"
              aria-describedby="modal-create-wholesaler"
            >
              <Box
                sx={{
                  position: "absolute" as "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Create Wholesaler
                </Typography>
                <WholesalerForm wholesaler={emptyWholesaler} />
              </Box>
            </Modal>
          </>
        </Box>
        <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          {/*<WholesalerSearchBarAutocomplete />*/}
        </Box>
        <WholesalerListTable />
      </Box>
    </Layout>
  );
}
