import { TableCell } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import UserAPI from "../api/User.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { Order, TableColumn, User } from "../types";
import ListTable from "./ListTable";
import Loading from "./Loading";

const columns: TableColumn<User>[] = [
  { field: "email", label: "Email" },
  { field: "role", label: "Role" },
];

function buildTableRow(user: User): JSX.Element[] {
  return [
    <TableCell key={"email"}>{user.email}</TableCell>,
    <TableCell key={"role"}>{user.role}</TableCell>,
  ];
}

export default function UserListTable() {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof User>("email"); // TODO

  const navigate = useNavigate();

  const { data } = UserAPI.useList({
    businessUnit: currentBusinessUnit,
    order,
    orderBy,
    page,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof User,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick = (user: User) => (event: unknown) => {
    navigate(`/users/${user.id}`);
  };
  return (
    <>
      {data ? (
        <ListTable
          columns={columns}
          buildTableRow={buildTableRow}
          handleChangePage={handleChangePage}
          handleRowClick={handleRowClick}
          handleRequestSort={handleRequestSort}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}
