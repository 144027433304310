import { AutoImmportedFileState } from "../types";

export default class AutoImportedFile {
  static stateName(state: AutoImmportedFileState) {
    if (state === "created") {
      return "Initialized import...";
    } else if (state === "imported") {
      return "Imported";
    } else if (state === "read_file") {
      return "Validating file";
    } else if (state === "completed_read_file") {
      return "Validated file, queuing to be imported.";
    } else if (state === "create_chargeback_file") {
      return "Creating ChargebackFile";
    } else if (state === "processed") {
      return "Completed";
    } else {
      return "Error";
    }
  }
}
