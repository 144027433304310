import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  AutoImportedFile,
  BaseQueryPropsType,
  ErrorResult,
  ListResponseData,
} from "../types";
import {
  handleBasicPost,
  handleDelete,
  handleDetail,
  handleList,
  handleMutationError,
  handleQueryError,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useList: ({ order, orderBy, page }: BaseQueryPropsType<AutoImportedFile>) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<AutoImportedFile>>({
      cacheTime: 0,
      queryKey: ["auto_imported_files", order, orderBy, page],
      queryFn: () =>
        handleList({
          baseUrl: "auto_imported_files",
          order,
          orderBy,
          page,
          params: [],
        }),
      onError: handleQueryError(navigate),
      refetchInterval: 10000, // 10 seconds
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<AutoImportedFile>({
      cacheTime: 0,
      queryKey: ["auto_imported_files", id],
      queryFn: () => handleDetail({ baseUrl: "auto_imported_files", id }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<{ id: number } | ErrorResult>({
      mutationFn: () => handleDelete({ baseUrl: "auto_imported_files", id }),
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        enqueueSnackbar("Successfully archived", { variant: "success" });
        await queryClient.refetchQueries(["auto_imported_files"]);
      },
    });
  },
  useReprocess: (id?: string) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<AutoImportedFile>({
      mutationFn: () =>
        handleBasicPost({
          baseUrl: `auto_imported_files/${id}/reprocess`,
          input: {},
        }),
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        enqueueSnackbar("Successfully Reprocesed", { variant: "success" });
        await queryClient.refetchQueries(["auto_imported_files", data.id]);
      },
      retry: 1,
    });
  },
  useReprocessAllErrors: () => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<{}>({
      mutationFn: () =>
        handleBasicPost({
          baseUrl: `auto_imported_files/reprocess_all_errors`,
          input: {},
        }),
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        enqueueSnackbar("Successfully started reprocess");
      },
      retry: 1,
    });
  },
};
export default methods;
