import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { TableCell, TextField } from "@mui/material";
import { startCase } from "lodash";
import React from "react";
import CurrencyFormat from "react-currency-format";

import ContractProductPriceAPI from "../api/ContractProductPrice.api";
import { ContractProductPrice, Order, TableColumn } from "../types";
import { extractDate } from "../utils";
import ContractProductPriceModal from "./ContractProductPriceModal";
import ListTable from "./ListTable";
import Loading from "./Loading";

type Props = {
  contractId: number;
};

const columns: TableColumn<ContractProductPrice>[] = [
  {
    field: "productNdc",
    label: "Product NDC",
  },
  { field: "priceCents", label: "Price" },
  { field: "priceClassification", label: "Price Classification" },
  { field: "effectiveDate", label: "Effective Date" },
  { field: "endDate", label: "End Date" },
];

function buildTableRow(
  contractProductPrice: ContractProductPrice,
): JSX.Element[] {
  return [
    <TableCell key={"productNdc"}>{contractProductPrice.productNdc}</TableCell>,
    <TableCell key={"priceCents"}>
      <CurrencyFormat
        value={contractProductPrice.price}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    </TableCell>,
    <TableCell key={"priceClassification"}>
      {startCase(contractProductPrice.priceClassification)}
    </TableCell>,
    <TableCell key={"extractDate"}>
      {extractDate(contractProductPrice.effectiveDate)}
    </TableCell>,
    <TableCell key={"endDate"}>
      {extractDate(contractProductPrice.endDate)}
    </TableCell>,
  ];
}

export default function ContractProductManagement({ contractId }: Props) {
  const [query, setQuery] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof ContractProductPrice>("productNdc"); // TODO

  const { data, refetch } = ContractProductPriceAPI.useList({
    contractId,
    order,
    orderBy,
    page,
    query,
  });

  const [contractProductPriceId, setContractProductPriceId] =
    React.useState<number>();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    refetch();
    setContractProductPriceId(undefined);
    setOpen(false);
  };

  const handleContractProductPriceClick = (
    contractProductPrice: ContractProductPrice,
  ) => {
    setContractProductPriceId(contractProductPrice.id);
    setOpen(true);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ContractProductPrice,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick =
    (contractProductPrice: ContractProductPrice) => (event: unknown) => {
      // TODO open modal for editing, edit view will have link to model
      handleContractProductPriceClick(contractProductPrice);
    };

  // List Table with search ability to see all the products attached to contract
  // Add button that brings up the modal to search for products
  // click on list item brings up edit modal to edit the ContractProductPrice

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginTop: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="h6">Products in Contract</Typography>
        <IconButton
          aria-label="Add a product to the contract"
          size="large"
          onClick={handleOpen}
        >
          <AddCircleIcon color="primary" />
        </IconButton>
      </Box>
      <Box>
        <TextField
          id="ProductListTable-search"
          label="Search field"
          type="search"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          fullWidth
        />
      </Box>
      {data ? (
        <ListTable
          columns={columns}
          buildTableRow={buildTableRow}
          handleChangePage={handleChangePage}
          handleRowClick={handleRowClick}
          handleRequestSort={handleRequestSort}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        />
      ) : (
        <Loading />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <ContractProductPriceModal
            id={contractProductPriceId}
            contractId={contractId}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </Box>
  );
}
