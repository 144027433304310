import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

import ChargebackLineitemAPI from "../api/ChargebackLineitem.api";
import { ChargebackLineitemWithAssociations, ListResponseData } from "../types";
import ChargebackDebitMemoApprovedLineitem from "./ChargebackDebitMemoApprovedLineitem";
import ChargebackDebitMemoFeedbackLineitem from "./ChargebackDebitMemoFeedbackLineitem";
import EmptyTableRows from "./EmptyTableRows";
import Loading from "./Loading";

type Props = {
  chargebackDebitMemoId: number;
  chargebackDebitMemoApproved: boolean;
};
export default function ChargebackDebitMemoFeedbackLineitems({
  chargebackDebitMemoId,
  chargebackDebitMemoApproved,
}: Props) {
  const [page, setPage] = React.useState<number>(0);

  const { data, refetch, isRefetching } = ChargebackLineitemAPI.useList({
    chargebackDebitMemoId: chargebackDebitMemoId,
    page,
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const refetchAll = () => {
    refetch();
  };

  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        Chargebacks
      </Typography>
      {data ? (
        <TableContainerView
          data={data}
          refetchAll={refetchAll}
          isRefetching={isRefetching}
          handleChangePage={handleChangePage}
          chargebackDebitMemoApproved={chargebackDebitMemoApproved}
        />
      ) : (
        <Loading />
      )}
    </Box>
  );
}

type PropsTableContainerView = {
  data: ListResponseData<ChargebackLineitemWithAssociations>;
  refetchAll: () => void;
  isRefetching: boolean;
  handleChangePage: (event: unknown, newPage: number) => void;
  chargebackDebitMemoApproved: boolean;
};

export function TableContainerView({
  data,
  refetchAll,
  isRefetching,
  handleChangePage,
  chargebackDebitMemoApproved,
}: PropsTableContainerView) {
  const meta = data.meta;
  const currentPage = meta.current - 1;
  const rowsPerPage = meta.perPage;
  const emptyRows =
    currentPage > 0 ? Math.max(0, rowsPerPage - data.data.length) : 0;

  return (
    <TableContainer>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Line #</TableCell>
            <TableCell>Errors</TableCell>
            <TableCell>State</TableCell>
            <TableCell align="right">Wholesaler</TableCell>
            <TableCell align="right">Contract</TableCell>
            <TableCell align="right">Submitted Member</TableCell>
            <TableCell align="right">NDC</TableCell>
            <TableCell align="right">Qty</TableCell>
            <TableCell align="right">Submitted WAC ($)</TableCell>
            <TableCell align="right">System WAC ($)</TableCell>
            <TableCell align="right">Submitted Contract price ($)</TableCell>
            <TableCell align="right">System contract price ($)</TableCell>
            <TableCell align="right">Requested ($)</TableCell>
            <TableCell align="right">System Calculated ($)</TableCell>
            {!chargebackDebitMemoApproved && <TableCell>Investigate</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.data.map((chargebackLineitem) =>
              chargebackDebitMemoApproved ? (
                <ChargebackDebitMemoApprovedLineitem
                  key={chargebackLineitem.id}
                  chargebackLineitem={chargebackLineitem}
                />
              ) : (
                <ChargebackDebitMemoFeedbackLineitem
                  key={chargebackLineitem.id}
                  chargebackLineitem={chargebackLineitem}
                  refetchLineitems={refetchAll}
                  isRefetchingLineitems={isRefetching}
                />
              ),
            )}
          <EmptyTableRows colSpan={16} emptyRows={emptyRows} height={56} />
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[meta.perPage]}
        component="div"
        count={meta.count}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onPageChange={handleChangePage}
      />
    </TableContainer>
  );
}
