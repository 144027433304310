import { css } from "@emotion/css";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { useProSidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import ProfileNavigationMenu from "./ProfileNavigationMenu";

export default function NavigationBar() {
  const { currentBusinessUnit, setCurrentBusinessUnit } =
    useBusinessUnitContext();
  const { collapseSidebar } = useProSidebar();
  const navigate = useNavigate();

  const handleExitBusinessUnit = () => {
    setCurrentBusinessUnit && setCurrentBusinessUnit(undefined);
    navigate("/");
  };

  return (
    <AppBar position="static" sx={{ flexShrink: 1 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "flex" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => {
                collapseSidebar();
              }}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <div
              className={css`
                display: flex;
                flex-grow: 1;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              `}
            >
              <Link
                to={`/dashboard`}
                className={css`
                  text-decoration: none;
                `}
              >
                <Typography
                  variant="h6"
                  noWrap
                  sx={{
                    mr: 2,
                    fontFamily: "monospace",
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  {currentBusinessUnit?.name}
                </Typography>
              </Link>
            </div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <IconButton
                aria-label="Exit Business Unit"
                color="inherit"
                onClick={handleExitBusinessUnit}
              >
                <ExitToAppIcon />
              </IconButton>
              <ProfileNavigationMenu />
            </Box>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => {
                collapseSidebar();
              }}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <div
              className={css`
                display: flex;
                flex-grow: 1;
                flex-direction: column;
                justify-content: center;
                align-items: end;
              `}
            >
              <ProfileNavigationMenu />
            </div>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
