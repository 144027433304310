import camelcaseKeys from "camelcase-keys";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BusinessUnit,
  BusinessUnitDashboard,
  BusinessUnitInput,
  ErrorResult,
} from "../types";
import client from "./client";
import {
  handleDelete,
  handleDetail,
  handleQueryError,
  handleSave,
} from "./handler";

async function fetchBusinessUnits(): Promise<BusinessUnit[]> {
  try {
    const response: { data: BusinessUnit[] } = await client
      .get("business_units")
      .json();
    return camelcaseKeys(response.data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchBusinessUnit(id: number): Promise<BusinessUnit> {
  try {
    const data: BusinessUnit = await client.get(`business_units/${id}`).json();
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

async function fetchBusinessUnitDashboard(
  businessUnit: BusinessUnit | undefined,
): Promise<BusinessUnitDashboard> {
  try {
    if (typeof businessUnit === "undefined") {
      return Promise.reject(new Error("Invalid business unit"));
    }
    const data: BusinessUnitDashboard = await client
      .get(`business_units/${businessUnit.id}/dashboard`)
      .json();
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postReprocessAllChargebackDebitMemos(
  id: number | undefined,
): Promise<BusinessUnit> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data: BusinessUnit = await client
      .post(`business_units/${id}/reprocess_debit_memos`)
      .json();
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

const methods = {
  useList: () => {
    const navigate = useNavigate();
    return useQuery<BusinessUnit[]>({
      retry: false,
      queryKey: ["business_units"],
      queryFn: fetchBusinessUnits,
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: number | string) => {
    const navigate = useNavigate();
    return useQuery({
      queryKey: ["business_units", id],
      queryFn: () => handleDetail({ id, baseUrl: "business_units" }),
      onError: handleQueryError(navigate),
    });
  },
  useDashboard: (businessUnit?: BusinessUnit) => {
    const navigate = useNavigate();
    return useQuery({
      queryKey: ["business_units", businessUnit, "dashboard"],
      queryFn: () => fetchBusinessUnitDashboard(businessUnit),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (businessUnitInput: BusinessUnitInput) => {
    const navigate = useNavigate();
    return useMutation<BusinessUnit | ErrorResult>({
      mutationFn: () =>
        handleSave({ baseUrl: "business_units", input: businessUnitInput }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number } | ErrorResult>({
      mutationFn: () => handleDelete({ baseUrl: "business_units", id }),
      onError: handleQueryError(navigate),
    });
  },
  useReprocessAll: (id: number | undefined) => {
    const navigate = useNavigate();
    return useMutation<BusinessUnit | ErrorResult>({
      mutationFn: () => postReprocessAllChargebackDebitMemos(id),
      onError: handleQueryError(navigate),
    });
  },
};

export default methods;
