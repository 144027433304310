import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import ChargebackDebitMemoAPI from "../api/ChargebackDebitMemo.api";
import ChargebackDebitMemoModel from "../models/ChargebackDebitMemo.model";
import { ChargebackDebitMemo } from "../types";
import { centsToDollars, extractDate } from "../utils";
import ArchiveButton from "./ArchiveButton";
import ChargebackDebitMemoFeedbackLineitems from "./ChargebackDebitMemoFeedbackLineitems";

type Props = {
  chargebackDebitMemo: ChargebackDebitMemo;
  refetchChargebackDebitMemos: () => void;
};

export default function ChargebackDebitMemoFeedback({
  chargebackDebitMemo,
  refetchChargebackDebitMemos,
}: Props) {
  const [showLineitems, setShowLineitems] = React.useState<boolean>(false);

  const backgroundTableRowColor = () => {
    if (showLineitems) {
      return "#d7d7d7";
    } else if (chargebackDebitMemo.state === "uninvestigated") {
      return "custom.lightBlue";
    }
    return "#d7d7d7";
  };

  const contrastTextRowColor = () => {
    if (showLineitems) {
      return "";
    } else if (chargebackDebitMemo.state === "uninvestigated") {
      return "#000";
    }
    return "";
  };

  return (
    <>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset", color: contrastTextRowColor() },
          backgroundColor: backgroundTableRowColor(),
        }}
      >
        <TableCell component="th" scope="row" align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setShowLineitems(!showLineitems)}
            sx={{
              color: contrastTextRowColor(),
            }}
          >
            {showLineitems ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell align="right" sx={{ color: contrastTextRowColor() }}>
          {chargebackDebitMemo.highlight ? (
            <div
              dangerouslySetInnerHTML={{
                __html: chargebackDebitMemo.highlight,
              }}
              className="[&>em]:backgroundColor:yellow"
            />
          ) : (
            chargebackDebitMemo.debitMemo
          )}
        </TableCell>
        <TableCell align="right" sx={{ color: contrastTextRowColor() }}>
          {ChargebackDebitMemoModel.stateName(chargebackDebitMemo.state)}
        </TableCell>
        <TableCell align="right" sx={{ color: contrastTextRowColor() }}>
          {chargebackDebitMemo.approvedOn
            ? extractDate(chargebackDebitMemo.approvedOn)
            : "None"}
        </TableCell>
        <TableCell align="right" sx={{ color: contrastTextRowColor() }}>
          {chargebackDebitMemo.wholesaler
            ? chargebackDebitMemo.wholesaler.name
            : "Invalid Wholesalers"}
        </TableCell>
        <TableCell align="right" sx={{ color: contrastTextRowColor() }}>
          {chargebackDebitMemo.totalChargebackLineitems}
        </TableCell>
        <TableCell align="right" sx={{ color: contrastTextRowColor() }}>
          {chargebackDebitMemo.requiresActionTotal}
        </TableCell>
        <TableCell align="right" sx={{ color: contrastTextRowColor() }}>
          {centsToDollars(chargebackDebitMemo.requestedAmount)}
        </TableCell>
        <TableCell align="right" sx={{ color: contrastTextRowColor() }}>
          {centsToDollars(chargebackDebitMemo.calculatedAmount)}
        </TableCell>
        <TableCell align="right" sx={{ color: contrastTextRowColor() }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "0.5rem",
            }}
          >
            <DeleteButton
              id={chargebackDebitMemo.id}
              refetchChargebackDebitMemos={refetchChargebackDebitMemos}
            />
            <ReprocessButton
              id={chargebackDebitMemo.id}
              refetchChargebackDebitMemos={refetchChargebackDebitMemos}
            />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow sx={{ backgroundColor: grey[50] }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={showLineitems} timeout={500} mountOnEnter>
            <ChargebackDebitMemoFeedbackLineitems
              chargebackDebitMemoId={chargebackDebitMemo.id}
              chargebackDebitMemoApproved={
                chargebackDebitMemo.state === "approved"
              }
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

type PropsDeleteButton = {
  id: number;
  refetchChargebackDebitMemos: () => void;
};

function DeleteButton({ id, refetchChargebackDebitMemos }: PropsDeleteButton) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync } = ChargebackDebitMemoAPI.useDelete(id);

  const handleReprocess = async () => {
    const res = await mutateAsync();
    if ("errors" in res) {
      res.errors.forEach((message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      enqueueSnackbar("Successfully deleted chargeback debit memo", {
        variant: "success",
      });
      refetchChargebackDebitMemos();
    }
  };
  const throttledHandleDelete = React.useCallback(
    debounce(handleReprocess, 1000),
    [],
  );

  return <ArchiveButton onDelete={throttledHandleDelete} />;
}

type PropsReprocessButton = {
  id: number;
  refetchChargebackDebitMemos: () => void;
};

function ReprocessButton({
  id,
  refetchChargebackDebitMemos,
}: PropsReprocessButton) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync, isLoading } = ChargebackDebitMemoAPI.useReprocess(id);

  const handleReprocess = async () => {
    const res = await mutateAsync();
    if ("errors" in res) {
      res.errors.forEach((message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      enqueueSnackbar("Successfully reprocessing chargeback debit memo", {
        variant: "success",
      });
      refetchChargebackDebitMemos();
    }
  };
  const throttledHandleReprocess = React.useCallback(
    debounce(handleReprocess, 1000),
    [],
  );

  return (
    <Button
      onClick={throttledHandleReprocess}
      variant="contained"
      color={"primary"}
      disabled={isLoading}
    >
      Reprocess
    </Button>
  );
}
