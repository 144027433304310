import { TableCell } from "@mui/material";
import { isNil } from "lodash";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import ChargebackExportGroupAPI from "../api/ChargebackExportGroup.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import ChargebackExportGroupModel from "../models/ChargebackExportGroup.model";
import { ChargebackExportGroup, Order, TableColumn } from "../types";
import { extractDate } from "../utils";
import ListTable from "./ListTable";
import Loading from "./Loading";

const columns: TableColumn<ChargebackExportGroup>[] = [
  { field: "id", label: "ID" },
  { field: "state", label: "State" },
  { field: "reportType", label: "Report Type" },
  { field: "file", label: "File" },
  { field: "effectiveDate", label: "Effective Date" },
  { field: "endDate", label: "End Date" },
  { field: "wholesaler", label: "Wholesaler" },
  { field: "createdAt", label: "Created Date" },
];

function buildTableRow(
  chargebackExportGroup: ChargebackExportGroup,
): JSX.Element[] {
  return [
    <TableCell key={"id"}>{chargebackExportGroup.id}</TableCell>,
    <TableCell key={"state"}>
      {ChargebackExportGroupModel.stateName(chargebackExportGroup.state)}
    </TableCell>,
    <TableCell key={"reportType"}>
      {ChargebackExportGroupModel.reportTypeName(
        chargebackExportGroup.reportType,
      )}
    </TableCell>,
    <TableCell key={"file"}>
      {!isNil(chargebackExportGroup.file) ? (
        <Link target="_blank" to={chargebackExportGroup.file}>
          {chargebackExportGroup.filename}
        </Link>
      ) : (
        ""
      )}
    </TableCell>,
    <TableCell key={"effectiveDate"}>
      {extractDate(chargebackExportGroup.effectiveDate)}
    </TableCell>,
    <TableCell key={"endDate"}>
      {extractDate(chargebackExportGroup.endDate)}
    </TableCell>,
    <TableCell key={"wholesaler"}>
      {chargebackExportGroup?.wholesaler?.name
        ? chargebackExportGroup?.wholesaler?.name
        : "N/A"}
    </TableCell>,
    <TableCell key={"createdAt"}>
      {extractDate(chargebackExportGroup.createdAt)}
    </TableCell>,
  ];
}

export default function ChargebackExportGroupListTable() {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof ChargebackExportGroup>("createdAt"); // TODO

  const navigate = useNavigate();

  const { data } = ChargebackExportGroupAPI.useList({
    businessUnit: currentBusinessUnit,
    order,
    orderBy,
    page,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ChargebackExportGroup,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick =
    (chargebackExportGroup: ChargebackExportGroup) => (event: unknown) => {
      navigate(`/exports/${chargebackExportGroup.id}`);
    };

  if (data) {
    return (
      <ListTable
        columns={columns}
        buildTableRow={buildTableRow}
        handleChangePage={handleChangePage}
        handleRowClick={handleRowClick}
        handleRequestSort={handleRequestSort}
        meta={data.meta}
        order={order}
        orderBy={orderBy}
        rows={data.data}
      />
    );
  }
  return <Loading />;
}
