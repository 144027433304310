import { ChargebackDebitMemoState } from "../types";

export default class ChargebackDebitMemoModel {
  static stateName(state: ChargebackDebitMemoState) {
    if (state === "unprocessed") {
      return "Unvalidated";
    } else if (state === "uninvestigated") {
      return "New";
    } else if (state === "investigating") {
      return "Investigating";
    } else if (state === "investigated") {
      return "Investigated";
    } else if (state === "approved") {
      return "Approved";
    } else if (state === "error") {
      return "Error";
    } else if (state === "sending_to_edi") {
      return "Sending to EDI";
    } else if (state === "sent_to_edi") {
      return "Sent to EDI";
    } else if (state === "received_by_edi") {
      return "Received by EDI";
    } else if (state === "accepted_by_edi") {
      return "Accepted by EDI";
    } else if (state === "rejected_by_edi") {
      return "Rejected by EDI";
    } else if (state === "error_sending_to_edi") {
      return "Error sending to EDI";
    } else if (state === "sent_manually") {
      return "Sent manually";
    } else if (state === "accepted_manually") {
      return "Accepted manually";
    } else if (state === "rejected_manually") {
      return "Rejected manually";
    } else {
      return "Internal Server Error";
    }
  }
}
