import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { PriceClassification } from "../types";

type Props = {
  priceClassification?: PriceClassification;
  setPriceClassification: (arg: PriceClassification) => void;
};

export default function PriceClassificationSelector({
  priceClassification,
  setPriceClassification,
}: Props) {
  return (
    <FormControl fullWidth>
      <InputLabel id="price-classification-label">
        Price Classification
      </InputLabel>
      <Select
        labelId="price-classification-lablel"
        id="price-classification-select"
        value={priceClassification}
        label="Type"
        onChange={(event: SelectChangeEvent) => {
          const newPriceClassification: PriceClassification = event.target
            .value as PriceClassification;
          setPriceClassification(newPriceClassification);
        }}
      >
        <MenuItem value={"unit"}>Unit</MenuItem>
        <MenuItem value={"case"}>Case</MenuItem>
      </Select>
    </FormControl>
  );
}
