import { Box } from "@mui/material";
import React from "react";

import WholesalerAPI from "../api/Wholesaler.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { Wholesaler } from "../types";
import SearchBarAutocomplete from "./SearchBarAutocomplete";

type Props = {
  setCurrentWholesaler: (wholesaler: Wholesaler) => void;
};

export default function WholesalerSearchBarAutocomplete({
  setCurrentWholesaler,
}: Props) {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [value, setValue] = React.useState<any | null>(null);
  const [inputValue, setInputValue] = React.useState("");

  const { data } = WholesalerAPI.useSearch({
    businessUnit: currentBusinessUnit,
    query: inputValue,
  });

  const options = data && data.map((o) => ({ label: o.name, value: o }));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
      }}
    >
      <SearchBarAutocomplete
        getOptionLabel={(option: Wholesaler) => {
          return typeof option === "string" ? option : option.name;
        }}
        label="Find a Wholesaler"
        inputValue={inputValue}
        noOptionsText="No results"
        onChange={(event: any, newValue: any | null) => {
          setValue(newValue);
          if (newValue && newValue.value) {
            setCurrentWholesaler(newValue.value);
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options || []}
        value={value}
      />
    </Box>
  );
}
