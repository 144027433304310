import { TextField } from "@mui/material";
import React from "react";
import { UseQueryResult } from "react-query";

import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { BusinessUnit, ListResponseData, Order, TableColumn } from "../types";
import ListTable from "./ListTable";
import Loading from "./Loading";

export default function IndexTable<Type extends { id?: number }>({
  buildTableRow,
  columns,
  defaultOrderBy,
  modelClick,
  useList,
}: {
  columns: TableColumn<Type>[];
  defaultOrderBy: keyof Type;
  buildTableRow: (arg0: Type) => JSX.Element[];
  modelClick?: (arg0: Type) => void;
  useList: ({
    businessUnit,
    order,
    query,
    orderBy,
    page,
  }: {
    businessUnit?: BusinessUnit;
    order: Order;
    query?: string;
    orderBy?: keyof Type;
    page?: number;
  }) => UseQueryResult<ListResponseData<Type>, unknown>;
}) {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Type>(defaultOrderBy);
  const [query, setQuery] = React.useState<string>("");

  const { data } = useList({
    businessUnit: currentBusinessUnit,
    query,
    order,
    orderBy,
    page,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Type,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick = (model: Type) => (event: unknown) => {
    if (modelClick) {
      modelClick(model);
    }
  };

  return (
    <>
      <TextField
        id="indextable-search"
        label="Search field"
        type="search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {data ? (
        <ListTable
          columns={columns}
          buildTableRow={buildTableRow}
          handleChangePage={handleChangePage}
          handleRequestSort={handleRequestSort}
          handleRowClick={handleRowClick}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}
