import { Box, Typography } from "@mui/material";
import React from "react";

import ChargebackDebitMemoApprovedListTable from "../components/ChargebackDebitMemoApprovedListTable";
import Layout from "../components/Layout";
import LogoBox from "../components/LogoBox";

export default function ChargebackApprovedList() {
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="h2">Approved Debit Memos</Typography>
          <LogoBox />
        </Box>
        <ChargebackDebitMemoApprovedListTable />
      </Box>
    </Layout>
  );
}
