import { Box, Typography } from "@mui/material";
import React from "react";

import ContractWholesalerEligibilityAPI from "../api/ContractWholesalerEligibility.api";
import ContractWholesalerEligibilityModel from "../models/ContractWholesalerEligibility.model";
import { Wholesaler } from "../types";
import ContractWholesalerEligibilityForm from "./ContractWholesalerEligibilityForm";
import Loading from "./Loading";
import WholesalerSearchBarAutocomplete from "./WholesalerSearchBarAutocomplete";

type Props = {
  id?: number;
  contractId: number;
  handleClose: () => void;
};

export default function ContractWholesalerEligibilityModal({
  id,
  contractId,
  handleClose,
}: Props) {
  if (id) {
    return (
      <ContractWholesalerEligibilityModalEdit
        id={id}
        contractId={contractId}
        handleClose={handleClose}
      />
    );
  }
  return (
    <ContractWholesalerEligibilityModalNew
      contractId={contractId}
      handleClose={handleClose}
    />
  );
}

type PropsContractWholesalerEligibilityModalEdit = {
  id: number;
  contractId: number;
  handleClose: () => void;
};

function ContractWholesalerEligibilityModalEdit({
  id,
  contractId,
  handleClose,
}: PropsContractWholesalerEligibilityModalEdit) {
  const { data } = ContractWholesalerEligibilityAPI.useDetail(id.toString());

  if (!data) {
    return <Loading />;
  }

  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
        {data.wholesalerName}
      </Typography>
      <ContractWholesalerEligibilityForm
        contractId={contractId}
        wholesalerId={data.wholesalerId}
        contractWholesalerEligibility={data}
        handleClose={handleClose}
      />
    </>
  );
}

type PropsContractWholesalerEligibilityModalNew = {
  contractId: number;
  handleClose: () => void;
};

function ContractWholesalerEligibilityModalNew({
  contractId,
  handleClose,
}: PropsContractWholesalerEligibilityModalNew) {
  const [currentWholesaler, setCurrentWholesaler] =
    React.useState<Wholesaler>();
  return (
    <>
      <Box sx={{ marginBottom: "1rem" }}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2 }}
        >
          Add Wholesaler to Contract
        </Typography>
        <WholesalerSearchBarAutocomplete
          setCurrentWholesaler={(wholesaler) => {
            setCurrentWholesaler(wholesaler);
          }}
        />
      </Box>
      {currentWholesaler && (
        <ContractWholesalerEligibilityForm
          contractId={contractId}
          wholesalerId={currentWholesaler.id}
          contractWholesalerEligibility={ContractWholesalerEligibilityModel.createEmpty(
            {
              contractId,
              wholesalerId: currentWholesaler.id,
            },
          )}
          handleClose={handleClose}
        />
      )}
    </>
  );
}
