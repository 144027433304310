import { ChargebackLineitemState } from "../types";

export default class ChargebackLineitemModel {
  static stateName(state: ChargebackLineitemState) {
    if (state === "unprocessed") {
      return "Unvalidated";
    } else if (state === "uninvestigated") {
      return "New";
    } else if (state === "investigating") {
      return "Investigating";
    } else if (state === "pay_calculated_amount") {
      return "Pay System Amount";
    } else if (state === "pay_requested_amount") {
      return "Pay Requested Amount";
    } else if (state === "rejected") {
      return "Rejected";
    } else {
      return "Internal Server Error";
    }
  }
}
