import { Box, Typography } from "@mui/material";
import React from "react";

import BusinessUnitForm from "../components/BusinessUnitForm";
import EmptyLayout from "../components/EmptyLayout";
import BusinessUnitModel from "../models/BusinessUnit.model";

export default function OnboardBusinessUnit() {
  return (
    <EmptyLayout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <Typography variant="h2" sx={{ marginBottom: "1rem" }}>
          Onboard Business Unit
        </Typography>

        <BusinessUnitForm businessUnit={BusinessUnitModel.createEmpty()} />
      </Box>
    </EmptyLayout>
  );
}
