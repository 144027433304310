import { TableCell, TableRow } from "@mui/material";

type Props = {
  emptyRows: number;
  colSpan: number;
  height?: number;
};

export default function EmptyTableRows({ emptyRows, colSpan, height }: Props) {
  if (emptyRows === 0) {
    return <></>;
  }
  const calcHeight = height ? height : 53;
  return (
    <TableRow
      style={{
        height: calcHeight * emptyRows,
      }}
    >
      <TableCell colSpan={8} />
    </TableRow>
  );
}
