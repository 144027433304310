import { Box, Typography } from "@mui/material";

import Layout from "../components/Layout";
import WholesalerForm from "../components/WholesalerForm";
import WholesalerModel from "../models/Wholesaler.model";

export default function WholesalerNew() {
  const emptyWholesaler = WholesalerModel.createEmpty();
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">Wholesaler New</Typography>
        <WholesalerForm wholesaler={emptyWholesaler} />
      </Box>
    </Layout>
  );
}
