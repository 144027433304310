import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BusinessUnit,
  BusinessUnitCode,
  BusinessUnitCodeInput,
  ListResponseData,
  Order,
  Param,
} from "../types";
import {
  handleDelete,
  handleList,
  handleMutationError,
  handleQueryError,
  handleSave,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useList: ({
    businessUnit,
    order,
    orderBy,
    page,
  }: {
    businessUnit: BusinessUnit;
    order: Order;
    orderBy: keyof BusinessUnitCode;
    page: number;
  }) => {
    const navigate = useNavigate();
    const params: Param[] = [];
    if (businessUnit) {
      params.push({
        key: "business_unit_id",
        value: businessUnit.id.toString(),
      });
    }
    return useQuery<ListResponseData<BusinessUnitCode>>({
      cacheTime: 0,
      queryKey: ["business_unit_codes", order, orderBy, page],
      queryFn: () =>
        handleList({
          params,
          order,
          orderBy,
          page,
          baseUrl: "business_unit_codes",
        }),
      onError: handleQueryError(navigate),
      refetchInterval: 10000, // 10 seconds
    });
  },
  useSave: (input: BusinessUnitCodeInput) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<BusinessUnitCode>({
      mutationFn: () => handleSave({ baseUrl: "business_unit_codes", input }),
      onError: handleMutationError(enqueueSnackbar),
      retry: 1,
      onSuccess: async (data) => {
        await queryClient.refetchQueries(["business_unit_codes"]);
      },
    });
  },
  useDelete: (id: number) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<{ id: number }>({
      mutationFn: () => handleDelete({ baseUrl: "business_unit_codes", id }),
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: async (data) => {
        enqueueSnackbar("Deleted successfully", { variant: "success" });
        await queryClient.refetchQueries(["business_unit_codes"]);
      },
    });
  },
};
export default methods;
