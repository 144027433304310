import { useQuery } from "react-query";

import { Param } from "../types";
import { handleBasicFetch } from "./handler";

const methods = {
  useChargebackLineitems: ({
    businessUnitId,
    wholesalerId,
    startDate,
    endDate,
  }: {
    businessUnitId?: number;
    wholesalerId?: number;
    startDate: string | null;
    endDate: string | null;
  }) => {
    const params: Param[] = [];
    if (businessUnitId) {
      params.push({ key: "business_unit_id", value: businessUnitId });
    }
    if (wholesalerId) {
      params.push({ key: "wholesaler_id", value: wholesalerId });
    }
    if (startDate) {
      params.push({ key: "start_date", value: startDate });
    }
    if (endDate) {
      params.push({ key: "end_date", value: endDate });
    }
    return useQuery<{ chargebackLineitems: number }>({
      queryKey: [
        "statistics",
        "chargeback_lineitems",
        businessUnitId,
        wholesalerId,
        startDate,
        endDate,
      ],
      queryFn: () =>
        handleBasicFetch({
          url: "statistics/chargeback_lineitems",
          params,
        }),
    });
  },
  useChargebackDebitMemoGlobalCounts: () => {
    return useQuery<{
      requireApprovalCount: number;
      investigationCount: number;
      approvedCount: number;
    }>({
      cacheTime: 5000,
      queryKey: ["statistics", "chargeback_debit_memo_global_counts"],
      queryFn: () =>
        handleBasicFetch({
          url: "statistics/chargeback_debit_memo_global_counts",
          params: [],
        }),
    });
  },
  useChargebackFileGlobalCounts: () => {
    return useQuery<{
      errorCount: number;
    }>({
      cacheTime: 5000,
      queryKey: ["statistics", "chargeback_file_global_counts"],
      queryFn: () =>
        handleBasicFetch({
          url: "statistics/chargeback_file_global_counts",
          params: [],
        }),
    });
  },
  useAutoImportedFileGlobalCounts: () => {
    return useQuery<{
      filesImportedTodayCount: number;
      errorCount: number;
    }>({
      cacheTime: 5000,
      queryKey: ["statistics", "auto_imported_file_global_counts"],
      queryFn: () =>
        handleBasicFetch({
          url: "statistics/auto_imported_file_global_counts",
          params: [],
        }),
    });
  },
};

export default methods;
