import camelcaseKeys from "camelcase-keys";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  BusinessUnit,
  ErrorResult,
  ListResponseData,
  Order,
  Param,
  Wholesaler,
  WholesalerInput,
  WholesalerSearchQueryProps,
} from "../types";
import client from "./client";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleQueryError,
  handleSave,
  handleSimpleList,
} from "./handler";

async function searchWholesalers({
  businessUnit,
  query,
}: WholesalerSearchQueryProps): Promise<Wholesaler[]> {
  try {
    const searchParams = new URLSearchParams();
    if (query) {
      searchParams.set("query", query);
    }
    if (businessUnit) {
      searchParams.set("business_unit_id", businessUnit.id.toString());
    }
    const data: Wholesaler[] = await client
      .get("wholesalers/search", {
        searchParams,
      })
      .json();

    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

const methods = {
  useList: ({
    businessUnit,
    order,
    orderBy,
    page,
    query,
  }: {
    businessUnit: BusinessUnit | undefined;
    order: Order;
    orderBy: keyof Wholesaler;
    page?: number;
    query?: string;
  }) => {
    const navigate = useNavigate();
    const params: Param[] = [];
    if (businessUnit) {
      params.push({ key: "business_unit_id", value: businessUnit.id });
    }
    return useQuery<ListResponseData<Wholesaler>>({
      cacheTime: 0,
      queryKey: ["wholesalers", businessUnit, order, orderBy, page, query],
      queryFn: () =>
        handleList({
          baseUrl: "wholesalers",
          params,
          order,
          orderBy,
          page,
          query,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useAll: ({ businessUnit }: { businessUnit: BusinessUnit }) => {
    const navigate = useNavigate();
    return useQuery<Wholesaler[]>({
      cacheTime: 5000,
      queryKey: ["all_wholesalers", businessUnit.id],
      queryFn: () =>
        handleSimpleList({
          url: "wholesalers/all",
          params: [{ key: "business_unit_id", value: businessUnit.id }],
        }),
      onError: handleQueryError(navigate),
    });
  },
  useSearch: ({ businessUnit, query }: WholesalerSearchQueryProps) => {
    const navigate = useNavigate();
    return useQuery<Wholesaler[]>({
      queryKey: ["wholesalers", businessUnit, query],
      queryFn: () => searchWholesalers({ businessUnit, query }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<Wholesaler>({
      cacheTime: 0,
      queryKey: ["wholesalers", id],
      queryFn: () => handleDetail({ baseUrl: "wholesalers", id }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (wholesalerInput: WholesalerInput) => {
    const navigate = useNavigate();
    return useMutation<Wholesaler | ErrorResult>({
      mutationFn: () =>
        handleSave({ input: wholesalerInput, baseUrl: "wholesalers" }),
      onError: handleQueryError(navigate),
      retry: 1,
    });
  },
  useDelete: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number } | ErrorResult>({
      mutationFn: () => handleDelete({ baseUrl: "wholesalers", id }),
      onError: handleQueryError(navigate),
    });
  },
};

export default methods;
