import { TableCell } from "@mui/material";
import { isNil } from "lodash";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import ReportAPI from "../api/Report.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import ReportModel from "../models/Report.model";
import { Order, Report, TableColumn } from "../types";
import { extractDate } from "../utils";
import ListTable from "./ListTable";
import Loading from "./Loading";

const columns: TableColumn<Report>[] = [
  { field: "state", label: "State" },
  { field: "reportType", label: "Report Type" },
  { field: "file", label: "File" },
  { field: "createdAt", label: "Created Date" },
];

function buildTableRow(report: Report): JSX.Element[] {
  return [
    <TableCell key={"state"}>{ReportModel.stateName(report.state)}</TableCell>,
    <TableCell key={"reportType"}>
      {ReportModel.reportTypeName(report.reportType)}
    </TableCell>,
    <TableCell key={"file"}>
      {!isNil(report.file) ? (
        <Link target="_blank" to={report.file}>
          {report.filename}
        </Link>
      ) : (
        ""
      )}
    </TableCell>,
    <TableCell key={"createdAt"}>{extractDate(report.createdAt)}</TableCell>,
  ];
}

export default function ReportListTable() {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Report>("createdAt"); // TODO

  const navigate = useNavigate();

  const { data } = ReportAPI.useList({
    businessUnit: currentBusinessUnit,
    order,
    orderBy,
    page,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Report,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick = (report: Report) => (event: unknown) => {
    navigate(`/reports/${report.id}`);
  };

  if (data) {
    return (
      <ListTable
        columns={columns}
        buildTableRow={buildTableRow}
        handleChangePage={handleChangePage}
        handleRowClick={handleRowClick}
        handleRequestSort={handleRequestSort}
        meta={data.meta}
        order={order}
        orderBy={orderBy}
        rows={data.data}
      />
    );
  }
  return <Loading />;
}
