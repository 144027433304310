import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { fetchBusinessUnit } from "../api/BusinessUnit.api";
import ChargebackDebitMemoAPI from "../api/ChargebackDebitMemo.api";
import ChargebackDebitMemoInvestigatingList from "../components/ChargebackDebitMemoInvestigatingList";
import ChargebackDebitMemoReprocessAllButton from "../components/ChargebackDebitMemoReprocessAllButton";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";

export default function ChargebackInvestigatingList() {
  const navigate = useNavigate();
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [reprocessing, setReprocessing] = React.useState(
    currentBusinessUnit && currentBusinessUnit.state === "reprocessing",
  );

  const { mutateAsync, isLoading } =
    ChargebackDebitMemoAPI.useExportInvestigating(currentBusinessUnit?.id);

  const handleDownload = async () => {
    try {
      const res = await mutateAsync();
      navigate(`/exports/${res["id"]}`);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (reprocessing && currentBusinessUnit) {
      const interval = setInterval(async () => {
        const data = await fetchBusinessUnit(currentBusinessUnit.id);
        if (data && data.state !== "reprocessing") {
          setReprocessing(false);
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [reprocessing, currentBusinessUnit]);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">Debit Memos that require action</Typography>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
              flexDirection: "row",
              gap: "1rem",
            }}
          >
            <IconButton
              aria-label="Download as an Excel"
              size="large"
              onClick={handleDownload}
              disabled={isLoading}
            >
              <FileDownloadIcon />
            </IconButton>
            <ChargebackDebitMemoReprocessAllButton
              setReprocessing={setReprocessing}
            />
          </Box>
        </Box>
        {reprocessing ? (
          <Box>
            <Typography variant="h6">
              Debit Memos are being reprocessed, please wait
            </Typography>
            <Box sx={{ marginTop: "3rem" }}>
              <Loading />
            </Box>
          </Box>
        ) : (
          <ChargebackDebitMemoInvestigatingList />
        )}
      </Box>
    </Layout>
  );
}
