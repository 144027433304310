import { AddressInput, MemberInput } from "../types";
import AddressModel from "./Address.model";

export default class Member {
  public id?: number;
  public facilityName: string;
  public facilityId: string;
  public hin: string;
  public dea: string;
  public id340B: string;
  public otherId: string;
  public classOfTrade: string;
  public archived: boolean;
  public createdAt?: Date;
  public address: AddressInput;

  constructor({
    id,
    facilityName,
    facilityId,
    dea,
    hin,
    id340B,
    otherId,
    classOfTrade,
    archived,
    createdAt,
    address,
  }: MemberInput) {
    this.id = id;
    this.facilityName = facilityName;
    this.facilityId = facilityId;
    this.dea = dea;
    this.hin = hin;
    this.id340B = id340B;
    this.otherId = otherId;
    this.classOfTrade = classOfTrade;
    this.archived = archived;
    this.createdAt = createdAt;
    this.address = address;
  }

  static createEmpty() {
    return new Member({
      facilityName: "",
      facilityId: "",
      dea: "",
      hin: "",
      id340B: "",
      otherId: "",
      classOfTrade: "",
      archived: false,
      address: AddressModel.createEmpty(),
      // createdAt: null,
    });
  }
}
