import { Box, Button, Paper } from "@mui/material";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";

import ChargebackFileAPI from "../api/ChargebackFile.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";

export default function ChargebackFileForm() {
  const navigate = useNavigate();
  const { currentBusinessUnit } = useBusinessUnitContext();
  const { enqueueSnackbar } = useSnackbar();

  const [files, setFiles] = React.useState<any[]>();

  const onDrop = React.useCallback(async (acceptedFiles: any[]) => {
    if (acceptedFiles.length >= 1 && acceptedFiles.length <= 100) {
      const totalFileSize = acceptedFiles
        .map((file) => file.size)
        .reduce((a, b) => a + b, 0);

      if (totalFileSize / 1024 > 1024) {
        enqueueSnackbar(
          `Exceeded maxium size of all files: ${totalFileSize / 1024}`,
          { variant: "error" },
        );
      } else {
        setFiles(acceptedFiles);
      }
    }
    if (acceptedFiles.length > 100) {
      enqueueSnackbar(
        "Exceeded maxium number of files uploaded. No more than a 100 files at a time.",
        { variant: "error" },
      );
    }
  }, []);

  // https://learn.microsoft.com/en-us/archive/blogs/vsofficedeveloper/office-2007-file-format-mime-types-for-http-content-streaming-2
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: {
        "text/csv": [".csv"],
        "text/plain": [".edi"],
        "text/xml": [".xml"],
        "application/xml": [".xml"],
        // "application/vnd.ms-excel": [".xls", ".xlt", ".xla"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
    });

  const { isLoading, mutateAsync } = ChargebackFileAPI.useSave({
    files,
    businessUnitId: currentBusinessUnit && currentBusinessUnit.id,
  });

  const onSave = async () => {
    const res = await mutateAsync();
    if ("errors" in res) {
      res.errors.forEach((message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      enqueueSnackbar("Saved successfully", { variant: "success" });
      navigate(`/chargebacks/files`);
    }
  };

  const throttledOnSave = React.useCallback(debounce(onSave, 1000), []);

  if (fileRejections.length > 0) {
    fileRejections.forEach(({ file, errors }) => {
      enqueueSnackbar(`Failed to upload file: ${errors[0].message}`, {
        variant: "error",
      });
    });
  }

  return (
    <Box>
      <Paper
        elevation={3}
        sx={{ padding: "2rem", justifyContent: "center", display: "flex" }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <BoxText
          isDragActive={isDragActive}
          fileLoaded={!!files}
          fileName={
            files && files.length > 0 ? files.map((f) => f.name).join(", ") : ""
          }
        />
      </Paper>
      {!!files && (
        <Button
          fullWidth
          onClick={throttledOnSave}
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          UPLOAD
        </Button>
      )}
    </Box>
  );
}

type PropsBoxText = {
  isDragActive: boolean;
  fileLoaded: boolean;
  fileName?: string;
};
function BoxText({ isDragActive, fileLoaded, fileName }: PropsBoxText) {
  if (isDragActive) {
    return <p>Drop the file here ...</p>;
  } else if (fileLoaded) {
    return (
      <p>
        File uploaded: <strong>{fileName}</strong>
      </p>
    );
  }
  return <p>Drag 'n' drop a file here, or click to select the file</p>;
}
