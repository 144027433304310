import { ContractInput } from "../types";

type CreateEmptyProps = {
  groupId?: number;
  businessUnitId?: number;
};

export default class Contract {
  public id?: number;
  public refNum: string;
  public prevRefNum: string;
  public name: string;
  public contractType?: string;
  public effectiveDate?: Date | null;
  public endDate?: Date | null;
  public allWholesalers: boolean;
  public noMemberCheck: boolean;
  public notificationInstructions: string;
  public archived: boolean;
  public createdAt?: Date;
  public groupId?: number;
  public businessUnitId?: number;

  constructor({
    id,
    refNum,
    prevRefNum,
    name,
    contractType,
    effectiveDate,
    endDate,
    allWholesalers,
    noMemberCheck,
    notificationInstructions,
    archived,
    createdAt,
    groupId,
    businessUnitId,
  }: ContractInput) {
    this.id = id;
    this.refNum = refNum;
    this.prevRefNum = prevRefNum;
    this.name = name;
    this.contractType = contractType;
    this.effectiveDate = effectiveDate;
    this.endDate = endDate;
    this.allWholesalers = allWholesalers;
    this.noMemberCheck = noMemberCheck;
    this.notificationInstructions = notificationInstructions;
    this.archived = archived;
    this.createdAt = createdAt;
    this.groupId = groupId;
    this.businessUnitId = businessUnitId;
  }

  static createEmpty({ groupId, businessUnitId }: CreateEmptyProps) {
    return new Contract({
      name: "",
      refNum: "",
      prevRefNum: "",
      // contractType
      // effectiveDate: null,
      // endDate: null,
      allWholesalers: false,
      noMemberCheck: false,
      notificationInstructions: "",
      archived: false,
      // createdAt: null,
      groupId,
      businessUnitId,
    });
  }
}
