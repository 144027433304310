import { Box, Button, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import ChargebackFileAPI from "../api/ChargebackFile.api";
import ArchiveButton from "../components/ArchiveButton";
import ChargebackFileChargebackDebitMemoList from "../components/ChargebackFileChargebackDebitMemoList";
import Layout from "../components/Layout";
import LogoBox from "../components/LogoBox";
import ChargebackFileModel from "../models/ChargebackFile.model";

export default function ChargebackFileInfo() {
  const { id } = useParams();
  const { data } = ChargebackFileAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">Chargeback File Info</Typography>
          <LogoBox />
        </Box>
        {data && (
          <Box>
            <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
              <TextField
                disabled
                label="File Name"
                variant="standard"
                value={data.filename}
                fullWidth
              />
            </Box>
            <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
              <TextField
                disabled
                label="State"
                variant="standard"
                value={ChargebackFileModel.stateName(data.state)}
                fullWidth
              />
            </Box>
            <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
              <TextField
                disabled
                label="Import Type"
                variant="standard"
                value={data.importType === "sftp" ? "TrueCommerce" : "Manual"}
                fullWidth
              />
            </Box>
            {data.state === "error" && (
              <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
                Error: {data.errorMessage}
              </Box>
            )}
            <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
              <Link target="_blank" to={data.file}>
                Download File
              </Link>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "4rem" }}>
              {data && data.state === "error" && (
                <RerunChargebackFile id={data.id} />
              )}
              {data &&
                (data.state === "error" ||
                  data.state === "processed_lineitems" ||
                  data.state === "exported") && (
                  <ArchiveChargebackFileButton id={data.id} />
                )}
            </Box>
          </Box>
        )}
        <Box>
          <Typography variant="h4">Chargeback Debit Memos in file</Typography>
          {id && (
            <ChargebackFileChargebackDebitMemoList chargebackFileId={id} />
          )}
        </Box>
      </Box>
    </Layout>
  );
}

type RerunChargebackFileProps = {
  id: number;
};

function RerunChargebackFile({ id }: RerunChargebackFileProps) {
  const { mutateAsync, isLoading } = ChargebackFileAPI.useRerun(id);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = async () => {
    const res = await mutateAsync();
    if ("errors" in res) {
      res.errors.forEach((message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      enqueueSnackbar("Reran file successfully", { variant: "success" });
    }
  };

  return (
    <Button onClick={handleClick} variant="contained" disabled={isLoading}>
      Rerun
    </Button>
  );
}

type ArchiveChargebackFileButtonProps = {
  id: number;
};

function ArchiveChargebackFileButton({ id }: ArchiveChargebackFileButtonProps) {
  const navigate = useNavigate();

  const { mutateAsync } = ChargebackFileAPI.useDelete(id);
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = async () => {
    const res = await mutateAsync();
    if ("errors" in res) {
      res.errors.forEach((message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      navigate("/chargebacks/files");
    }
  };

  return <ArchiveButton onDelete={onDelete} />;
}
