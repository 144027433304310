import { Box, Typography } from "@mui/material";

import ChargebackExportGroupListTable from "../components/ChargebackExportGroupListTable";
import Layout from "../components/Layout";

export default function ChargebackExportGroupList() {
  return (
    <Layout>
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Box>
          <Typography variant="h2">Export Files</Typography>
        </Box>
        <ChargebackExportGroupListTable />
      </Box>
    </Layout>
  );
}
