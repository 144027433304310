import { Box, Typography } from "@mui/material";
import React from "react";

import ExportListSearch from "../components/ExportListSearch";
import Layout from "../components/Layout";

export default function ChargebackExportedList() {
  return (
    <Layout>
      <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Typography variant="h2">Search Approved DebitMemos</Typography>
        </Box>
        <Box>
          <ExportListSearch />
        </Box>
      </Box>
    </Layout>
  );
}
