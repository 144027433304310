import { Box, Modal } from "@mui/material";
import React from "react";

import ContractRefNumAPI from "../api/ContractRefNum.api";
import ContractRefNumForm from "./ContractRefNumForm";

type Props = {
  contractRefNumId?: number;
  contractId: number;
  open: boolean;
  handleClose: () => void;
};

export default function ContractRefNumModal({
  contractRefNumId,
  contractId,
  open,
  handleClose,
}: Props) {
  const { data } = ContractRefNumAPI.useDetail(
    contractRefNumId ? contractRefNumId.toString() : undefined,
  );

  const afterSaveCallback = () => {
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-contract-ref-num-title"
      aria-describedby="modal-contract-ref-num-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        {contractRefNumId && data && (
          <ContractRefNumForm
            afterSaveCallback={afterSaveCallback}
            id={contractRefNumId}
            contractId={contractId}
            contractRefNumInput={data}
          />
        )}
        {!contractRefNumId && (
          <ContractRefNumForm
            afterSaveCallback={afterSaveCallback}
            contractId={contractId}
          />
        )}
      </Box>
    </Modal>
  );
}
