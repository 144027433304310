import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import React from "react";

import Layout from "../components/Layout";
import ProductForm from "../components/ProductForm";
import ProductListTable from "../components/ProductListTable";
import ProductModel from "../models/Product.model";

export default function ProductList() {
  const [openModal, setOpenModal] = React.useState(false);
  const emptyProduct = ProductModel.createEmpty();

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">Product List</Typography>

          <>
            <IconButton
              aria-label="Create a Product"
              size="large"
              onClick={handleOpen}
            >
              <AddCircleIcon color="primary" />
            </IconButton>
            <Modal
              open={openModal}
              onClose={handleClose}
              aria-labelledby="modal-create-product"
              aria-describedby="modal-create-product"
            >
              <Box
                sx={{
                  position: "absolute" as "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 800,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h6" component="h2">
                    Create Product
                  </Typography>
                  <ProductForm product={emptyProduct} />
                </Box>
              </Box>
            </Modal>
          </>
        </Box>
        <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          {/*<WholesalerSearchBarAutocomplete />*/}
        </Box>
        <ProductListTable />
      </Box>
    </Layout>
  );
}
