import camelcaseKeys from "camelcase-keys";
import { snakeCase } from "lodash";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  ChargebackExportGroup,
  ChargebackExportGroupProps,
  ErrorResult,
  ListResponseData,
  MetaPagination,
} from "../types";
import client from "./client";
import { handleBasicPost, handleQueryError } from "./handler";

async function fetchChargebackGroups({
  businessUnit,
  state,
  order,
  orderBy,
  page,
}: ChargebackExportGroupProps): Promise<
  ListResponseData<ChargebackExportGroup>
> {
  try {
    const searchParams = new URLSearchParams();
    if (page) {
      searchParams.set("page", page.toString());
    }
    if (order) {
      searchParams.set("order", order);
    }
    if (orderBy) {
      searchParams.set("order_by", snakeCase(orderBy));
    }
    if (businessUnit) {
      searchParams.set("business_unit_id", businessUnit.id.toString());
    }
    if (state) {
      searchParams.set("state", state);
    }
    const response: { data: ChargebackExportGroup[]; meta: MetaPagination } =
      await client
        .get("chargeback_export_groups", {
          searchParams,
        })
        .json();

    return camelcaseKeys(
      { data: response.data || [], meta: response.meta },
      { deep: true },
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

async function fetchChargebackExportGroup(
  id: string | undefined,
): Promise<ChargebackExportGroup> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }

    const data: ChargebackExportGroup = await client
      .get(`chargeback_export_groups/${id}`)
      .json();
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

async function deleteExportGroup(id: number): Promise<ChargebackExportGroup> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data: ChargebackExportGroup = await client
      .delete(`chargeback_export_groups/${id}`)
      .json();

    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

const methods = {
  useList: ({
    businessUnit,
    state,
    order,
    orderBy,
    page,
  }: ChargebackExportGroupProps) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<ChargebackExportGroup>>({
      cacheTime: 0,
      queryKey: ["chargeback_export_groups", state, order, orderBy, page],
      queryFn: () =>
        fetchChargebackGroups({
          businessUnit,
          state,
          order,
          orderBy,
          page,
        }),
      onError: handleQueryError(navigate),
      refetchInterval: 5000,
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<ChargebackExportGroup>({
      cacheTime: 0,
      queryKey: ["chargeback_export_groups", id],
      queryFn: () => fetchChargebackExportGroup(id),
      onError: handleQueryError(navigate),
      refetchInterval: 5000,
    });
  },
  useReprocess: (id: number) => {
    const navigate = useNavigate();
    return useMutation<ChargebackExportGroup>({
      mutationFn: () =>
        handleBasicPost({
          baseUrl: `chargeback_export_groups/${id}/reprocess`,
          input: {},
        }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number } | ErrorResult>({
      mutationFn: () => deleteExportGroup(id),
      onError: handleQueryError(navigate),
    });
  },
};

export default methods;
