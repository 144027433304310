import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";

// import GroupSearchBarAutocomplete from "../components/GroupSearchBarAutocomplete";
import Layout from "../components/Layout";
import MemberListTable from "../components/MemberListTable";

export default function MemberList() {
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">Members</Typography>
          <Link to={"/members/new"}>
            <IconButton aria-label="Create a Group" size="large">
              <AddCircleIcon color="primary" />
            </IconButton>
          </Link>
        </Box>
        <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          {/*<GroupSearchBarAutocomplete />*/}
        </Box>
        <MemberListTable />
      </Box>
    </Layout>
  );
}
