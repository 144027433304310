import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import ContractListTable from "../components/ContractListTable";
import Layout from "../components/Layout";

export default function ContractList() {
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">Contracts</Typography>
          <Link to={"/contracts/new"}>
            <IconButton aria-label="Create a Contract" size="large">
              <AddCircleIcon color="primary" />
            </IconButton>
          </Link>
        </Box>
        <ContractListTable />
      </Box>
    </Layout>
  );
}
