import { Button } from "@mui/material";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import ChargebackDebitMemoAPI from "../api/ChargebackDebitMemo.api";

type Props = {
  id: number;
  refetch: () => void;
};

export default function ChargebackDebitMemoReinvestigateButton({
  id,
  refetch,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync, isLoading } =
    ChargebackDebitMemoAPI.useReinvestigate(id);

  const handleReinvestigate = async () => {
    const res = await mutateAsync();
    if ("errors" in res) {
      res.errors.forEach((message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      enqueueSnackbar("Reinvestigating Debit Memo", { variant: "success" });
      refetch();
    }
  };

  const throttledHandleReinvestigate = React.useCallback(
    debounce(handleReinvestigate, 1000),
    [],
  );

  return (
    <Button
      onClick={throttledHandleReinvestigate}
      color="secondary"
      variant={"contained"}
      disabled={isLoading}
    >
      Reinvestigate
    </Button>
  );
}
