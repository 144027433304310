import camelcaseKeys from "camelcase-keys";
import { snakeCase } from "lodash";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  ChargebackFile,
  ChargebackFileInput,
  ChargebackFileQueryProps,
  ChargebackFileWithStats,
  ErrorResult,
  ListResponseData,
  MetaPagination,
} from "../types";
import client from "./client";
import {
  handleDelete,
  handleDetail,
  handleMutationError,
  handleQueryError,
  handleSave,
} from "./handler";

async function fetchChargebackfiles({
  businessUnit,
  state,
  order,
  orderBy,
  page,
  query,
  importType,
}: ChargebackFileQueryProps): Promise<ListResponseData<ChargebackFile>> {
  try {
    const searchParams = new URLSearchParams();
    if (page) {
      searchParams.set("page", page.toString());
    }
    if (query) {
      searchParams.set("query", query);
    }
    if (importType) {
      searchParams.set("import_type", importType);
    }
    if (order) {
      searchParams.set("order", order);
    }
    if (orderBy) {
      searchParams.set("order_by", snakeCase(orderBy));
    }
    if (businessUnit) {
      searchParams.set("business_unit_id", businessUnit.id.toString());
    }
    if (state) {
      searchParams.set("state", state);
    }
    const response: { data: ChargebackFile[]; meta: MetaPagination } =
      await client
        .get("chargeback_files", {
          searchParams,
        })
        .json();

    return camelcaseKeys(
      { data: response.data || [], meta: response.meta },
      { deep: true },
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

async function rerunFile(id: number): Promise<ChargebackFile> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data: ChargebackFile = await client
      .post(`chargeback_files/${id}/rerun`)
      .json();

    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

const methods = {
  useList: ({
    businessUnit,
    state,
    order,
    orderBy,
    page,
    query,
    importType,
  }: ChargebackFileQueryProps) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<ChargebackFile>>({
      cacheTime: 0,
      queryKey: [
        "chargeback_files",
        state,
        order,
        orderBy,
        page,
        query,
        importType,
      ],
      queryFn: () =>
        fetchChargebackfiles({
          businessUnit,
          state,
          order,
          orderBy,
          page,
          query,
          importType,
        }),
      onError: handleQueryError(navigate),
      refetchInterval: 10000, // 10 seconds
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<ChargebackFileWithStats>({
      cacheTime: 0,
      queryKey: ["chargeback_files", id],
      queryFn: () =>
        handleDetail({
          id: id,
          baseUrl: "chargeback_files",
        }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (chargebackFileInput: ChargebackFileInput) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation<ChargebackFile | ErrorResult>({
      mutationFn: () =>
        handleSave({
          baseUrl: "chargeback_files",
          input: chargebackFileInput,
        }),
      onError: handleMutationError(enqueueSnackbar),
    });
  },
  useRerun: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number } | ErrorResult>({
      mutationFn: () => rerunFile(id),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number } | ErrorResult>({
      mutationFn: () =>
        handleDelete({
          baseUrl: "chargeback_files",
          id,
        }),
      onError: handleQueryError(navigate),
    });
  },
};

export default methods;
