import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import WholesalerAPI from "../api/Wholesaler.api";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import ProductWholesalerPriceManagement from "../components/ProductWholesalerPriceManagement";
import WholesalerDistributionCenterTable from "../components/WholesalerDistributionCenterTable";
import WholesalerForm from "../components/WholesalerForm";

export default function WholesaleEdit() {
  const { id } = useParams();
  const { data, isLoading } = WholesalerAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">Wholesaler Edit</Typography>

        {isLoading ? (
          <Loading />
        ) : (
          data && (
            <WholesalerForm wholesaler={data}>
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <Typography variant="h4">Distribution Centers</Typography>
                  <Link
                    to={`/wholesalers/${id}/wholesaler_distribution_centers/new`}
                  >
                    <IconButton
                      aria-label="Create a Distribution Center"
                      size="large"
                    >
                      <AddCircleIcon color="primary" />
                    </IconButton>
                  </Link>
                </Box>
                <WholesalerDistributionCenterTable wholesaler={data} />
                <ProductWholesalerPriceManagement wholesaler={data} />
              </>
            </WholesalerForm>
          )
        )}
      </Box>
    </Layout>
  );
}
