import { ThemeOptions, createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    custom: {
      red: string;
      green: string;
      lightBlue: string;
      mainBlue: string;
      gray: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      red?: string;
      green?: string;
      lightBlue?: string;
      mainBlue?: string;
      gray?: string;
    };
  }
}

export interface CustomThemeOptions extends ThemeOptions {
  custom: {
    red: string;
    green: string;
    lightBlue: string;
    mainBlue: string;
    gray: string;
  };
}

export const themeOptions: CustomThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#254061",
      light: "#449ca2",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#449ca2",
    },
    info: {
      main: "#58595b",
    },
    success: {
      main: "#88a80d",
    },
    error: {
      main: "#de1726",
    },
    text: {
      primary: "rgba(10,10,10,0.87)",
    },
  },
  custom: {
    red: "#de1726",
    green: "#88a80d",
    lightBlue: "#449ca2",
    mainBlue: "#254061",
    gray: "#58595b",
  },
};

export default createTheme(themeOptions);
