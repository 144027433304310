import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TableCell,
  TextField,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import TagAPI from "../api/Tag.api";
import IndexTable from "../components/IndexTable";
import Layout from "../components/Layout";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { BusinessUnit, TableColumn, Tag } from "../types";

// TODO
// create a Modal to add new tags

const columns: TableColumn<Tag>[] = [{ field: "name", label: "Name" }];

function buildTableRow(tag: Tag): JSX.Element[] {
  return [<TableCell key={"name"}>{tag.name}</TableCell>];
}

export default function TagList() {
  const navigate = useNavigate();
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [open, setOpen] = React.useState<boolean>(false);

  const modelClick = (tag: Tag) => {
    navigate(`/tags/${tag.id}`);
  };
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">Tags</Typography>

          <IconButton
            aria-label="Create a Tag"
            size="large"
            onClick={() => setOpen(true)}
          >
            <AddCircleIcon color="primary" />
          </IconButton>
        </Box>
        <IndexTable
          columns={columns}
          buildTableRow={buildTableRow}
          defaultOrderBy={"name"}
          useList={TagAPI.useList}
          modelClick={modelClick}
        />

        {currentBusinessUnit && (
          <CreateTag
            businessUnit={currentBusinessUnit}
            open={open}
            onClose={() => setOpen(false)}
          />
        )}
      </Box>
    </Layout>
  );
}

function CreateTag({
  businessUnit,
  open,
  onClose,
}: {
  businessUnit: BusinessUnit;
  open: boolean;
  onClose: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = React.useState<string>("");

  const { mutateAsync } = TagAPI.useSave({
    businessUnitId: businessUnit.id,
    name,
  });

  const handleCreate = async () => {
    try {
      if (isEmpty(name)) {
        enqueueSnackbar("Requires a name.");
        return;
      }
      await mutateAsync();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-create"
      aria-describedby="modal-create"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TextField
          label="Name"
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
          fullWidth
        />
        <Button onClick={handleCreate} variant="contained" fullWidth>
          Create
        </Button>
      </Box>
    </Modal>
  );
}
