import { TableCell } from "@mui/material";
import React from "react";

import ChargebackDebitMemoApi from "../api/ChargebackDebitMemo.api";
import { ChargebackDebitMemo, Order, TableColumn } from "../types";
import { centsToDollars, extractDate } from "../utils";
import ListTable from "./ListTable";
import Loading from "./Loading";

const columns: TableColumn<ChargebackDebitMemo>[] = [
  { field: "debitMemo", label: "Debit Memo" },
  { field: "approvedOn", label: "Approved On" },
  { field: "wholesaler", label: "Wholesaler", orderBy: false },
  { field: "totalChargebackLineitems", label: "Lines #", orderBy: false },
  { field: "requiresActionTotal", label: "Errors #", orderBy: false },
  { field: "requestedAmount", label: "Requested $", orderBy: false },
  { field: "calculatedAmount", label: "Calculated $", orderBy: false },
  { field: "payoutAmount", label: "Approved $", orderBy: false },
];

function buildTableRow(
  chargebackDebitMemo: ChargebackDebitMemo,
): JSX.Element[] {
  return [
    <TableCell key={"debitMemo"}>
      {chargebackDebitMemo.highlight ? (
        <div
          dangerouslySetInnerHTML={{
            __html: chargebackDebitMemo.highlight,
          }}
          className="[&>em]:backgroundColor:yellow"
        />
      ) : (
        chargebackDebitMemo.debitMemo
      )}
    </TableCell>,
    <TableCell key={"approvedOn"}>
      {extractDate(chargebackDebitMemo.approvedOn)}
    </TableCell>,
    <TableCell key={"wholesaler"}>
      {chargebackDebitMemo.wholesaler && chargebackDebitMemo.wholesaler.name}
    </TableCell>,
    <TableCell key={"totalChargebackLineitems"}>
      {chargebackDebitMemo.totalChargebackLineitems}
    </TableCell>,
    <TableCell key={"requiresActionTotal"}>
      {chargebackDebitMemo.requiresActionTotal}
    </TableCell>,
    <TableCell key={"requestedAmount"}>
      {centsToDollars(chargebackDebitMemo.requestedAmount)}
    </TableCell>,
    <TableCell key={"calculatedAmount"}>
      {centsToDollars(chargebackDebitMemo.calculatedAmount)}
    </TableCell>,
    <TableCell key={"payoutAmount"}>
      {centsToDollars(chargebackDebitMemo.payoutAmount)}
    </TableCell>,
  ];
}

export default function ChargebackFileChargebackDebitMemoList({
  chargebackFileId,
}: {
  chargebackFileId: string | number;
}) {
  // const [query, setQuery] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof ChargebackDebitMemo>("debitMemo");

  const { data } = ChargebackDebitMemoApi.useCustomEndpointList({
    endpoint: `chargeback_files/${chargebackFileId}/chargeback_debit_memos`,
    order,
    orderBy,
    page,
    query: undefined,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ChargebackDebitMemo,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      {data ? (
        <ListTable
          columns={columns}
          buildTableRow={buildTableRow}
          handleChangePage={handleChangePage}
          handleRequestSort={handleRequestSort}
          // handleRowClick,
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}
