import { GroupInput } from "../types";

export default class Group {
  public id?: number;
  public name: string;
  public description: string;
  public effectiveDate?: Date | null;
  public endDate?: Date | null;
  public dea: string;
  public hin: string;
  public archived: boolean;
  public createdAt?: Date;

  constructor({
    id,
    name,
    description,
    effectiveDate,
    endDate,
    dea,
    hin,
    archived,
    createdAt,
  }: GroupInput) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.effectiveDate = effectiveDate;
    this.endDate = endDate;
    this.dea = dea;
    this.hin = hin;
    this.archived = archived;
    this.createdAt = createdAt;
  }

  static createEmpty() {
    return new Group({
      name: "",
      description: "",
      // effectiveDate: null,
      // endDate: null,
      dea: "",
      hin: "",
      archived: false,
      // createdAt: null,
    });
  }
}
