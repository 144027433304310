import { Box, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";

import MemberAPI from "../api/Member.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { Member } from "../types";
import SearchBarAutocomplete from "./SearchBarAutocomplete";

type Props = {
  setCurrentMember: (member: Member) => void;
};

export default function MemberSearchBarAutocomplete({
  setCurrentMember,
}: Props) {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = React.useState<any | null>(null);
  const [inputValue, setInputValue] = React.useState("");

  const { data } = MemberAPI.useSearch({
    businessUnit: currentBusinessUnit,
    query: inputValue,
  });

  const handleSelect = () => {
    if (value && value.value) {
      setCurrentMember(value.value);
    } else {
      enqueueSnackbar("Must select a Member");
    }
  };

  const options =
    data &&
    data.map((o) => {
      if (o.memberNumber) {
        return {
          label: `${o.facilityName} (${o.memberNumber})`,
          value: o,
        };
      }
      return {
        label: o.facilityName,
        value: o,
      };
    });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
      }}
    >
      <SearchBarAutocomplete
        getOptionLabel={(option: Member) => {
          if (typeof option === "string") {
            return option;
          } else if (option.memberNumber) {
            return `${option.facilityName} (${option.memberNumber})`;
          }
          return option.facilityName;
        }}
        label="Find a Member"
        inputValue={inputValue}
        noOptionsText="No results"
        onChange={(event: any, newValue: any | null) => {
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options || []}
        value={value}
      />
      <Button onClick={handleSelect} variant="contained">
        Select
      </Button>
    </Box>
  );
}
