import { Box, Modal } from "@mui/material";
import React from "react";

import ProductWacPriceAPI from "../api/ProductWacPrice.api";
import ProductWacPriceModel from "../models/ProductWacPrice.model";
import Loading from "./Loading";
import ProductWacPriceForm from "./ProductWacPriceForm";

type Props = {
  handleClose: () => void;
  id?: number;
  open: boolean;
  productId: number;
};

export default function ProductWacPriceModal({
  handleClose,
  id,
  open,
  productId,
}: Props) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        {id ? (
          <ProductWacPriceModalEdit id={id} handleClose={handleClose} />
        ) : (
          <ProductWacPriceModalNew
            productId={productId}
            handleClose={handleClose}
          />
        )}
      </Box>
    </Modal>
  );
}

type PropsProductWacPriceModalEdit = {
  id: number;
  handleClose: () => void;
};

function ProductWacPriceModalEdit({
  id,
  handleClose,
}: PropsProductWacPriceModalEdit) {
  const { data } = ProductWacPriceAPI.useDetail(id.toString());
  if (!data) {
    return <Loading />;
  }
  return (
    <ProductWacPriceForm productWacPrice={data} handleClose={handleClose} />
  );
}

type PropsProductWacPriceModalNew = {
  productId: number;
  handleClose: () => void;
};

function ProductWacPriceModalNew({
  productId,
  handleClose,
}: PropsProductWacPriceModalNew) {
  return (
    <ProductWacPriceForm
      productWacPrice={ProductWacPriceModel.createEmpty({
        productId,
      })}
      handleClose={handleClose}
    />
  );
}
