import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

import ChargebackDebitMemoApi from "../api/ChargebackDebitMemo.api";
import Layout from "../components/Layout";
import ListTableWithCustomRow from "../components/ListTableWithCustomRow";
import Loading from "../components/Loading";
import WholesalerSearchBarAutocomplete from "../components/WholesalerSearchBarAutocomplete";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import ChargebackDebitMemoModel from "../models/ChargebackDebitMemo.model";
import {
  ChargebackDebitMemo,
  ChargebackDebitMemoState,
  Order,
  TableColumn,
  Wholesaler,
} from "../types";
import { centsToDollars } from "../utils";

const columns: TableColumn<ChargebackDebitMemo>[] = [
  { field: "debitMemo", label: "Debit Memo" },
  { field: "state", label: "State" },
  { field: "totalNumLineitems", label: "Total Line Items" },
  { field: "totalSubmittedClaimAmount", label: "Total Submitted Claim Amount" },
  { field: "totalApprovedAmount", label: "Total Approved Amount" },
  { field: "createdAt", label: "Action", orderBy: false },
];

export default function EdiListPage() {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [page, setPage] = React.useState<number>(0);
  const [query, setQuery] = React.useState<string>("");
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof ChargebackDebitMemo>("approvedOn");
  const [chargebackDebitMemoState, setChargebackDebitMemoState] =
    React.useState<ChargebackDebitMemoState | "">("");
  const [wholesaler, setWholesaler] = React.useState<Wholesaler | undefined>(
    undefined,
  );
  const { data, isLoading } = ChargebackDebitMemoApi.useEdiList({
    businessUnit: currentBusinessUnit,
    order,
    orderBy,
    page,
    query,
    state:
      chargebackDebitMemoState === "" ? undefined : chargebackDebitMemoState,
    wholesaler,
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">EDI</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <Box sx={{ flex: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="report-label" focused shrink>
                State
              </InputLabel>
              <Select
                labelId={"report-label"}
                value={chargebackDebitMemoState}
                onChange={(event) =>
                  setChargebackDebitMemoState(
                    event.target.value as ChargebackDebitMemoState,
                  )
                }
                label={"Type"}
                displayEmpty={true}
              >
                <MenuItem value={""}>All</MenuItem>
                <MenuItem value={"approved"}>Ready to Submit</MenuItem>
                <MenuItem value={"sent_to_edi"}>Sent to TrueCommerce</MenuItem>
                <MenuItem value={"received_by_edi"}>
                  Received by TrueCommerce
                </MenuItem>
                <MenuItem value={"accepted_by_edi"}>
                  Accepted by Wholesaler
                </MenuItem>
                <MenuItem value={"rejected_by_edi"}>
                  Rejected by Wholesaler
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ flex: 1 }}>
            <WholesalerSearchBarAutocomplete
              setCurrentWholesaler={setWholesaler}
            />
          </Box>
        </Box>
        <Box>
          <TextField
            id={"EdiListPage-search"}
            label={"Search field"}
            type={"search"}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            fullWidth
          />
        </Box>
        <>
          {data && !isLoading ? (
            <ListTableWithCustomRow
              columns={columns}
              handleChangePage={(event: unknown, newPage) => {
                setPage(newPage);
              }}
              handleRequestSort={(
                event: React.MouseEvent<unknown>,
                property: keyof ChargebackDebitMemo,
              ) => {
                const isAsc = orderBy === property && order === "asc";
                setOrder(isAsc ? "desc" : "asc");
                setOrderBy(property);
              }}
              meta={data.meta}
              order={order}
              orderBy={orderBy}
              rows={data.data}
            >
              {(data.data || []).map((chargebackDebitMemo) => (
                <EdiDebitMemoRow
                  chargebackDebitMemo={chargebackDebitMemo}
                  key={chargebackDebitMemo.id}
                />
              ))}
            </ListTableWithCustomRow>
          ) : (
            <Loading />
          )}
        </>
      </Box>
    </Layout>
  );
}

function EdiDebitMemoRow({
  chargebackDebitMemo,
}: {
  chargebackDebitMemo: ChargebackDebitMemo;
}) {
  return (
    <>
      <TableRow>
        <TableCell key={"debitMemo"}>{chargebackDebitMemo.debitMemo}</TableCell>
        <TableCell key={"state"}>
          {ChargebackDebitMemoModel.stateName(chargebackDebitMemo.state)}
        </TableCell>
        <TableCell key={"totalNumLineitems"}>
          {chargebackDebitMemo.totalNumLineitems}
        </TableCell>
        <TableCell key={"totalSubmittedClaimAmount"}>
          {centsToDollars(chargebackDebitMemo.totalSubmittedClaimAmount)}
        </TableCell>
        <TableCell key={"totalApprovedAmount"}>
          {centsToDollars(chargebackDebitMemo.totalApprovedAmount)}
        </TableCell>
        <TableCell key={"createdAt"}>
          <ChargebackDebitMemoAction
            chargebackDebitMemoId={chargebackDebitMemo.id}
            state={chargebackDebitMemo.state}
          />
        </TableCell>
      </TableRow>
    </>
  );
}

function ChargebackDebitMemoAction({
  chargebackDebitMemoId,
  state,
}: {
  chargebackDebitMemoId: number;
  state: ChargebackDebitMemoState;
}) {
  const { mutate, isLoading } = ChargebackDebitMemoApi.useSendToEdi(
    chargebackDebitMemoId,
  );
  if (state !== "approved") {
    return null;
  }
  return (
    <Box>
      <Button variant="contained" onClick={() => mutate()} disabled={isLoading}>
        Send to EDI
      </Button>
    </Box>
  );
}
