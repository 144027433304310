import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, Typography } from "@mui/material";
import { TableCell, TextField } from "@mui/material";
import React from "react";

import ContractRefNumAPI from "../api/ContractRefNum.api";
import { ContractRefNum, Order, TableColumn } from "../types";
import { extractDate } from "../utils";
import ContractRefNumModal from "./ContractRefNumModal";
import ListTable from "./ListTable";
import Loading from "./Loading";

const columns: TableColumn<ContractRefNum>[] = [
  {
    field: "refNum",
    label: "Ref Num",
  },
  { field: "active", label: "Active" },
  { field: "effectiveDate", label: "Effective Date" },
  { field: "endDate", label: "End Date" },
];

function buildTableRow(contractRefNum: ContractRefNum): JSX.Element[] {
  return [
    <TableCell key={"refNum"}>{contractRefNum.refNum}</TableCell>,
    <TableCell key={"active"}>
      {contractRefNum.active ? "Active" : "Inactive"}
    </TableCell>,
    <TableCell key={"effectiveDate"}>
      {extractDate(contractRefNum.effectiveDate)}
    </TableCell>,
    <TableCell key={"endDate"}>
      {extractDate(contractRefNum.endDate)}
    </TableCell>,
  ];
}

export default function ContractRefNumCrud({
  contractId,
}: {
  contractId: number;
}) {
  const [query, setQuery] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof ContractRefNum>("refNum"); // TODO

  const { data, refetch } = ContractRefNumAPI.useList({
    contractId,
    order,
    orderBy,
    page,
    query,
  });

  const [contractRefNumId, setContractRefNumId] = React.useState<number>();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    refetch();
    setContractRefNumId(undefined);
    setOpen(false);
  };

  const handleContractRefNumClick = (contractRefNum: ContractRefNum) => {
    setContractRefNumId(contractRefNum.id);
    setOpen(true);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ContractRefNum,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick =
    (contractRefNum: ContractRefNum) => (event: unknown) => {
      // TODO open modal for editing, edit view will have link to model
      handleContractRefNumClick(contractRefNum);
    };

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginTop: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="h6">Reference Numbers</Typography>
        <IconButton
          aria-label="Add a ref # to the contract"
          size="large"
          onClick={handleOpen}
        >
          <AddCircleIcon color="primary" />
        </IconButton>
      </Box>
      <Box>
        <TextField
          id="ContractRefNum-search"
          label="Search field"
          type="search"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          fullWidth
        />
      </Box>
      {data ? (
        <ListTable
          columns={columns}
          buildTableRow={buildTableRow}
          handleChangePage={handleChangePage}
          handleRowClick={handleRowClick}
          handleRequestSort={handleRequestSort}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        />
      ) : (
        <Loading />
      )}
      <ContractRefNumModal
        contractRefNumId={contractRefNumId}
        open={open}
        handleClose={handleClose}
        contractId={contractId}
      />
    </Box>
  );
}
