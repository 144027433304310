import { TableCell, TextField } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import GroupAPI from "../api/Group.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { Group, Order, TableColumn } from "../types";
import { extractDate } from "../utils";
import ListTable from "./ListTable";
import Loading from "./Loading";

const columns: TableColumn<Group>[] = [
  { field: "name", label: "Name" },
  { field: "effectiveDate", label: "Effective Date" },
  { field: "endDate", label: "End Date" },
];

function buildTableRow(group: Group): JSX.Element[] {
  return [
    <TableCell key={"name"}>{group.name}</TableCell>,
    <TableCell key={"extractDate"}>
      {extractDate(group.effectiveDate)}
    </TableCell>,
    <TableCell key={"endDate"}>{extractDate(group.endDate)}</TableCell>,
  ];
}

export default function GroupListTable() {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Group>("createdAt"); // TODO
  const [query, setQuery] = React.useState<string>("");

  const navigate = useNavigate();

  const { data } = GroupAPI.useList({
    businessUnit: currentBusinessUnit,
    query,
    order,
    orderBy,
    page,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Group,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick = (group: Group) => (event: unknown) => {
    navigate(`/groups/${group.id}`);
  };

  return (
    <>
      <TextField
        id="GroupListTable-search"
        label="Search field"
        type="search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {data ? (
        <ListTable
          columns={columns}
          buildTableRow={buildTableRow}
          handleChangePage={handleChangePage}
          handleRowClick={handleRowClick}
          handleRequestSort={handleRequestSort}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}
