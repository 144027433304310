import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import ProductAPI from "../api/Product.api";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import ProductForm from "../components/ProductForm";
import ProductWacPriceManagement from "../components/ProductWacPriceManagement";

export default function ProductEdit() {
  const { id } = useParams();
  const { data, isSuccess, isLoading, isError } = ProductAPI.useDetail(id);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const [productWacId, setProductWacId] = React.useState<number>();

  const handleProductWacPriceClick = (showProductWacId: number) => {
    setProductWacId(showProductWacId);
    setOpen(true);
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">Product Edit</Typography>

        {isLoading || isError ? (
          <Loading />
        ) : (
          data &&
          isSuccess && (
            <ProductForm product={data}>
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h4">WAC Prices</Typography>
                  <IconButton
                    aria-label="Create a Distribution Center"
                    size="large"
                    onClick={handleOpen}
                  >
                    <AddCircleIcon color="primary" />
                  </IconButton>
                </Box>
                <ProductWacPriceManagement
                  productWacId={productWacId}
                  product={data}
                  open={open}
                  setOpen={setOpen}
                  handleProductWacPriceClick={handleProductWacPriceClick}
                />
              </>
            </ProductForm>
          )
        )}
      </Box>
    </Layout>
  );
}
