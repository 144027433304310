import { Button } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as Sentry from "@sentry/react";
import { SnackbarProvider, closeSnackbar } from "notistack";
import React from "react";
import ReactDOM from "react-dom/client";
import { ProSidebarProvider } from "react-pro-sidebar";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { BusinessUnitProvider } from "./contexts/BusinessUnit.context";
import { SessionProvider } from "./contexts/Session.context";
import "./index.scss";
// import { SnackbarProvider } from "./contexts/Snackbar.context";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";

if (process.env.NODE_ENV === "development") {
  // const { worker } = require("./mocks/browser");
  // worker.start();
  //   serviceWorker: {
  //     /**
  //      * Use a custom Service Worker script URL to resolve
  //      * the mock worker served by Codesandbox.
  //      * @note You DO NOT need this in your application.
  //      * @see https://mswjs.io/docs/api/setup-worker/start#serviceworker
  //      */
  //     // url: "/mockServiceWorker.js",
  //   },
  // });
}
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://6337346c5fbf4b2592444564fadf9ed6@o4505074758647808.ingest.sentry.io/4505074760089600",
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const queryClient = new QueryClient({});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <SessionProvider>
              <BusinessUnitProvider>
                <SnackbarProvider
                  maxSnack={3}
                  autoHideDuration={3000}
                  action={(snackbarId) => (
                    <Button
                      onClick={() => closeSnackbar(snackbarId)}
                      variant="outlined"
                      color="secondary"
                    >
                      Dismiss
                    </Button>
                  )}
                >
                  <ProSidebarProvider>
                    <App />
                  </ProSidebarProvider>
                </SnackbarProvider>
              </BusinessUnitProvider>
            </SessionProvider>
          </BrowserRouter>
          <CssBaseline />
        </ThemeProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
