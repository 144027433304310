import { css } from "@emotion/css";
import { AppBar, Box, Container } from "@mui/material";
import React from "react";

import ProfileNavigationMenu from "./ProfileNavigationMenu";

type Props = {
  children: JSX.Element;
};

export default function EmptyLayout({ children }: Props) {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar position="static">
        <Container maxWidth="xl">
          <div
            className={css`
              display: flex;
              flex-grow: 1;
              flex-direction: column;
              justify-content: center;
              align-items: end;
            `}
          >
            <ProfileNavigationMenu />
          </div>
        </Container>
      </AppBar>
      <Container
        component="main"
        // maxWidth="xs"
        sx={{ display: "flex", flexGrow: 1 }}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
          }}
        >
          {children}
        </Box>
      </Container>
    </Box>
  );
}
