import { Box, Typography } from "@mui/material";
import React from "react";

import ProductWholesalerPriceAPI from "../api/ProductWholesalerPrice.api";
import ProductWholesalerPriceModel from "../models/ProductWholesalerPrice.model";
import { Product } from "../types";
import Loading from "./Loading";
import ProductSearchBarAutocomplete from "./ProductSearchBarAutocomplete";
import ProductWholesalerPriceForm from "./ProductWholesalerPriceForm";

type Props = {
  id?: number;
  wholesalerId: number;
  handleClose: () => void;
};

export default function ProductWholesalerPriceModal({
  id,
  wholesalerId,
  handleClose,
}: Props) {
  if (id) {
    return (
      <ProductWholesalerPriceModalEdit
        id={id}
        wholesalerId={wholesalerId}
        handleClose={handleClose}
      />
    );
  }
  return (
    <ProductWholesalerPriceModalNew
      wholesalerId={wholesalerId}
      handleClose={handleClose}
    />
  );
}

type PropsProductWholesalerPriceModalEdit = {
  id: number;
  wholesalerId: number;
  handleClose: () => void;
};

function ProductWholesalerPriceModalEdit({
  id,
  wholesalerId,
  handleClose,
}: PropsProductWholesalerPriceModalEdit) {
  const { data } = ProductWholesalerPriceAPI.useDetail(id.toString());

  if (!data) {
    return <Loading />;
  }

  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
        {data.productNdc}
      </Typography>
      <ProductWholesalerPriceForm
        wholesalerId={wholesalerId}
        productId={data.productId}
        productWholesalerPrice={data}
        handleClose={handleClose}
      />
    </>
  );
}

type PropsProductWholesalerPriceModalNew = {
  wholesalerId: number;
  handleClose: () => void;
};

function ProductWholesalerPriceModalNew({
  wholesalerId,
  handleClose,
}: PropsProductWholesalerPriceModalNew) {
  const [currentProduct, setCurrentProduct] = React.useState<Product>();
  return (
    <>
      <Box sx={{ marginBottom: "1rem" }}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2 }}
        >
          Add Product to Contract
        </Typography>
        <ProductSearchBarAutocomplete
          product={currentProduct}
          setCurrentProduct={(product) => {
            setCurrentProduct(product);
          }}
        />
      </Box>
      {currentProduct && (
        <ProductWholesalerPriceForm
          wholesalerId={wholesalerId}
          productId={currentProduct.id}
          productWholesalerPrice={ProductWholesalerPriceModel.createEmpty({
            wholesalerId,
            productId: currentProduct.id,
          })}
          handleClose={handleClose}
        />
      )}
    </>
  );
}
