import ArchiveIcon from "@mui/icons-material/Archive";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { debounce } from "lodash";
import React from "react";

type Props = {
  onDelete: () => void;
  buttonText?: string;
  popupText?: string;
};

export default function ArchiveButton({
  onDelete,
  buttonText,
  popupText,
}: Props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    onDelete();
    setOpen(false);
  };

  const throttledHandleDelete = React.useCallback(
    debounce(handleDelete, 1000),
    [],
  );

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        color="secondary"
        startIcon={buttonText ? null : <ArchiveIcon />}
      >
        {buttonText ? buttonText : "Archive"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {popupText
              ? popupText
              : "This will delete all related data linked to this."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={throttledHandleDelete} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
