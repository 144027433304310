import { Box, Typography } from "@mui/material";

import Layout from "../components/Layout";
import UserForm from "../components/UserForm";

export default function UserNew() {
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">User New</Typography>
        <UserForm />
      </Box>
    </Layout>
  );
}
