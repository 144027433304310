import camelcaseKeys from "camelcase-keys";
import {snakeCase} from "lodash";
import {useSnackbar} from "notistack";
import {useMutation, useQuery} from "react-query";
import {useNavigate} from "react-router-dom";
import snakeCaseKeys from "snakecase-keys";

import {
  BusinessUnit,
  ChargebackDebitMemo,
  ChargebackDebitMemoExportProps,
  ChargebackDebitMemoExportSearchProps,
  ChargebackDebitMemoQueryProps,
  ChargebackDebitMemoState,
  ChargebackExportGroup,
  ErrorResult,
  ListResponseData,
  MetaPagination,
  Order,
  Param,
  SuccessResult,
  Wholesaler,
} from "../types";
import client from "./client";
import {
  handleBasicPost,
  handleDelete,
  handleDetail,
  handleList,
  handleMutationError,
  handleQueryError,
} from "./handler";
import queryClient from "./queryClient";

async function fetchChargebackDebitMemos({
                                           businessUnit,
                                           state,
                                           order,
                                           orderBy,
                                           page,
                                           query,
                                         }: ChargebackDebitMemoQueryProps): Promise<ListResponseData<ChargebackDebitMemo>> {
  try {
    const searchParams = new URLSearchParams();
    if (page) {
      searchParams.set("page", page.toString());
    }
    if (order) {
      searchParams.set("order", order);
    }
    if (orderBy) {
      searchParams.set("order_by", snakeCase(orderBy));
    }
    if (query) {
      searchParams.set("query", query);
    }
    if (businessUnit) {
      searchParams.set("business_unit_id", businessUnit.id.toString());
    }
    if (state) {
      searchParams.set("state", state);
    }
    const response: { data: ChargebackDebitMemo[]; meta: MetaPagination } =
      await client
        .get("chargeback_debit_memos", {
          searchParams,
        })
        .json();

    return camelcaseKeys(
      {data: response.data || [], meta: response.meta},
      {deep: true},
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postChargebackDebitMemosExport({
                                                businessUnit,
                                                chargebackDebitMemoIds,
                                              }: ChargebackDebitMemoExportProps): Promise<ChargebackExportGroup> {
  try {
    const inputSnaked = snakeCaseKeys(
      {
        businessUnitId: businessUnit ? businessUnit.id : null,
        chargebackDebitMemoIds,
      },
      {deep: true},
    );
    const data: ChargebackExportGroup = await client
      .post("chargeback_debit_memos/export", {json: inputSnaked})
      .json();

    return camelcaseKeys(data, {deep: true});
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postChargebackDebitMemosExportSearch({
                                                      businessUnit,
                                                      wholesalerId,
                                                      effectiveDate,
                                                      endDate,
                                                    }: ChargebackDebitMemoExportSearchProps): Promise<ChargebackExportGroup> {
  try {
    const inputSnaked = snakeCaseKeys(
      {
        businessUnitId: businessUnit ? businessUnit.id : null,
        wholesalerId,
        effectiveDate,
        endDate,
      },
      {deep: true},
    );
    const data: ChargebackExportGroup = await client
      .post("chargeback_debit_memos/export_search", {json: inputSnaked})
      .json();

    return camelcaseKeys(data, {deep: true});
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postReprocessChargebackDebitMemo(
  id: number | undefined,
): Promise<ChargebackDebitMemo> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data: ChargebackDebitMemo = await client
      .post(`chargeback_debit_memos/${id}/reprocess`)
      .json();
    return camelcaseKeys(data, {deep: true});
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postReinvestigateChargebackDebitMemo(
  id: number | undefined,
): Promise<ChargebackDebitMemo> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data: ChargebackDebitMemo = await client
      .post(`chargeback_debit_memos/${id}/reinvestigate`)
      .json();
    return camelcaseKeys(data, {deep: true});
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postApproveChargebackDebitMemo(
  id: number | undefined,
): Promise<ChargebackDebitMemo> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data: ChargebackDebitMemo = await client
      .post(`chargeback_debit_memos/${id}/approve`)
      .json();
    return camelcaseKeys(data, {deep: true});
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postReinvestigateAllChargebackDebitMemos(
  businessUnit?: BusinessUnit,
): Promise<SuccessResult | ErrorResult> {
  try {
    if (!(businessUnit && businessUnit.id)) {
      return Promise.reject(new Error("Invalid Business Unit"));
    }
    const data: SuccessResult = await client
      .post(
        `chargeback_debit_memos/reinvestigate_all?business_unit_id=${businessUnit.id}`,
      )
      .json();
    return camelcaseKeys(data, {deep: true});
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postApproveAllChargebackDebitMemos(
  businessUnit?: BusinessUnit,
): Promise<SuccessResult | ErrorResult> {
  try {
    if (!(businessUnit && businessUnit.id)) {
      return Promise.reject(new Error("Invalid Business Unit"));
    }
    const data: SuccessResult = await client
      .post(
        `chargeback_debit_memos/approve_all?business_unit_id=${businessUnit.id}`,
      )
      .json();
    return camelcaseKeys(data, {deep: true});
  } catch (error) {
    return Promise.reject(error);
  }
}

type DetailProps = {
  placeholderData?: ChargebackDebitMemo;
  enabled?: boolean;
};

// const queryClient = new QueryClient();

const methods = {
  useCustomEndpointList: ({
                            endpoint,
                            order,
                            orderBy,
                            page,
                            query,
                          }: {
    endpoint: string;
    order: Order;
    orderBy: keyof ChargebackDebitMemo;
    page?: number;
    query?: string;
  }) => {
    const navigate = useNavigate();
    const params: Param[] = [];
    if (query) {
      params.push({
        key: "query",
        value: query,
      });
    }
    return useQuery<ListResponseData<ChargebackDebitMemo>>({
      cacheTime: 0,
      queryKey: [endpoint, order, orderBy, page, query],
      queryFn: () =>
        handleList({
          baseUrl: endpoint,
          order,
          orderBy,
          page,
          params,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useList: ({
              businessUnit,
              state,
              order,
              orderBy,
              page,
              query,
            }: ChargebackDebitMemoQueryProps) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<ChargebackDebitMemo>>({
      cacheTime: 0,
      queryKey: [
        "chargeback_debit_memos",
        businessUnit?.id,
        state,
        order,
        orderBy,
        page,
        query,
      ],
      queryFn: () =>
        fetchChargebackDebitMemos({
          businessUnit,
          state,
          order,
          orderBy,
          page,
          query,
        }),
      onError: handleQueryError(navigate),
      refetchInterval: 10000,
      keepPreviousData: true,
    });
  },
  useEdiList: ({
                 businessUnit,
                 state,
                 order,
                 orderBy,
                 page,
                 query,
                 wholesaler,
               }: {
    businessUnit?: BusinessUnit;
    state?: ChargebackDebitMemoState;
    order?: Order;
    orderBy?: keyof ChargebackDebitMemo;
    query?: string;
    page?: number;
    wholesaler?: Wholesaler;
  }) => {
    const navigate = useNavigate();
    const params: Param[] = [];
    if (businessUnit) {
      params.push({
        key: "business_unit_id",
        value: businessUnit.id,
      });
    }
    if (state) {
      params.push({
        key: "state",
        value: state,
      });
    }
    if (wholesaler) {
      params.push({
        key: "wholesaler_id",
        value: wholesaler.id,
      });
    }
    return useQuery<ListResponseData<ChargebackDebitMemo>>({
      cacheTime: 0,
      queryKey: [
        "chargeback_debit_memos_edi",
        businessUnit?.id,
        state,
        order,
        orderBy,
        page,
        query,
        wholesaler?.id,
      ],
      queryFn: () =>
        handleList({
          baseUrl: "chargeback_debit_memos/edi",
          order,
          orderBy,
          page,
          query,
          params,
        }),
      onError: handleQueryError(navigate),
      refetchInterval: 10000,
      keepPreviousData: true,
    });
  },
  useManualSubmissionsList: ({
                               businessUnit,
                               state,
                               order,
                               orderBy,
                               page,
                               query,
                               wholesaler,
                             }: {
    businessUnit?: BusinessUnit;
    state?: ChargebackDebitMemoState;
    order?: Order;
    orderBy?: keyof ChargebackDebitMemo;
    query?: string;
    page?: number;
    wholesaler?: Wholesaler;
  }) => {
    const navigate = useNavigate();
    const params: Param[] = [];
    if (businessUnit) {
      params.push({
        key: "business_unit_id",
        value: businessUnit.id,
      });
    }
    if (state) {
      params.push({
        key: "state",
        value: state,
      });
    }
    if (wholesaler) {
      params.push({
        key: "wholesaler_id",
        value: wholesaler.id,
      });
    }
    return useQuery<ListResponseData<ChargebackDebitMemo>>({
      cacheTime: 0,
      queryKey: [
        "chargeback_debit_memos_manual_submissions",
        businessUnit?.id,
        state,
        order,
        orderBy,
        page,
        query,
        wholesaler?.id,
      ],
      queryFn: () =>
        handleList({
          baseUrl: "chargeback_debit_memos/manual_submissions",
          order,
          orderBy,
          page,
          query,
          params,
        }),
      onError: handleQueryError(navigate),
      refetchInterval: 10000,
      keepPreviousData: true,
    });
  },
  useDetail: (id: string | undefined, options: DetailProps) => {
    const navigate = useNavigate();
    const enabled = options["enabled"] ? options["enabled"] : false;
    return useQuery<ChargebackDebitMemo>({
      cacheTime: 0,
      enabled,
      initialData: options["placeholderData"],
      queryKey: ["chargeback_debit_memos", id],
      queryFn: () => handleDetail({baseUrl: "chargeback_debit_memos", id}),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number } | ErrorResult>({
      mutationFn: () => handleDelete({baseUrl: "chargeback_debit_memos", id}),
      onError: handleQueryError(navigate),
    });
  },
  useExport: (exportInput: ChargebackDebitMemoExportProps) => {
    const navigate = useNavigate();
    return useMutation<ChargebackExportGroup | ErrorResult>({
      mutationFn: () => postChargebackDebitMemosExport(exportInput),
      onError: handleQueryError(navigate),
    });
  },
  useExportInvestigating: (businessUnitId?: number) => {
    const navigate = useNavigate();
    return useMutation<ChargebackExportGroup>({
      mutationFn: () =>
        handleBasicPost({
          baseUrl: "chargeback_debit_memos/export_investigating",
          input: {businessUnitId},
        }),
      onError: handleQueryError(navigate),
    });
  },
  useExportSearch: (
    exportSearchInput: ChargebackDebitMemoExportSearchProps,
  ) => {
    const navigate = useNavigate();
    return useMutation<ChargebackExportGroup | ErrorResult>({
      mutationFn: () => postChargebackDebitMemosExportSearch(exportSearchInput),
      onError: handleQueryError(navigate),
    });
  },
  useReinvestigate: (id: number | undefined) => {
    const navigate = useNavigate();
    return useMutation<ChargebackDebitMemo | ErrorResult>({
      mutationFn: () => postReinvestigateChargebackDebitMemo(id),
      onError: handleQueryError(navigate),
    });
  },
  useReinvestigateAll: (businessUnit?: BusinessUnit) => {
    const navigate = useNavigate();
    return useMutation<SuccessResult | ErrorResult>({
      mutationFn: () => postReinvestigateAllChargebackDebitMemos(businessUnit),
      onError: handleQueryError(navigate),
    });
  },
  useApprove: (id: number | undefined) => {
    const navigate = useNavigate();
    return useMutation<ChargebackDebitMemo | ErrorResult>({
      mutationFn: () => postApproveChargebackDebitMemo(id),
      onError: handleQueryError(navigate),
    });
  },
  useUnapprove: (id: number) => {
    const {enqueueSnackbar} = useSnackbar();
    return useMutation<ChargebackDebitMemo>({
      mutationFn: () =>
        handleBasicPost({
          baseUrl: `chargeback_debit_memos/${id}/unapprove`,
          input: {},
        }),
      onError: handleMutationError(enqueueSnackbar),
    });
  },
  useApproveAll: (businessUnit?: BusinessUnit) => {
    const navigate = useNavigate();
    return useMutation<SuccessResult | ErrorResult>({
      mutationFn: () => postApproveAllChargebackDebitMemos(businessUnit),
      onError: handleQueryError(navigate),
    });
  },
  useReprocess: (id: number | undefined) => {
    const navigate = useNavigate();
    return useMutation<ChargebackDebitMemo | ErrorResult>({
      mutationFn: () => postReprocessChargebackDebitMemo(id),
      onError: handleQueryError(navigate),
    });
  },
  useSendToEdi: (id: number) => {
    const {enqueueSnackbar} = useSnackbar();
    return useMutation<ChargebackDebitMemo>({
      mutationFn: () =>
        handleBasicPost({
          baseUrl: `chargeback_debit_memos/${id}/send_to_edi`,
          input: {},
        }),
      onError: handleMutationError(enqueueSnackbar),
      onSuccess: (chargebackDebitMemo: ChargebackDebitMemo, variables: any) => {
        enqueueSnackbar("Sent to EDI", {variant: "success"});
        queryClient.invalidateQueries(["chargeback_debit_memos_edi"], {
          refetchActive: true,
        });
      },
    });
  },
};

export default methods;
