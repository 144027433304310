import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useSessionContext } from "../contexts/Session.context";

export default function ProfileNavigationMenu() {
  const { setSession } = useSessionContext();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setSession && setSession(undefined);
    navigate("/login");
  };

  const handleEditBusinessUnit = () => {
    navigate("/edit-business-unit");
  };

  const handleEditProfile = () => {
    navigate("/profile");
  };

  const handleShowUsers = () => {
    navigate("/users");
  };

  const handleShowAutoImportedFiles = () => {
    navigate("/auto-imported-files");
  };

  return (
    <>
      <IconButton
        size="large"
        aria-controls={open ? "basic-menu" : undefined}
        aria-label="account of current user"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        color="inherit"
        onClick={handleClick}
      >
        <AccountCircleIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => navigate("/")}>Dashboard</MenuItem>
        <MenuItem onClick={handleEditBusinessUnit}>Edit Business Unit</MenuItem>
        <MenuItem onClick={handleShowAutoImportedFiles}>
          AutoImported Files
        </MenuItem>
        <MenuItem onClick={handleShowUsers}>Manage Users</MenuItem>
        <MenuItem onClick={handleEditProfile}>Profile</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}
