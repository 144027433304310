import camelcaseKeys from "camelcase-keys";
import { snakeCase } from "lodash";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import snakeCaseKeys from "snakecase-keys";

import { ListResponseData, MetaPagination, QueryProps, User } from "../types";
import client from "./client";
import { handleQueryError } from "./handler";

async function postEmailUpdate({
  userId,
  email,
}: {
  userId: number;
  email: string;
}) {
  try {
    const inputSnaked = snakeCaseKeys({ email }, { deep: true });
    const data: { message: string } = await client
      .post(`users/${userId}/update_email`, { json: inputSnaked })
      .json();

    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postPasswordUpdate({
  password,
  passwordConfirmation,
}: {
  password?: string;
  passwordConfirmation?: string;
}) {
  try {
    const inputSnaked = snakeCaseKeys(
      { password, passwordConfirmation },
      { deep: true },
    );
    const data: { message: string } = await client
      .put(`password/update`, { json: inputSnaked })
      .json();

    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postEmailVerification(token?: string) {
  try {
    const data: { message: string } = await client
      .post(`users/email_verification`, { json: { token } })
      .json();
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postForgotPassword(email?: string) {
  try {
    const data: { message: string } = await client
      .post(`password/forgot`, { json: { email } })
      .json();
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postResetPassword({
  password,
  passwordConfirmation,
  token,
}: {
  password?: string;
  passwordConfirmation?: string;
  token?: string;
}) {
  try {
    const data: { message: string } = await client
      .post(`password/reset`, {
        json: { password, password_confirmation: passwordConfirmation, token },
      })
      .json();
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    console.log(error);
    return Promise.reject(error.data);
  }
}

async function handleSave({
  id,
  email,
  role,
  password,
}: {
  id?: number;
  email: string;
  role: string;
  password?: string;
}) {
  try {
    let data: User;
    if (typeof id === "undefined") {
      data = await client
        .post("users", { json: { email, role, password } })
        .json();
    } else {
      data = await client.put(`users/${id}`, { json: { email, role } }).json();
    }
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

async function fetchUsers({
  order,
  orderBy,
  page,
  query,
}: QueryProps): Promise<ListResponseData<User>> {
  try {
    const searchParams = new URLSearchParams();
    if (page) {
      searchParams.set("page", page.toString());
    }
    if (query) {
      searchParams.set("query", query);
    }
    if (order) {
      searchParams.set("order", order);
    }
    if (orderBy) {
      searchParams.set("order_by", snakeCase(orderBy));
    }
    const response: { data: User[]; meta: MetaPagination } = await client
      .get("users", {
        searchParams,
      })
      .json();

    return camelcaseKeys(
      { data: response.data || [], meta: response.meta },
      { deep: true },
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

async function fetchUser(id?: string): Promise<User> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }

    const data: User = await client.get(`users/${id}`).json();
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

const methods = {
  useList: ({ order, orderBy, page, query }: QueryProps) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<User>>({
      cacheTime: 0,
      queryKey: ["users"],
      queryFn: () => fetchUsers({ order, orderBy, page, query }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id?: string) => {
    const navigate = useNavigate();
    return useQuery<User>({
      cacheTime: 0,
      queryKey: ["users", id],
      queryFn: () => fetchUser(id),
      onError: handleQueryError(navigate),
    });
  },
  useSave: ({
    id,
    email,
    role,
    password,
  }: {
    id?: number;
    email: string;
    role: string;
    password?: string;
  }) => {
    return useMutation<User>({
      mutationFn: () => handleSave({ id, email, role, password }),
      retry: 1,
    });
  },
  useUpdateEmail: ({ userId, email }: { userId: number; email: string }) => {
    return useMutation<{ message: string }>({
      mutationFn: () => postEmailUpdate({ userId, email }),
      retry: 1,
    });
  },
  useEmailVerification: (token?: string) => {
    return useMutation<{ message: string }>({
      mutationFn: () => postEmailVerification(token),
      retry: 2,
    });
  },
  useForgotPassword: (email?: string) => {
    return useMutation<{ message: string }>({
      mutationFn: () => postForgotPassword(email),
      retry: 2,
    });
  },
  useUpdatePassword: ({
    password,
    passwordConfirmation,
  }: {
    password?: string;
    passwordConfirmation?: string;
  }) => {
    return useMutation<{ message: string }>({
      mutationFn: () => postPasswordUpdate({ password, passwordConfirmation }),
      retry: 1,
    });
  },
  useResetPassword: ({
    password,
    passwordConfirmation,
    token,
  }: {
    password?: string;
    passwordConfirmation?: string;
    token?: string;
  }) => {
    return useMutation<{ message: string }>({
      mutationFn: () =>
        postResetPassword({ password, passwordConfirmation, token }),
      retry: 2,
    });
  },
};

export default methods;
