import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TableCell,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";

import BusinessUnitCodeAPI from "../api/BusinessUnitCode.api";
import ArchiveButton from "../components/ArchiveButton";
import { BusinessUnit, BusinessUnitCode, Order, TableColumn } from "../types";
import ListTable from "./ListTable";
import Loading from "./Loading";

const columns: TableColumn<BusinessUnitCode>[] = [
  { field: "code", label: "Code" },
  { field: "codeType", label: "Type" },
  { field: "id", label: "", orderBy: false },
];

function buildTableRow(businessUnitCode: BusinessUnitCode): JSX.Element[] {
  return [
    <TableCell key={"code"}>{businessUnitCode.code}</TableCell>,
    <TableCell key={"codeType"}>{businessUnitCode.codeType}</TableCell>,
    <TableCell key={"id"}>
      <DeleteBusinessUnitCode id={businessUnitCode.id} />
    </TableCell>,
  ];
}

type Props = {
  businessUnit: BusinessUnit;
};
export default function BusinessUnitCodes({ businessUnit }: Props) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof BusinessUnitCode>("createdAt");

  const { data, refetch } = BusinessUnitCodeAPI.useList({
    businessUnit,
    page,
    orderBy,
    order,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof BusinessUnitCode,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick =
    (businessUnitCode: BusinessUnitCode) => (event: unknown) => {};

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Typography variant="h6">Codes</Typography>
          <IconButton
            aria-label="Create a Contract"
            size="large"
            onClick={() => setOpen(true)}
          >
            <AddCircleIcon color="primary" />
          </IconButton>
        </Box>
        {data ? (
          <ListTable
            columns={columns}
            buildTableRow={buildTableRow}
            handleChangePage={handleChangePage}
            handleRowClick={handleRowClick}
            handleRequestSort={handleRequestSort}
            meta={data.meta}
            order={order}
            orderBy={orderBy}
            rows={data.data}
          />
        ) : (
          <Loading />
        )}
      </Box>
      <ModalCreate
        open={open}
        handleClose={() => setOpen(false)}
        businessUnit={businessUnit}
        refetch={refetch}
      />
    </React.Fragment>
  );
}

type ModalCreateProps = {
  open: boolean;
  handleClose: () => void;
  businessUnit: BusinessUnit;
  refetch: () => void;
};
function ModalCreate({
  open,
  handleClose,
  businessUnit,
  refetch,
}: ModalCreateProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [code, setCode] = React.useState<string>("");

  const { mutateAsync } = BusinessUnitCodeAPI.useSave({
    businessUnitId: businessUnit.id,
    codeType: "dea",
    code,
  });

  const onSave = async () => {
    await mutateAsync();
    enqueueSnackbar("Successfully created", { variant: "success" });
    refetch();
    handleClose();
    setCode("");
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          label="code"
          variant="outlined"
          value={code}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCode(event.target.value);
          }}
        />
        <Button variant="contained" color="primary" onClick={onSave}>
          Save
        </Button>
      </Box>
    </Modal>
  );
}

type DeleteBusinessUnitCodeProps = {
  id: number;
};

function DeleteBusinessUnitCode({ id }: DeleteBusinessUnitCodeProps) {
  const { mutateAsync } = BusinessUnitCodeAPI.useDelete(id);
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = async () => {
    try {
      await mutateAsync();
    } catch (error) {
      enqueueSnackbar("Unable to delete business unit code", {
        variant: "error",
      });
    }
  };

  return (
    <ArchiveButton
      onDelete={onDelete}
      buttonText={"Delete"}
      popupText={
        "This will remove the business unit code from the business unit"
      }
    />
  );
}
