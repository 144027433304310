import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import { isNil } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import ReportAPI from "../api/Report.api";
import Layout from "../components/Layout";
import ReportListTable from "../components/ReportListTable";
import WholesalerSearchBarAutocomplete from "../components/WholesalerSearchBarAutocomplete";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { ReportType, Wholesaler } from "../types";

interface ExtraParams {
  effectiveDate?: Date | string;
  endDate?: Date | string;
}
interface ICustomReportContext {
  setExtraParams?: (arg?: any) => void;
  extraParams?: ExtraParams;
}
const CustomReportContext = React.createContext<Partial<ICustomReportContext>>(
  {},
);

export default function CustomReportsPage() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [extraParams, setExtraParams] = React.useState<ExtraParams>({});
  const [reportType, setReportType] = React.useState<ReportType | undefined>(
    "contract_dump",
  );

  const { mutateAsync, isLoading } = ReportAPI.useCreate({
    reportType,
    args: {
      ...extraParams,
      businessUnitId: currentBusinessUnit?.id,
    },
  });

  const handleRunReport = async () => {
    try {
      if (
        reportType === "custom_chargeback_debit_memo_search_by_date" ||
        reportType === "summarized_chargeback_debit_memo_search_by_date"
      ) {
        if (!(extraParams["effectiveDate"] && extraParams["endDate"])) {
          enqueueSnackbar("Requires both Effective & End date", {
            variant: "error",
          });
          return;
        }
      }
      const report = await mutateAsync();
      navigate(`/reports/${report.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CustomReportContext.Provider value={{ extraParams, setExtraParams }}>
      <Layout>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            gap: "1rem",
          }}
        >
          <Box>
            <Typography variant="h2">Custom Reports</Typography>
          </Box>
          <Box sx={{ maxWidth: "20rem" }}>
            <FormControl fullWidth>
              <InputLabel id="report-type-label">Report Type</InputLabel>
              <Select
                labelId="report-type-lablel"
                id="report-type-select"
                value={reportType}
                label="Type"
                onChange={(event: SelectChangeEvent) =>
                  setReportType(event.target.value as ReportType)
                }
              >
                <MenuItem value={"contract_dump"}>Contract Dump</MenuItem>
                <MenuItem value={"custom_chargeback_debit_memo_search_by_date"}>
                  Custom Chargeback Debit Memo search by date
                </MenuItem>
                <MenuItem value={"member_dump"}>Member Dump</MenuItem>
                <MenuItem value={"product_dump"}>Product Dump</MenuItem>
                <MenuItem
                  value={"summarized_chargeback_debit_memo_search_by_date"}
                >
                  Summarized Debit Memo Search
                </MenuItem>
                <MenuItem value={"wholesaler_dump"}>Wholesaler Dump</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", maxWidth: "40rem" }}
          >
            <ExtraFormFields reportType={reportType} />
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRunReport}
              disabled={isNil(reportType) || isLoading}
            >
              Run Report
            </Button>
          </Box>
          <Box>
            <ReportListTable />
          </Box>
        </Box>
      </Layout>
    </CustomReportContext.Provider>
  );
}

function ExtraFormFields({ reportType }: { reportType?: ReportType }) {
  if (reportType === "custom_chargeback_debit_memo_search_by_date") {
    return <CustomChargebackDebitMemoSearchByDateExtraFormFields />;
  } else if (reportType === "summarized_chargeback_debit_memo_search_by_date") {
    return <SummarizedChargebackDebitMemoSearchByDateExtraFormFields />;
  }
  return null;
}

function CustomChargebackDebitMemoSearchByDateExtraFormFields() {
  const { setExtraParams } = React.useContext(CustomReportContext);

  const [effectiveDate, setEffectiveDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [currentWholesaler, setCurrentWholesaler] =
    React.useState<Wholesaler>();

  React.useEffect(() => {
    if (setExtraParams) {
      setExtraParams({
        effectiveDate: effectiveDate ? effectiveDate.toDate() : null,
        endDate: endDate ? endDate.toDate() : null,
        wholesalerId: currentWholesaler ? currentWholesaler?.id : null,
      });
    }
  }, [setExtraParams, effectiveDate, endDate, currentWholesaler]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
      <WholesalerSearchBarAutocomplete
        setCurrentWholesaler={(wholesaler) => {
          setCurrentWholesaler(wholesaler);
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      >
        <DatePicker
          label="Effective Date"
          value={effectiveDate}
          onChange={(value: any) => {
            setEffectiveDate(value);
          }}
          sx={{ width: "100%" }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(value: any) => {
            setEndDate(value);
          }}
          sx={{ width: "100%" }}
        />
      </Box>
    </Box>
  );
}

function SummarizedChargebackDebitMemoSearchByDateExtraFormFields() {
  const { setExtraParams } = React.useContext(CustomReportContext);

  const [effectiveDate, setEffectiveDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);

  React.useEffect(() => {
    if (setExtraParams) {
      setExtraParams({
        effectiveDate: effectiveDate ? effectiveDate.toDate() : null,
        endDate: endDate ? endDate.toDate() : null,
      });
    }
  }, [setExtraParams, effectiveDate, endDate]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      >
        <DatePicker
          label="Effective Date"
          value={effectiveDate}
          onChange={(value: any) => {
            setEffectiveDate(value);
          }}
          sx={{ width: "100%" }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(value: any) => {
            setEndDate(value);
          }}
          sx={{ width: "100%" }}
        />
      </Box>
    </Box>
  );
}
