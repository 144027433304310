import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import WholesalerDistributionCenterAPI from "../api/WholesalerDistributionCenter.api";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import WholesalerDistributionCenterForm from "../components/WholesalerDistributionCenterForm";

export default function WholesalerDistributionCenterNew() {
  const { id, wholesalerId } = useParams();

  const { data, isLoading } = WholesalerDistributionCenterAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">
          Edit Wholesaler Distribution Center
        </Typography>
        {isLoading ? (
          <Loading />
        ) : (
          data &&
          data.address &&
          wholesalerId && (
            <WholesalerDistributionCenterForm
              wholesalerId={wholesalerId}
              wholesalerDistributionCenter={data}
              addressInput={data.address}
            />
          )
        )}
      </Box>
    </Layout>
  );
}
