import { FormControl, InputAdornment, TextField } from "@mui/material";
import React from "react";

type Props = {
  label: string;
  value: string;
  setValue: (arg0: string) => void;
  variant: "standard" | "filled" | "outlined" | undefined;
  className?: any;
  fullWidth?: boolean;
};

export default function CurrencyTextField({
  label,
  value,
  setValue,
  variant,
  className,
  fullWidth,
}: Props) {
  return (
    <FormControl>
      <TextField
        fullWidth={fullWidth || false}
        variant={variant}
        label={label}
        InputProps={{
          inputMode: "numeric",
          // pattern: "[0-9]*",
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        value={value}
        onChange={(event) => {
          const input = event.target.value;
          setValue(input);
        }}
        type="number"
        className={className ? className : null}
      />
    </FormControl>
  );
}
