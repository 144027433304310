import { Box } from "@mui/material";

import logoImg from "../assets/logo.png";

export default function LogoBox() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    >
      <img
        src={logoImg}
        alt="Artemis CB"
        style={{ width: "100%", maxWidth: "25rem" }}
      />
    </Box>
  );
}
