import { TableCell } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import AutoImportedFileAPI from "../api/AutoImportedFile.api";
import AutoImportedFileModel from "../models/AutoImportedFile.model";
import { AutoImportedFile, Order, TableColumn } from "../types";
import { extractDate } from "../utils";
import ListTable from "./ListTable";
import Loading from "./Loading";

const columns: TableColumn<AutoImportedFile>[] = [
  { field: "filename", label: "File", orderBy: false },
  { field: "state", label: "State" },
  { field: "updatedAt", label: "Updated At" },
];

function buildTableRow(autoImportedFile: AutoImportedFile): JSX.Element[] {
  return [
    <TableCell key={"filename"}>{`${autoImportedFile.filename}`}</TableCell>,
    <TableCell key={"state"}>{`${AutoImportedFileModel.stateName(
      autoImportedFile.state,
    )}`}</TableCell>,
    <TableCell key={"updatedAt"}>
      {extractDate(autoImportedFile.updatedAt)}
    </TableCell>,
  ];
}

export default function ChargebackFileListTable() {
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof AutoImportedFile>("updatedAt");

  const navigate = useNavigate();

  const { data } = AutoImportedFileAPI.useList({
    order,
    orderBy,
    page,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof AutoImportedFile,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick =
    (autoImportedFile: AutoImportedFile) => (event: unknown) => {
      navigate(`/auto-imported-files/${autoImportedFile.id}`);
    };

  if (data) {
    return (
      <ListTable
        columns={columns}
        buildTableRow={buildTableRow}
        handleChangePage={handleChangePage}
        handleRowClick={handleRowClick}
        handleRequestSort={handleRequestSort}
        meta={data.meta}
        order={order}
        orderBy={orderBy}
        rows={data.data}
      />
    );
  }
  return <Loading />;
}
