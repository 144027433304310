import { css } from "@emotion/css";
import { TextField } from "@mui/material";

import { ChargebackLineitemWithAssociations } from "../types";
import { centsToDollars } from "../utils";
import CurrencyTextField from "./CurrencyTextField";

type Props = {
  onSetLineitemAttribute: (
    key: keyof ChargebackLineitemWithAssociations,
    value: string,
  ) => void;
  chargebackLineitem: ChargebackLineitemWithAssociations;
};

export default function FeedbackLineitemFields({
  onSetLineitemAttribute,
  chargebackLineitem,
}: Props) {
  const cssClassName = css`
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  `;
  return (
    <>
      <TextField
        label="Debit Memo Date"
        variant="standard"
        value={chargebackLineitem.debitMemoDateStr || ""}
        onChange={(e) =>
          onSetLineitemAttribute("debitMemoDateStr", e.target.value)
        }
        className={cssClassName}
      />
      <TextField
        label="Vendor Num"
        variant="standard"
        value={chargebackLineitem.vendorNum || ""}
        onChange={(e) => onSetLineitemAttribute("vendorNum", e.target.value)}
        className={cssClassName}
      />
      <TextField
        label="Vendor Name"
        variant="standard"
        value={chargebackLineitem.vendorName || ""}
        onChange={(e) => onSetLineitemAttribute("vendorName", e.target.value)}
        className={cssClassName}
      />
      <TextField
        label="DC id"
        variant="standard"
        value={chargebackLineitem.dcId || ""}
        onChange={(e) => onSetLineitemAttribute("dcId", e.target.value)}
        className={cssClassName}
      />
      <TextField
        label="Line Item Num"
        variant="standard"
        value={chargebackLineitem.lineItemNum || ""}
        onChange={(e) => onSetLineitemAttribute("lineItemNum", e.target.value)}
        className={cssClassName}
      />
      <TextField
        label="NDC"
        variant="standard"
        value={chargebackLineitem.ndc || ""}
        onChange={(e) => onSetLineitemAttribute("ndc", e.target.value)}
        className={cssClassName}
      />
      <TextField
        label="Wholesaler Product Code"
        variant="standard"
        value={chargebackLineitem.wholesalerProductCode || ""}
        onChange={(e) =>
          onSetLineitemAttribute("wholesalerProductCode", e.target.value)
        }
        className={cssClassName}
      />
      <TextField
        label="U O M"
        variant="standard"
        value={chargebackLineitem.uom || ""}
        onChange={(e) => onSetLineitemAttribute("uom", e.target.value)}
        className={cssClassName}
      />
      <TextField
        label="Member HIN"
        variant="standard"
        value={chargebackLineitem.memberHin || ""}
        onChange={(e) => onSetLineitemAttribute("memberHin", e.target.value)}
        className={cssClassName}
      />
      <TextField
        label="Member DEA"
        variant="standard"
        value={chargebackLineitem.memberDea || ""}
        onChange={(e) => onSetLineitemAttribute("memberDea", e.target.value)}
        className={cssClassName}
      />
      <TextField
        label="Member 340B"
        variant="standard"
        value={chargebackLineitem.memberId340B || ""}
        onChange={(e) => onSetLineitemAttribute("memberId340B", e.target.value)}
        className={cssClassName}
      />
      <TextField
        label="Member Facility ID"
        variant="standard"
        value={chargebackLineitem.memberFacilityId || ""}
        onChange={(e) =>
          onSetLineitemAttribute("memberFacilityId", e.target.value)
        }
        className={cssClassName}
      />
      <TextField
        label="Member Name"
        variant="standard"
        value={chargebackLineitem.memberName || ""}
        onChange={(e) => onSetLineitemAttribute("memberName", e.target.value)}
        className={cssClassName}
      />
      <TextField
        label="Member Address"
        variant="standard"
        value={chargebackLineitem.memberAddress || ""}
        onChange={(e) =>
          onSetLineitemAttribute("memberAddress", e.target.value)
        }
        className={cssClassName}
      />
      <TextField
        label="Member City"
        variant="standard"
        value={chargebackLineitem.memberCity || ""}
        onChange={(e) => onSetLineitemAttribute("memberCity", e.target.value)}
        className={cssClassName}
      />
      <TextField
        label="Member State"
        variant="standard"
        value={chargebackLineitem.memberState || ""}
        onChange={(e) => onSetLineitemAttribute("memberState", e.target.value)}
        className={cssClassName}
      />
      <TextField
        label="Member Postal Code"
        variant="standard"
        value={chargebackLineitem.memberPostalCode || ""}
        onChange={(e) =>
          onSetLineitemAttribute("memberPostalCode", e.target.value)
        }
        className={cssClassName}
      />
      <TextField
        label="Quantity"
        variant="standard"
        value={chargebackLineitem.quantityStr || ""}
        onChange={(e) => onSetLineitemAttribute("quantityStr", e.target.value)}
        className={cssClassName}
      />
      <CurrencyTextField
        label="Submitted WAC"
        variant="standard"
        value={chargebackLineitem.wacStr || ""}
        setValue={(val: string) => onSetLineitemAttribute("wacStr", val)}
        className={cssClassName}
      />
      <CurrencyTextField
        label="Submitted Extended WAC"
        variant="standard"
        value={chargebackLineitem.extendedWacStr || ""}
        setValue={(val: string) =>
          onSetLineitemAttribute("extendedWacStr", val)
        }
        className={cssClassName}
      />
      <TextField
        variant="standard"
        label="System Extended WAC"
        disabled
        value={centsToDollars(chargebackLineitem.systemExtendedWac)}
        className={cssClassName}
      />
      <CurrencyTextField
        label="Contract Price"
        variant="standard"
        value={chargebackLineitem.contractPriceStr || ""}
        setValue={(val: string) =>
          onSetLineitemAttribute("contractPriceStr", val)
        }
        className={cssClassName}
      />
      <CurrencyTextField
        label="Extended Contract Price"
        variant="standard"
        value={chargebackLineitem.extendedContractPriceStr || ""}
        setValue={(val: string) =>
          onSetLineitemAttribute("extendedContractPriceStr", val)
        }
        className={cssClassName}
      />
      <TextField
        variant="standard"
        label="System Extended Contract Price"
        disabled
        value={centsToDollars(chargebackLineitem.systemExtendedContractPrice)}
        className={cssClassName}
      />
      <CurrencyTextField
        label="Chargeback Cost"
        variant="standard"
        value={chargebackLineitem.chargebackCostStr || ""}
        setValue={(val: string) =>
          onSetLineitemAttribute("chargebackCostStr", val)
        }
        className={cssClassName}
      />
      <TextField
        label="Invoice Num"
        variant="standard"
        value={chargebackLineitem.invoiceNum || ""}
        onChange={(e) => onSetLineitemAttribute("invoiceNum", e.target.value)}
        className={cssClassName}
      />
      <TextField
        label="Invoice Date"
        variant="standard"
        value={chargebackLineitem.invoiceDateStr || ""}
        onChange={(e) =>
          onSetLineitemAttribute("invoiceDateStr", e.target.value)
        }
        className={cssClassName}
      />
      <TextField
        label="Contract Num"
        variant="standard"
        value={chargebackLineitem.contractNum || ""}
        onChange={(e) => onSetLineitemAttribute("contractNum", e.target.value)}
        className={cssClassName}
      />
      <TextField
        label="GLN"
        variant="standard"
        value={chargebackLineitem.gln || ""}
        onChange={(e) => onSetLineitemAttribute("gln", e.target.value)}
        className={cssClassName}
      />
    </>
  );
}
