import { GroupMemberEligibilityInput } from "../types";

type CreateEmptyProps = {
  groupId: number;
  memberId: number;
};

export default class GroupMemberEligibility {
  public id?: number;
  public effectiveDate?: Date | null;
  public endDate?: Date | null;
  public createdAt?: Date;
  public memberId: number;
  public groupId: number;

  constructor({
    id,
    effectiveDate,
    endDate,
    createdAt,
    memberId,
    groupId,
  }: GroupMemberEligibilityInput) {
    this.id = id;
    this.effectiveDate = effectiveDate;
    this.endDate = endDate;
    this.createdAt = createdAt;
    this.memberId = memberId;
    this.groupId = groupId;
  }

  static createEmpty({ groupId, memberId }: CreateEmptyProps) {
    return new GroupMemberEligibility({
      groupId,
      memberId,
    });
  }
}
