import { FormControlLabel, FormGroup, Switch, TableCell } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import ChargebackFileAPI from "../api/ChargebackFile.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import ChargebackFileModel from "../models/ChargebackFile.model";
import {
  ChargebackFile,
  ChargebackFileImportType,
  Order,
  TableColumn,
} from "../types";
import { extractDate } from "../utils";
import ListTable from "./ListTable";
import Loading from "./Loading";

const columns: TableColumn<ChargebackFile>[] = [
  { field: "id", label: "ID" },
  { field: "filename", label: "File", orderBy: false },
  { field: "state", label: "State" },
  { field: "createdAt", label: "Created Date" },
];

// ${process.env.REACT_APP_FILE_STORAGE_BASE}${chargebackFile.file}
function buildTableRow(chargebackFile: ChargebackFile): JSX.Element[] {
  return [
    <TableCell key={"id"}>{chargebackFile.id}</TableCell>,
    <TableCell key={"filename"}>{`${chargebackFile.filename}`}</TableCell>,
    <TableCell key={"state"}>{`${ChargebackFileModel.stateName(
      chargebackFile.state,
    )}`}</TableCell>,
    <TableCell key={"createdAt"}>
      {extractDate(chargebackFile.createdAt)}
    </TableCell>,
  ];
}

export default function ChargebackFileListTable() {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof ChargebackFile>("createdAt");
  const [importType, setImportType] =
    React.useState<ChargebackFileImportType>("upload");

  const navigate = useNavigate();

  const { data } = ChargebackFileAPI.useList({
    businessUnit: currentBusinessUnit,
    order,
    orderBy,
    page,
    importType,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ChargebackFile,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick =
    (chargebackFile: ChargebackFile) => (event: unknown) => {
      navigate(`/chargebacks/files/${chargebackFile.id}`);
    };

  return (
    <React.Fragment>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={importType === "sftp"}
              onChange={(event) => {
                setImportType(event.target.checked ? "sftp" : "upload");
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Auto Imported TrueCommerce files"
        />
      </FormGroup>
      {data ? (
        <ListTable
          columns={columns}
          buildTableRow={buildTableRow}
          handleChangePage={handleChangePage}
          handleRowClick={handleRowClick}
          handleRequestSort={handleRequestSort}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        />
      ) : (
        <Loading />
      )}
    </React.Fragment>
  );
}
