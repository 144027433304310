import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React from "react";

import ChargebackDebitMemoAPI from "../api/ChargebackDebitMemo.api";
import ChargebackDebitMemoFeedback from "../components/ChargebackDebitMemoFeedback";
import Loading from "../components/Loading";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { ChargebackDebitMemo, ListResponseData } from "../types";
import EmptyTableRows from "./EmptyTableRows";

export default function ChargebackDebitMemoInvestigatingList() {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [query, setQuery] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(0);

  const { data, isLoading, refetch } = ChargebackDebitMemoAPI.useList({
    state: "investigating",
    page,
    businessUnit: currentBusinessUnit,
    query,
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const refetchChargebackDebitMemos = () => {
    refetch();
  };

  return (
    <>
      <TextField
        id="ChargebackDebitMemoInvestigating-search"
        label="Search field"
        type="search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        fullWidth
      />
      {data && !isLoading ? (
        <TableContainerView
          data={data}
          refetchChargebackDebitMemos={refetchChargebackDebitMemos}
          handleChangePage={handleChangePage}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}

type PropsTableContainerView = {
  data: ListResponseData<ChargebackDebitMemo>;
  refetchChargebackDebitMemos: () => void;
  handleChangePage: (event: unknown, newPage: number) => void;
};

function TableContainerView({
  data,
  refetchChargebackDebitMemos,
  handleChangePage,
}: PropsTableContainerView) {
  const meta = data.meta;
  const currentPage = meta.current - 1;
  const rowsPerPage = meta.perPage;
  const emptyRows =
    currentPage > 0 ? Math.max(0, rowsPerPage - data.data.length) : 0;
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="feedback table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">Debit Memo</TableCell>
            <TableCell align="right">State</TableCell>
            <TableCell align="right">Approved On</TableCell>
            <TableCell align="right">Wholesaler</TableCell>
            <TableCell align="right">Lines #</TableCell>
            <TableCell align="right">Requires Action #</TableCell>
            <TableCell align="right">Requested $</TableCell>
            <TableCell align="right">System Calculated $</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data &&
            data.data.map((chargebackDebitMemo) => (
              <ChargebackDebitMemoFeedback
                key={chargebackDebitMemo.id}
                chargebackDebitMemo={chargebackDebitMemo}
                refetchChargebackDebitMemos={refetchChargebackDebitMemos}
              />
            ))}

          <EmptyTableRows colSpan={10} emptyRows={emptyRows} />
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[meta.perPage]}
        component="div"
        count={meta.count}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onPageChange={handleChangePage}
      />
    </TableContainer>
  );
}
