import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import GroupAPI from "../api/Group.api";
import GroupForm from "../components/GroupForm";
import Layout from "../components/Layout";
import Loading from "../components/Loading";

export default function GroupEdit() {
  const { id } = useParams();
  const { data, isLoading } = GroupAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">Group Edit</Typography>

        {isLoading ? <Loading /> : data && <GroupForm group={data} />}
      </Box>
    </Layout>
  );
}
