import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import React from "react";

import { MetaPagination, Order, TableColumn } from "../types";
import EmptyTableRows from "./EmptyTableRows";

interface TableProps<Type> {
  buildTableRow: (arg0: Type) => JSX.Element[];
  buildRowDropDown?: (arg0: Type) => JSX.Element[] | JSX.Element;
  columns: TableColumn<Type>[];
  handleChangePage: (event: unknown, newPage: number) => void;
  handleSelected: () => void;
  handleRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Type,
  ) => void;
  meta: MetaPagination;
  order: Order;
  orderBy: string;
  rows?: Type[];
  selected: number[];
  setSelected: (arg: number[]) => void;
  selectedIcon?: JSX.Element;
}

export default function SelectTableWithCustomRow<Type extends { id: number }>({
  buildTableRow,
  buildRowDropDown,
  columns,
  handleChangePage,
  handleSelected,
  handleRequestSort,
  meta,
  order,
  orderBy,
  rows = [],
  selected,
  setSelected,
  selectedIcon,
}: TableProps<Type>) {
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      // TODO, select all from all pages??
      // or just allow you to keep clicking select all on each page
      return;
    }
    setSelected([]);
  };

  const tableTitle = "SelectTableTitle";

  const page = meta.current - 1;
  const rowsPerPage = meta.perPage;
  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ display: "flex", flexGrow: 1, width: "100%" }}>
      <Paper sx={{ mb: 2, px: 2, width: "100%" }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleSelected={handleSelected}
          selectedIcon={selectedIcon}
        />
        <TableContainer>
          <Table aria-labelledby={tableTitle}>
            <EnhancedTableHead
              columns={columns}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              order={order}
              orderBy={orderBy}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => (
                <EnhancedTableRow
                  buildTableRow={buildTableRow}
                  buildRowDropDown={buildRowDropDown}
                  columns={columns}
                  key={row.id}
                  index={index}
                  row={row}
                  selected={selected}
                  setSelected={setSelected}
                />
              ))}

              <EmptyTableRows colSpan={columns.length} emptyRows={emptyRows} />
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[meta.perPage]}
          component="div"
          count={meta.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
    </Box>
  );

  interface EnhancedTableHeadProps<Type> {
    columns: TableColumn<Type>[];
    numSelected: number;
    order: Order;
    orderBy: string;
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof Type,
    ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
  }

  function EnhancedTableHead<Type>({
    columns,
    numSelected,
    onRequestSort,
    onSelectAllClick,
    order,
    orderBy,
    rowCount,
  }: EnhancedTableHeadProps<Type>) {
    const createSortHandler =
      (property: keyof Type) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
          {columns.map((headCell, index) => (
            <TableCell
              key={index}
              align="left"
              padding="normal"
              sortDirection={orderBy === headCell.field ? order : undefined}
            >
              {headCell.orderBy !== false ? (
                <TableSortLabel
                  active={headCell.field === orderBy}
                  direction={orderBy === headCell.field ? order : "asc"}
                  onClick={createSortHandler(headCell.field)}
                >
                  {headCell.label}
                  {orderBy === headCell.field ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  interface EnhancedTableToolbarProps {
    numSelected: number;
    handleSelected: () => void;
    selectedIcon?: JSX.Element;
  }

  function EnhancedTableToolbar({
    numSelected,
    handleSelected,
    selectedIcon,
  }: EnhancedTableToolbarProps) {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity,
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Select debit memos to export
          </Typography>
        )}
        {numSelected > 0 && (
          <Tooltip title="">
            <IconButton onClick={handleSelected}>
              {selectedIcon ? selectedIcon : <FileDownloadIcon />}
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  }
}

interface EnhancedTableRowProps<Type> {
  buildTableRow: (arg0: Type) => JSX.Element[];
  buildRowDropDown?: (arg0: Type) => JSX.Element[] | JSX.Element;
  columns: TableColumn<Type>[];
  row: Type;
  index: number;
  setSelected: (arg: number[]) => void;
  selected: number[];
}

function EnhancedTableRow<Type extends { id: number }>({
  buildTableRow,
  buildRowDropDown,
  columns,
  row,
  index,
  selected,
  setSelected,
}: EnhancedTableRowProps<Type>) {
  const handleRowClick = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as any;
    if (!(target?.type === "checkbox" || target?.type === "button")) {
      setOpen((open) => !open);
    }
  };
  const handleCheckClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const isItemSelected = isSelected(row.id);
  const labelId = `enhanced-table-checkbox-${index}`;

  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <React.Fragment key={index}>
      <TableRow
        hover
        key={index}
        role="checkbox"
        tabIndex={-1}
        selected={isItemSelected}
        // onClick={() => setOpen((open) => (open ? !open : false))}
        // onClick={() => setOpen((open) => !open)}
        onClick={handleRowClick}
        sx={{ cursor: "pointer" }}
      >
        <TableCell
          padding="checkbox"
          onClick={(event) => handleCheckClick(event, row.id)}
        >
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>

        {buildTableRow(row)}
      </TableRow>
      {buildRowDropDown && (
        <TableRow sx={{ backgroundColor: grey[50] }}>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={columns.length + 2}
          >
            <Collapse in={open} timeout={500} mountOnEnter>
              {buildRowDropDown(row)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
