import { Box, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import ReportAPI from "../api/Report.api";
import ArchiveButton from "../components/ArchiveButton";
import Layout from "../components/Layout";
import ReportModel from "../models/Report.model";

export default function ReportShow() {
  const { id } = useParams();
  const { data } = ReportAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">Report Status</Typography>
        {data && (
          <Box>
            <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
              <TextField
                disabled
                label="State"
                variant="standard"
                value={ReportModel.stateName(data.state)}
                fullWidth
              />
              <TextField
                disabled
                label="Type"
                variant="standard"
                value={ReportModel.reportTypeName(data.reportType)}
                fullWidth
              />
            </Box>
            {data.state === "completed" && (
              <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
                <Link target="_blank" to={data.file}>
                  Download File
                </Link>
              </Box>
            )}
            <Box sx={{ display: "flex", flexDirection: "row", gap: "4rem" }}>
              {data && data.id && <ArchiveReportButton id={data.id} />}
            </Box>
          </Box>
        )}
      </Box>
    </Layout>
  );
}

function ArchiveReportButton({ id }: { id: number }) {
  const navigate = useNavigate();

  const { mutateAsync } = ReportAPI.useDelete(id);
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Archived successfully", { variant: "success" });
      navigate("/reports/custom");
    } catch (error) {
      console.error(error);
    }
  };

  return <ArchiveButton onDelete={onDelete} />;
}
