import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { Link } from "react-router-dom";

import ChargebackLineitemAPI from "../api/ChargebackLineitem.api";
import ChargebackLineitemModel from "../models/ChargebackLineitem.model";
import {
  ChargebackLineitemState,
  ChargebackLineitemWithAssociations,
} from "../types";
import { centsToDollars } from "../utils";
import FeedbackLineitemFields from "./FeedbackLineitemFields";

type Props = {
  chargebackLineitem: ChargebackLineitemWithAssociations;
  isRefetchingLineitems: boolean;
  refetchLineitems: () => void;
};

const GreyButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#254061",
  "&:hover": {
    backgroundColor: "#254061",
  },
}));

const GreenButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#88a80d",
  "&:hover": {
    backgroundColor: "#88a80d",
  },
}));

export default function ChargebackDebitMemoFeedbackLineitem({
  chargebackLineitem,
  isRefetchingLineitems,
  refetchLineitems,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [updatingLineitem, setUpdatingLineitem] = React.useState(false);
  const [updatedChargebackLineitem, setUpdatedChargebackLineitem] =
    React.useState(chargebackLineitem);
  const [open, setOpen] = React.useState<boolean>(false);

  const { mutateAsync, isLoading } = ChargebackLineitemAPI.useReprocess(
    updatedChargebackLineitem,
  );

  const onSave = async () => {
    try {
      setUpdatingLineitem(true);
      const resData = await mutateAsync();
      if ("errors" in resData) {
        resData.errors.forEach((message: string) =>
          enqueueSnackbar(message, { variant: "error" }),
        );
      } else {
        setUpdatedChargebackLineitem(resData);
        refetchLineitems();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`Error reprocessing lineitem: ${error}`, {
        variant: "error",
      });
    } finally {
      setUpdatingLineitem(false);
    }
  };
  const throttledOnSave = React.useCallback(debounce(onSave, 1000), []);

  const onSetLineitemAttribute = (
    key: keyof ChargebackLineitemWithAssociations,
    value: string,
  ): void => {
    setUpdatedChargebackLineitem({
      ...updatedChargebackLineitem,
      [key]: value,
    });
  };

  const selectBackgroundColor = (state: ChargebackLineitemState) => {
    if (state === "rejected") {
      return "error.main";
    } else if (state === "pay_calculated_amount") {
      return "success.main";
    } else if (state === "pay_requested_amount") {
      return "primary.light";
    }
    return "";
  };

  return (
    <>
      <TableRow
        key={chargebackLineitem.id}
        sx={{
          "& > *": { borderBottom: "unset" },
          backgroundColor: selectBackgroundColor(chargebackLineitem.state),
          border: "5px solid white",
          borderBottom: open ? 0 : null,
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Link
            to={`/chargebacks/files/${chargebackLineitem.chargebackFileId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {chargebackLineitem.fileLineNum}
          </Link>
        </TableCell>
        <Tooltip
          title={chargebackLineitem.rejectCodeDescriptions}
          placement="bottom"
        >
          <TableCell>{chargebackLineitem.rejectCodes}</TableCell>
        </Tooltip>
        <TableCell>
          {ChargebackLineitemModel.stateName(chargebackLineitem.state)}
        </TableCell>
        {chargebackLineitem.wholesalerDistributionCenter ? (
          <TableCell align="right" style={{ borderBottom: "none" }}>
            <Link
              to={`/wholesalers/${chargebackLineitem.wholesalerDistributionCenter.wholesalerId}`}
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {chargebackLineitem.wholesalerDistributionCenter.wholesalerName}
            </Link>
          </TableCell>
        ) : (
          <TableCell align="right">None</TableCell>
        )}
        {chargebackLineitem.contract ? (
          <Link
            to={`/contracts/${chargebackLineitem.contract.id}`}
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TableCell align="right" style={{ borderBottom: "none" }}>
              {chargebackLineitem.contract && chargebackLineitem.contract.name}
            </TableCell>
          </Link>
        ) : (
          <TableCell align="right" style={{ borderBottom: "none" }}>
            None
          </TableCell>
        )}
        {chargebackLineitem.member ? (
          <Link
            to={`/members/${chargebackLineitem.member.id}`}
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TableCell align="right" style={{ borderBottom: "none" }}>
              {chargebackLineitem.member &&
                chargebackLineitem.member.facilityName}
            </TableCell>
          </Link>
        ) : (
          <TableCell align="right">None</TableCell>
        )}
        {chargebackLineitem.product ? (
          <Link
            to={`/products/${chargebackLineitem.product.id}`}
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TableCell align="right" style={{ borderBottom: "none" }}>
              {chargebackLineitem.ndc}
            </TableCell>
          </Link>
        ) : (
          <TableCell align="right">{chargebackLineitem.ndc}</TableCell>
        )}
        <TableCell align="right">{chargebackLineitem.quantity}</TableCell>
        <TableCell align="right">
          {centsToDollars(chargebackLineitem.wac)}
        </TableCell>
        <TableCell align="right">
          {centsToDollars(chargebackLineitem.systemWac)}
        </TableCell>
        <TableCell align="right">
          {centsToDollars(chargebackLineitem.contractPrice)}
        </TableCell>
        <TableCell align="right">
          {centsToDollars(chargebackLineitem.systemContractPrice)}
        </TableCell>
        <TableCell align="right">
          {centsToDollars(chargebackLineitem.requestedAmount)}
        </TableCell>
        <TableCell align="right">
          {centsToDollars(chargebackLineitem.calculatedAmount)}
        </TableCell>
        <TableCell align="right">
          {chargebackLineitem.state === "uninvestigated" ? (
            <InvestigatingButton
              id={chargebackLineitem.id}
              refetchChargebackDebitMemo={refetchLineitems}
            />
          ) : chargebackLineitem.state === "investigating" ? (
            "Investigating"
          ) : (
            "Valid"
          )}
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          backgroundColor: selectBackgroundColor(chargebackLineitem.state),
          border: "5px solid white",
          borderTop: 0,
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={16}>
          <Collapse in={open} timeout={500} unmountOnExit>
            <Box sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "0.5rem",
                  justifyContent: "space-between",
                }}
              >
                <FeedbackLineitemFields
                  onSetLineitemAttribute={onSetLineitemAttribute}
                  chargebackLineitem={updatedChargebackLineitem}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  flexDirection: "row",
                  marginTop: "1rem",
                  gap: "1rem",
                }}
              >
                <Box sx={{ display: "flex", flexGrow: 1 }}>
                  <TextField
                    fullWidth
                    label="Comments"
                    multiline
                    rows={3}
                    value={updatedChargebackLineitem["comments"] || ""}
                    onChange={(e) => {
                      onSetLineitemAttribute("comments", e.target.value);
                    }}
                  />
                </Box>
                <PayCalculatedAmountButton
                  id={chargebackLineitem.id.toString()}
                  setUpdatedChargebackLineitem={setUpdatedChargebackLineitem}
                  refetchLineitems={refetchLineitems}
                />
                <PayRequestedAmountButton
                  id={chargebackLineitem.id.toString()}
                  setUpdatedChargebackLineitem={setUpdatedChargebackLineitem}
                  refetchLineitems={refetchLineitems}
                />
                <GreyButton
                  onClick={throttledOnSave}
                  variant="outlined"
                  color="primary"
                  disabled={isLoading || updatingLineitem}
                >
                  Save & Reprocess
                </GreyButton>
                <RejectButton
                  id={chargebackLineitem.id.toString()}
                  setUpdatedChargebackLineitem={setUpdatedChargebackLineitem}
                  refetchLineitems={refetchLineitems}
                />
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

type PropsPayRequestedAmountButton = {
  id: string;
  setUpdatedChargebackLineitem: (
    arg0: ChargebackLineitemWithAssociations,
  ) => void;
  refetchLineitems: () => void;
};

function PayRequestedAmountButton({
  id,
  setUpdatedChargebackLineitem,
  refetchLineitems,
}: PropsPayRequestedAmountButton) {
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync, isLoading } =
    ChargebackLineitemAPI.usePayRequestedAmount(id);

  const onPayRequested = async () => {
    const resData = await mutateAsync();
    if ("errors" in resData) {
      resData.errors.forEach((message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      enqueueSnackbar("Successfully paid requested amount", {
        variant: "success",
      });
      setUpdatedChargebackLineitem(resData);
      refetchLineitems();
    }
  };

  const throttledOnPayRequested = React.useCallback(
    debounce(onPayRequested, 1000),
    [],
  );

  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#fff",
    backgroundColor: "#449ca2",
    "&:hover": {
      backgroundColor: "#449ca2",
    },
  }));

  return (
    <ColorButton
      onClick={throttledOnPayRequested}
      variant="contained"
      disabled={isLoading}
    >
      Pay requested
    </ColorButton>
  );
}

type PropsPayCalculatedAmountButton = {
  id: string;
  setUpdatedChargebackLineitem: (
    arg0: ChargebackLineitemWithAssociations,
  ) => void;
  refetchLineitems: () => void;
};

function PayCalculatedAmountButton({
  id,
  setUpdatedChargebackLineitem,
  refetchLineitems,
}: PropsPayCalculatedAmountButton) {
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync, isLoading } =
    ChargebackLineitemAPI.usePayCalculatedAmount(id);

  const onPayCalculated = async () => {
    const resData = await mutateAsync();
    if ("errors" in resData) {
      resData.errors.forEach((message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      enqueueSnackbar("Successfully paid calculated amount", {
        variant: "success",
      });
      setUpdatedChargebackLineitem(resData);
      refetchLineitems();
    }
  };

  const throttledOnPayCalculated = React.useCallback(
    debounce(onPayCalculated, 1000),
    [],
  );

  return (
    <GreenButton
      onClick={throttledOnPayCalculated}
      variant="contained"
      disabled={isLoading}
      color="success"
    >
      Pay Calculated
    </GreenButton>
  );
}

type PropsRejectButton = {
  id: string;
  setUpdatedChargebackLineitem: (
    arg0: ChargebackLineitemWithAssociations,
  ) => void;
  refetchLineitems: () => void;
};

function RejectButton({
  id,
  setUpdatedChargebackLineitem,
  refetchLineitems,
}: PropsRejectButton) {
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync, isLoading } = ChargebackLineitemAPI.useReject(id);

  const onReject = async () => {
    const resData = await mutateAsync();
    if ("errors" in resData) {
      resData.errors.forEach((message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      enqueueSnackbar("Successfully rejected chargeback", {
        variant: "success",
      });
      setUpdatedChargebackLineitem(resData);
      refetchLineitems();
    }
  };

  const throttledOnReject = React.useCallback(debounce(onReject, 1000), []);

  return (
    <Button
      onClick={throttledOnReject}
      variant="contained"
      color="error"
      disabled={isLoading}
    >
      Reject
    </Button>
  );
}

type PropsInvestigatingButton = {
  id: number;
  refetchChargebackDebitMemo: () => void;
};

function InvestigatingButton({
  id,
  refetchChargebackDebitMemo,
}: PropsInvestigatingButton) {
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync, isLoading } = ChargebackLineitemAPI.useInvestigate(id);

  const onInvestigate = async () => {
    const resData = await mutateAsync();
    if ("errors" in resData) {
      resData.errors.forEach((message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      refetchChargebackDebitMemo();
      enqueueSnackbar("Successfully moved chargeback to INVESTIGATING", {
        variant: "success",
      });
    }
  };

  const throttledOnInvestigate = React.useCallback(
    debounce(onInvestigate, 1000),
    [],
  );

  return (
    <Button
      onClick={throttledOnInvestigate}
      variant="contained"
      disabled={isLoading}
    >
      Investigate
    </Button>
  );
}
