import { Box, Typography } from "@mui/material";
import React from "react";

import ContractForm from "../components/ContractForm";
import Layout from "../components/Layout";
import ContractModel from "../models/Contract.model";
import { ContractInput } from "../types";

export default function ContractNew() {
  const [contract] = React.useState<ContractInput>(
    ContractModel.createEmpty({}),
  );

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">New Contract</Typography>

        <ContractForm contract={contract} />
      </Box>
    </Layout>
  );
}
