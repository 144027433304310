import { TableCell } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import BulkEditAPI from "../api/BulkEdit.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { BulkEdit, Order, TableColumn } from "../types";
import { extractDate } from "../utils";
import ListTable from "./ListTable";
import Loading from "./Loading";

const columns: TableColumn<BulkEdit>[] = [
  { field: "filename", label: "File Name" },
  { field: "state", label: "State" },
  { field: "modelType", label: "Type" },
  { field: "createdAt", label: "Created At Date" },
];

function buildTableRow(bulkEdit: BulkEdit): JSX.Element[] {
  return [
    <TableCell key={"name"}>{bulkEdit.filename}</TableCell>,
    <TableCell key={"state"}>{bulkEdit.state}</TableCell>,
    <TableCell key={"modelType"}>{bulkEdit.modelType}</TableCell>,
    <TableCell key={"createdAt"}>{extractDate(bulkEdit.createdAt)}</TableCell>,
  ];
}

export default function BulkEditListTable() {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof BulkEdit>("createdAt"); // TODO

  const navigate = useNavigate();

  const { data } = BulkEditAPI.useList({
    businessUnit: currentBusinessUnit,
    order,
    orderBy,
    page,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof BulkEdit,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick = (bulkEdit: BulkEdit) => (event: unknown) => {
    navigate(`/bulk-edits/${bulkEdit.id}`);
  };

  return (
    <>
      {data ? (
        <ListTable
          columns={columns}
          buildTableRow={buildTableRow}
          handleChangePage={handleChangePage}
          handleRowClick={handleRowClick}
          handleRequestSort={handleRequestSort}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}
