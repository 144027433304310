import camelcaseKeys from "camelcase-keys";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import snakeCaseKeys from "snakecase-keys";

import {
  ChargebackLineitem,
  ChargebackLineitemInput,
  ChargebackLineitemQueryProps,
  ChargebackLineitemWithAssociations,
  ErrorResult,
  ListResponseData,
  MetaPagination,
} from "../types";
import client from "./client";
import { handleDetail, handleQueryError } from "./handler";

async function fetchChargebackLineitems({
  page,
  chargebackDebitMemoId,
}: ChargebackLineitemQueryProps): Promise<
  ListResponseData<ChargebackLineitemWithAssociations>
> {
  try {
    const searchParams = new URLSearchParams();
    if (page) {
      searchParams.set("page", page.toString());
    }
    if (chargebackDebitMemoId) {
      searchParams.set(
        "chargeback_debit_memo_id",
        chargebackDebitMemoId.toString(),
      );
    }
    const response: {
      data: ChargebackLineitemWithAssociations[];
      meta: MetaPagination;
    } = await client
      .get("chargeback_lineitems", {
        searchParams,
      })
      .json();

    return camelcaseKeys(
      { data: response.data || [], meta: response.meta },
      { deep: true },
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postInvestigateChargebackLineitem(
  id: number | undefined,
): Promise<ChargebackLineitem> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data: ChargebackLineitem = await client
      .post(`chargeback_lineitems/${id}/investigate`)
      .json();
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postReprocessChargebackLineitem(
  chargebackLineitemInput: ChargebackLineitemInput,
): Promise<ChargebackLineitemWithAssociations> {
  try {
    const chargebackLineitemInputSnaked = snakeCaseKeys(
      chargebackLineitemInput,
      {
        deep: true,
      },
    );
    const data: ChargebackLineitemWithAssociations = await client
      .put(`chargeback_lineitems/${chargebackLineitemInput.id}`, {
        json: chargebackLineitemInputSnaked,
      })
      .json();
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postPayRequestedAmount(
  id: string | undefined,
): Promise<ChargebackLineitemWithAssociations> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data: ChargebackLineitemWithAssociations = await client
      .post(`chargeback_lineitems/${id}/pay_requested_amount`)
      .json();
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postPayCalculatedAmount(
  id: string | undefined,
): Promise<ChargebackLineitemWithAssociations> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data: ChargebackLineitemWithAssociations = await client
      .post(`chargeback_lineitems/${id}/pay_calculated_amount`)
      .json();
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

async function postRejectChargebackLineitem(
  id: string | undefined,
): Promise<ChargebackLineitemWithAssociations> {
  try {
    if (typeof id === "undefined") {
      Promise.reject(new Error("Invalid id"));
    }
    const data: ChargebackLineitemWithAssociations = await client
      .post(`chargeback_lineitems/${id}/reject`)
      .json();
    return camelcaseKeys(data, { deep: true });
  } catch (error) {
    return Promise.reject(error);
  }
}

const methods = {
  useList: ({ chargebackDebitMemoId, page }: ChargebackLineitemQueryProps) => {
    const navigate = useNavigate();
    return useQuery<ListResponseData<ChargebackLineitemWithAssociations>>({
      cacheTime: 0,
      queryKey: [chargebackDebitMemoId, "chargeback_lineitems", page],
      queryFn: () => fetchChargebackLineitems({ chargebackDebitMemoId, page }),
      onError: handleQueryError(navigate),
      keepPreviousData: true,
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<ChargebackLineitemWithAssociations>({
      cacheTime: 0,
      queryKey: ["chargeback_lineitems", id],
      queryFn: () =>
        handleDetail({
          id,
          baseUrl: "chargeback_lineitems",
        }),
      onError: handleQueryError(navigate),
    });
  },
  useInvestigate: (id: number | undefined) => {
    const navigate = useNavigate();
    return useMutation<ChargebackLineitem | ErrorResult>({
      mutationFn: () => postInvestigateChargebackLineitem(id),
      onError: handleQueryError(navigate),
    });
  },
  useReprocess: (chargebackLineitemInput: ChargebackLineitemInput) => {
    const navigate = useNavigate();
    return useMutation<ChargebackLineitemWithAssociations | ErrorResult>({
      mutationFn: () =>
        postReprocessChargebackLineitem(chargebackLineitemInput),
      onError: handleQueryError(navigate),
    });
  },
  usePayRequestedAmount: (id: string | undefined) => {
    const navigate = useNavigate();
    return useMutation<ChargebackLineitemWithAssociations | ErrorResult>({
      mutationFn: () => postPayRequestedAmount(id),
      onError: handleQueryError(navigate),
    });
  },
  usePayCalculatedAmount: (id: string | undefined) => {
    const navigate = useNavigate();
    return useMutation<ChargebackLineitemWithAssociations | ErrorResult>({
      mutationFn: () => postPayCalculatedAmount(id),
      onError: handleQueryError(navigate),
    });
  },
  useReject: (id: string | undefined) => {
    const navigate = useNavigate();
    return useMutation<ChargebackLineitemWithAssociations | ErrorResult>({
      mutationFn: () => postRejectChargebackLineitem(id),
      onError: handleQueryError(navigate),
    });
  },
};

export default methods;
