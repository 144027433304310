import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import Loading from "./components/Loading";
import {useBusinessUnitContext} from "./contexts/BusinessUnit.context";
import {useSessionContext} from "./contexts/Session.context";
import AutoImportedFileList from "./pages/AutoImportedFileList.page";
import AutoImportedFileShow from "./pages/AutoImportedFileShow.page";
import BulkEditList from "./pages/BulkEditList.page";
import BulkEditShow from "./pages/BulkEditShow.page";
import BulkEditUpload from "./pages/BulkEditUpload.page";
import BusinessUnitEdit from "./pages/BusinessUnitEdit.page";
import ChargebackApprovedList from "./pages/ChargebackApprovedList.page";
import ChargebackDebitMemoInvestigatedList from "./pages/ChargebackDebitMemoInvestigatedList.page";
import ChargebackDebitMemoSearch from "./pages/ChargebackDebitMemoSearch.page";
import ChargebackExportGroupList from "./pages/ChargebackExportGroupList.page";
import ChargebackExportGroupShow from "./pages/ChargebackExportGroupShow.page";
import ChargebackFileInfo from "./pages/ChargebackFileInfo.page";
import ChargebackFileList from "./pages/ChargebackFileList.page";
import ChargebackInvestigatingList from "./pages/ChargebackInvestigatingList.page";
import ChargebackDebitMemoTags from "./pages/ChargebackDebitMemoTags.page";
import ChargebackNew from "./pages/ChargebackNew.page";
import ContractEdit from "./pages/ContractEdit.page";
import ContractList from "./pages/ContractList.page";
import ContractNew from "./pages/ContractNew.page";
import CustomReports from "./pages/CustomReports.page";
import Dashboard from "./pages/Dashboard.page";
import EdiList from "./pages/EdiList.page";
import EmailConfirmation from "./pages/EmailConfirmation.page";
import ForgotPassword from "./pages/ForgotPassword.page";
import GroupEdit from "./pages/GroupEdit.page";
import GroupList from "./pages/GroupList.page";
import GroupNew from "./pages/GroupNew.page";
import HomePage from "./pages/Home.page";
import Login from "./pages/Login.page";
import MemberEdit from "./pages/MemberEdit.page";
import MemberList from "./pages/MemberList.page";
import MemberNew from "./pages/MemberNew.page";
import NonEdiList from "./pages/NonEdiList.page";
import OnboardBusinessUnit from "./pages/OnboardBusinessUnit.page";
import ProductEdit from "./pages/ProductEdit.page";
import ProductList from "./pages/ProductList.page";
import ProductNew from "./pages/ProductNew.page";
import ProfileEdit from "./pages/ProfileEdit.page";
import ReportShow from "./pages/ReportShow.page";
import ResetPassword from "./pages/ResetPassword.page";
import TagEdit from "./pages/TagEdit.page";
import TagList from "./pages/TagList.page";
import UserEdit from "./pages/UserEdit.page";
import UserList from "./pages/UserList.page";
import UserNew from "./pages/UserNew.page";
import WholesalerDistributionCenterEdit from "./pages/WholesalerDistributionCenterEdit.page";
import WholesalerDistributionCenterNew from "./pages/WholesalerDistributionCenterNew.page";
import WholesalerEdit from "./pages/WholesalerEdit.page";
import WholesalerList from "./pages/WholesalerList.page";
import WholesalerNew from "./pages/WholesalerNew.page";
import {BusinessUnit, Session} from "./types";

type PropsSessionProtectedRoute = {
  children: JSX.Element;
  loadedSessionStorage?: boolean;
  session?: Session;
};

const SessionProtectedRoute = ({
                                 session,
                                 loadedSessionStorage,
                                 children,
                               }: PropsSessionProtectedRoute) => {
  if (!loadedSessionStorage) {
    return <Loading/>;
  }
  if (!session) {
    return <Navigate to="/login" replace/>;
  }
  return children;
};

type PropsBusinessUnitProtectedRoute = {
  children: JSX.Element;
  loadedLocalStorage?: boolean;
  businessUnit?: BusinessUnit;
};

const BusinessUnitProtectedRoute = ({
                                      businessUnit,
                                      loadedLocalStorage,
                                      children,
                                    }: PropsBusinessUnitProtectedRoute) => {
  if (!loadedLocalStorage) {
    return <Loading/>;
  }
  if (!businessUnit) {
    return <Navigate to="/" replace/>;
  }
  return children;
};

function App() {
  const {currentBusinessUnit, loadedLocalStorage} = useBusinessUnitContext();
  const {session, loadedSessionStorage} = useSessionContext();

  return (
    <Routes>
      <Route
        index
        path="/"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <HomePage/>
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <ProfileEdit/>
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <UserList/>
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/users/new"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <UserNew/>
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/users/:id"
        element={
          <SessionProtectedRoute
            loadedSessionStorage={loadedSessionStorage}
            session={session}
          >
            <UserEdit/>
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/email_confirmation/:token"
        element={<EmailConfirmation/>}
      />
      <Route
        path="/edit-business-unit"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <BusinessUnitEdit/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route path="/onboard-business-unit" element={<OnboardBusinessUnit/>}/>
      <Route
        path="/auto-imported-files"
        element={
          <SessionProtectedRoute
            session={session}
            loadedSessionStorage={loadedSessionStorage}
          >
            <AutoImportedFileList/>
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/auto-imported-files/:id"
        element={
          <SessionProtectedRoute
            session={session}
            loadedSessionStorage={loadedSessionStorage}
          >
            <AutoImportedFileShow/>
          </SessionProtectedRoute>
        }
      />
      <Route
        path="/chargebacks/new"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <ChargebackNew/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/chargebacks/files"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <ChargebackFileList/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/chargebacks/files/:id"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <ChargebackFileInfo/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/chargebacks/investigating"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <ChargebackInvestigatingList/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/chargebacks/investigated"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <ChargebackDebitMemoInvestigatedList/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/chargebacks/approved"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <ChargebackApprovedList/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/chargebacks/search"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <ChargebackDebitMemoSearch/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/chargebacks/tags"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <ChargebackDebitMemoTags/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/contracts"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <ContractList/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/contracts/new"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <ContractNew/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/contracts/:id"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <ContractEdit/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <Dashboard/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/edi"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <EdiList/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/exports"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <ChargebackExportGroupList/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/exports/:id"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <ChargebackExportGroupShow/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/groups"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <GroupList/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/groups/new"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <GroupNew/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/groups/:id"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <GroupEdit/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route path="/login" element={<Login/>}/>
      <Route path="/forgot-password" element={<ForgotPassword/>}/>
      <Route path="/reset-password/:token" element={<ResetPassword/>}/>
      <Route
        path="/bulk-edits"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <BulkEditList/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/bulk-edits/new"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <BulkEditUpload/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/bulk-edits/:id"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <BulkEditShow/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/members"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <MemberList/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/members/new"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <MemberNew/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/members/:id"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <MemberEdit/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/non-edi"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <NonEdiList/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/products"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <ProductList/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/products/new"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <ProductNew/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/products/:id"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <ProductEdit/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/reports/custom"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <CustomReports/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/reports/:id"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <ReportShow/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/tags"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <TagList/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/tags/:id"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <TagEdit/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/wholesalers"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <WholesalerList/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/wholesalers/new"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <WholesalerNew/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/wholesalers/:id"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <WholesalerEdit/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/wholesalers/:wholesalerId/wholesaler_distribution_centers/new"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <WholesalerDistributionCenterNew/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="/wholesalers/:wholesalerId/wholesaler_distribution_centers/:id"
        element={
          <BusinessUnitProtectedRoute
            businessUnit={currentBusinessUnit}
            loadedLocalStorage={loadedLocalStorage}
          >
            <WholesalerDistributionCenterEdit/>
          </BusinessUnitProtectedRoute>
        }
      />
      <Route
        path="*"
        element={<Navigate to="/select-business-unit" replace/>}
      />
    </Routes>
  );
}

export default App;
