import { parseISO } from "date-fns";
import dayjs from "dayjs";
import { isNil } from "lodash";
import moment from "moment";

export function extractDate(arg0?: Date): string {
  if (isNil(arg0)) {
    return "N/A";
  }
  return moment(arg0).format("MM/DD/YYYY");
}

const ISODateFormat =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const isIsoDateString = (value: unknown): value is string => {
  return typeof value === "string" && ISODateFormat.test(value);
};

export function centsToDollars(priceCents: number | undefined): string {
  if (!priceCents) return "$0.00";
  // const priceCentsNum =
  //   priceCents instanceof "number" ? priceCents : parseInt(priceCents);
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(priceCents * 0.01);
}

export async function formatErrorResponse(error: any) {
  try {
    if ("response" in error) {
      const errorData = await error.response.json();
      return Promise.reject(errorData.exception);
    }
  } catch (e) {}
  return Promise.reject(error);
}

export const handleDates = (data: unknown) => {
  if (isIsoDateString(data)) return parseISO(data);
  if (typeof data === "string" && dayjs(data, "YYYY-MM-DD", true).isValid())
    return dayjs(data).toDate();
  if (data === null || data === undefined || typeof data !== "object")
    return data;

  for (const [key, val] of Object.entries(data)) {
    // @ts-expect-error this is a hack to make the type checker happy
    if (isIsoDateString(val)) data[key] = parseISO(val);
    else if (
      typeof val === "string" &&
      dayjs(val, "YYYY-MM-DD", true).isValid()
    )
      // @ts-expect-error this is a hack to make the type checker happy
      data[key] = dayjs(val).toDate();
    else if (typeof val === "object") handleDates(val);
  }

  return data;
};

export const convertDatesToStrings = (data: unknown) => {
  if (data instanceof Date) return dayjs(data).format("YYYY-MM-DD");
  if (data === null || data === undefined || typeof data !== "object")
    return data;
  for (const [key, val] of Object.entries(data)) {
    // @ts-expect-error this is a hack to make the type checker happy
    if (val instanceof Date) data[key] = dayjs(val).format("YYYY-MM-DD");
    else if (typeof val === "object") handleDates(val);
  }

  return data;
};
