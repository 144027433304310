import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Box, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import BusinessUnitAPI from "../api/BusinessUnit.api";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import LogoBox from "../components/LogoBox";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";

export default function Dashboard() {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const { data } = BusinessUnitAPI.useDashboard(currentBusinessUnit);

  return (
    <Layout backgroundColor={"#fff"}>
      {data ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            flex: 1,
            gap: "1rem",
          }}
        >
          <LogoBox />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "1rem",
              flexGrow: 1,
            }}
          >
            <Paper elevation={2} style={{ flex: 1 }}>
              <Link to="/chargebacks/files" style={{ textDecoration: "none" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2rem",
                    backgroundColor: "#254061",
                    gap: 2,
                    flexGrow: 1,
                    height: "16rem",
                  }}
                >
                  <InsertDriveFileIcon sx={{ fontSize: 40, color: "#fff" }} />
                  <Typography variant="h2" color="white">
                    {data.processingFiles}
                  </Typography>
                  <Typography variant="body1" color="white">
                    Files Actively Processing
                  </Typography>
                </Box>
              </Link>
            </Paper>
            <Paper elevation={2} style={{ flex: 1 }}>
              <Link
                to="/chargebacks/investigating"
                style={{ textDecoration: "none" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2rem",
                    backgroundColor: "#449ca2",
                    gap: 2,
                    flexGrow: 1,
                    height: "16rem",
                  }}
                >
                  <RemoveRedEyeIcon sx={{ fontSize: 40, color: "#fff" }} />
                  <Typography variant="h2" color="white">
                    {data.investigationDebitMemos}
                  </Typography>
                  <Typography variant="body1" color="white">
                    Debit Memos in "Investigation"
                  </Typography>
                </Box>
              </Link>
            </Paper>
            <Paper elevation={2} style={{ flex: 1 }}>
              <Link
                to="/chargebacks/investigated"
                style={{ textDecoration: "none" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2rem",
                    backgroundColor: "#88a80d",
                    gap: 2,
                    flexGrow: 1,
                    height: "16rem",
                  }}
                >
                  <CheckCircleIcon sx={{ fontSize: 40, color: "#fff" }} />
                  <Typography variant="h2" color="white">
                    {data.requiresApprovalDebitMemos}
                  </Typography>
                  <Typography variant="body1" color="white">
                    Debit Memos in "Requiring Approval"
                  </Typography>
                </Box>
              </Link>
            </Paper>
            <Paper elevation={2} style={{ flex: 1 }}>
              <Link to="/chargebacks/files" style={{ textDecoration: "none" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2rem",
                    backgroundColor: "#de1726",
                    gap: 2,
                    flexGrow: 1,
                    height: "16rem",
                  }}
                >
                  <ErrorIcon sx={{ fontSize: 40, color: "#fff" }} />
                  <Typography variant="h2" color="white">
                    {data.errorFiles}
                  </Typography>
                  <Typography variant="body1" color="white">
                    Files with Errors
                  </Typography>
                </Box>
              </Link>
            </Paper>
          </Box>
        </Box>
      ) : (
        <Loading />
      )}
    </Layout>
  );
}
