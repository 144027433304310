import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import MemberAPI from "../api/Member.api";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import MemberForm from "../components/MemberForm";

export default function MemberEdit() {
  const { id } = useParams();
  const { data, isLoading } = MemberAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">Member Edit</Typography>

        {isLoading ? (
          <Loading />
        ) : (
          data &&
          data.address && (
            <MemberForm member={data} addressInput={data.address} />
          )
        )}
      </Box>
    </Layout>
  );
}
