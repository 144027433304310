import { Button } from "@mui/material";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import BusinessUnitAPI from "../api/BusinessUnit.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";

type Props = {
  setReprocessing: (arg0: boolean) => void;
};

export default function ChargebackDebitMemoReprocessAllButton({
  setReprocessing,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { currentBusinessUnit } = useBusinessUnitContext();

  const businessUnitID = currentBusinessUnit && currentBusinessUnit.id;
  const { mutateAsync, isLoading } =
    BusinessUnitAPI.useReprocessAll(businessUnitID);

  const handleReprocessAll = async () => {
    const res = await mutateAsync();
    // TODO set reprocess all
    if ("errors" in res) {
      res.errors.forEach((message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      setReprocessing(true);
      enqueueSnackbar(
        "Reprocessing all Debit Memos, this will take a few minutes to be displayed correctly",
        { variant: "success" },
      );
    }
  };

  const throttledHandleReprocessAll = React.useCallback(
    debounce(handleReprocessAll, 1000),
    [],
  );

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={throttledHandleReprocessAll}
      disabled={isLoading}
    >
      Reprocess All
    </Button>
  );
}
