import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate } from "react-router-dom";

import UserAPI from "../api/User.api";
import { User } from "../types";

type Props = {
  user?: User;
};

export default function UserForm({ user }: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = React.useState<string>(user ? user.email : "");
  const [role, setRole] = React.useState<string>(
    user ? user.role.toString() : "basic",
  );
  const [password, setPassword] = React.useState<string>("");

  const { mutateAsync, isLoading } = UserAPI.useSave({
    id: user ? user.id : undefined,
    email,
    role,
    password,
  });

  const onSave = async () => {
    try {
      const newUser = await mutateAsync();
      enqueueSnackbar("Saved successfully", { variant: "success" });
      if (newUser.id) {
        navigate(`/users`);
      } else {
        navigate(`/users/${newUser.id}`);
      }
    } catch (error) {
      console.error(error.message);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const throttledOnSave = React.useCallback(debounce(onSave, 1000), []);

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <TextField
        label="Email"
        variant="outlined"
        value={email}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(event.target.value);
        }}
      />
      <FormControl fullWidth>
        <InputLabel id="user-role-label">Permissions</InputLabel>
        <Select
          labelId="user-role-label"
          id="user-role-select"
          value={role}
          label="Permissions"
          onChange={(event: SelectChangeEvent) => {
            setRole(event.target.value as string);
          }}
        >
          <MenuItem value={"basic"}>Basic</MenuItem>
          <MenuItem value={"admin"}>Admin</MenuItem>
        </Select>
      </FormControl>
      {!user && (
        <TextField
          label="Temp Password"
          variant="outlined"
          value={password}
          type="password"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "4rem",
          marginBottom: "1rem",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={throttledOnSave}
          disabled={isLoading}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}
