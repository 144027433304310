import { Box, Button, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";

import TagAPI from "../api/Tag.api";
import { TagInput } from "../types";

export default function TagForm({ input }: { input: TagInput }) {
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = React.useState(input.name);

  const { mutateAsync, isLoading } = TagAPI.useSave({
    id: input?.id,
    businessUnitId: input.businessUnitId,
    name: name,
  });

  const handleSave = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Saved successfully", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Error!", { variant: "error" });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <TextField
          label={"name"}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Box>
      <Button
        variant={"contained"}
        color={"primary"}
        onClick={handleSave}
        disabled={isLoading}
      >
        Save
      </Button>
    </Box>
  );
}
