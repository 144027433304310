import {useMutation, useQuery} from "react-query";
import {useNavigate} from "react-router-dom";

import {ContractProductPrice, ContractProductPriceInput, ErrorResult, ListResponseData, Order, Param,} from "../types";
import {handleDelete, handleDetail, handleList, handleQueryError, handleSave,} from "./handler";

const methods = {
  useList: ({
              contractId,
              order,
              orderBy,
              page,
              query,
            }: {
    contractId: number;
    order: Order,
    orderBy: keyof ContractProductPrice,
    page?: number,
    query?: string,
  }) => {
    const navigate = useNavigate();
    const params: Param[] = [];
    if (contractId) {
      params.push({key: "contract_id", value: contractId});
    }
    return useQuery<ListResponseData<ContractProductPrice>>({
      cacheTime: 0,
      queryKey: [
        "contract_product_prices",
        contractId,
        order,
        orderBy,
        page,
        query,
      ],
      queryFn: () =>
        handleList({
          baseUrl: "contract_product_prices",
          params,
          order,
          orderBy,
          page,
          query,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useDetail: (id: string | undefined) => {
    const navigate = useNavigate();
    return useQuery<ContractProductPrice>({
      cacheTime: 0,
      queryKey: ["contract_product_prices", id],
      queryFn: () =>
        handleDetail({
          id,
          baseUrl: "contract_product_prices",
        }),
      onError: handleQueryError(navigate),
    });
  },
  useSave: (contractProductPriceInput: ContractProductPriceInput) => {
    const navigate = useNavigate();
    return useMutation<ContractProductPrice | ErrorResult>({
      mutationFn: () =>
        handleSave({
          baseUrl: "contract_product_prices",
          input: contractProductPriceInput,
        }),
      onError: handleQueryError(navigate),
    });
  },
  useDelete: (id: number) => {
    const navigate = useNavigate();
    return useMutation<{ id: number } | ErrorResult>({
      mutationFn: () =>
        handleDelete({
          baseUrl: "contract_product_prices",
          id,
        }),
      onError: handleQueryError(navigate),
    });
  },
};
export default methods;
