import { ContractWholesalerEligibilityInput } from "../types";

type CreateEmptyProps = {
  contractId: number;
  wholesalerId: number;
};

export default class ContractProductPrice {
  public id?: number;
  public effectiveDate?: Date | null;
  public endDate?: Date | null;
  public createdAt?: Date;
  public wholesalerId?: number;
  public contractId?: number;
  public archived: boolean;

  constructor({
    id,
    effectiveDate,
    endDate,
    createdAt,
    wholesalerId,
    contractId,
    archived,
  }: ContractWholesalerEligibilityInput) {
    this.id = id;
    this.effectiveDate = effectiveDate;
    this.endDate = endDate;
    this.createdAt = createdAt;
    this.wholesalerId = wholesalerId;
    this.contractId = contractId;
    this.archived = archived;
  }

  static createEmpty({ wholesalerId, contractId }: CreateEmptyProps) {
    return new ContractProductPrice({
      wholesalerId,
      contractId,
      archived: false,
    });
  }
}
