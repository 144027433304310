import { TableCell, TextField } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import WholesalerAPI from "../api/Wholesaler.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { Order, TableColumn, Wholesaler } from "../types";
import ListTable from "./ListTable";
import Loading from "./Loading";

const columns: TableColumn<Wholesaler>[] = [
  { field: "name", label: "Name" },
  { field: "dea", label: "DEA" },
  { field: "hin", label: "HIN" },
  { field: "otherId", label: "Other ID" },
];

function buildTableRow(wholesaler: Wholesaler): JSX.Element[] {
  return [
    <TableCell key={"name"}>{wholesaler.name}</TableCell>,
    <TableCell key={"dea"}>{wholesaler.dea}</TableCell>,
    <TableCell key={"hin"}>{wholesaler.hin}</TableCell>,
    <TableCell key={"otherId"}>{wholesaler.otherId}</TableCell>,
  ];
}

export default function WholesalerListTable() {
  const { currentBusinessUnit } = useBusinessUnitContext();
  const navigate = useNavigate();

  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Wholesaler>("name"); // TODO
  const [query, setQuery] = React.useState<string>("");

  const { data } = WholesalerAPI.useList({
    businessUnit: currentBusinessUnit,
    order,
    orderBy,
    page,
    query,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Wholesaler,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick = (wholesaler: Wholesaler) => (event: unknown) => {
    navigate(`/wholesalers/${wholesaler.id}`);
  };

  return (
    <>
      <TextField
        id="WholesalerListTable-search"
        label="Search field"
        type="search"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {data ? (
        <ListTable
          columns={columns}
          buildTableRow={buildTableRow}
          handleChangePage={handleChangePage}
          handleRowClick={handleRowClick}
          handleRequestSort={handleRequestSort}
          meta={data.meta}
          order={order}
          orderBy={orderBy}
          rows={data.data}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}
