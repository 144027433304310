import { ProductInput } from "../types";

export default class Product {
  public id?: number;
  public name: string;
  public ndc: string;
  public upc: string;
  public gtin: string;
  public wholesalerProductNumber: string;
  public wholesaler: string;
  public genericName: string;
  public strength: string;
  public productFamily: string;
  public color: string;
  public shape: string;
  public productType: string;
  public archived?: boolean;
  public createdAt?: Date;

  constructor({
    id,
    name,
    ndc,
    upc,
    gtin,
    wholesalerProductNumber,
    wholesaler,
    genericName,
    strength,
    productFamily,
    color,
    shape,
    productType,
    archived,
    createdAt,
  }: ProductInput) {
    this.id = id;
    this.name = name;
    this.ndc = ndc;
    this.upc = upc;
    this.gtin = gtin;
    this.wholesalerProductNumber = wholesalerProductNumber;
    this.wholesaler = wholesaler;
    this.genericName = genericName;
    this.strength = strength;
    this.productFamily = productFamily;
    this.color = color;
    this.shape = shape;
    this.productType = productType;
    this.archived = archived;
    this.createdAt = createdAt;
  }

  static createEmpty() {
    return new Product({
      name: "",
      ndc: "",
      upc: "",
      gtin: "",
      wholesalerProductNumber: "",
      wholesaler: "",
      genericName: "",
      strength: "",
      productFamily: "",
      color: "",
      shape: "",
      productType: "",
      archived: false,
    });
  }
}
