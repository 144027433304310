import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import ContractRefNumAPI from "../api/ContractRefNum.api";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";
import { ContractRefNumInput } from "../types";

type Props = {
  contractId: number;
  id?: number;
  contractRefNumInput?: ContractRefNumInput;
  afterSaveCallback?: () => void;
};

export default function ContractRefNumForm({
  contractId,
  id,
  contractRefNumInput,
  afterSaveCallback,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { currentBusinessUnit } = useBusinessUnitContext();
  const [active, setActive] = React.useState<boolean>(
    contractRefNumInput?.active || false,
  );
  const [effectiveDate, setEffectiveDate] = React.useState<Dayjs | null>(
    contractRefNumInput?.effectiveDate
      ? dayjs(contractRefNumInput.effectiveDate)
      : null,
  );
  const [endDate, setEndDate] = React.useState(
    contractRefNumInput?.endDate ? dayjs(contractRefNumInput.endDate) : null,
  );
  const [refNum, setRefNum] = React.useState<string>(
    contractRefNumInput?.refNum || "",
  );

  const { mutateAsync, isLoading } = ContractRefNumAPI.useSave({
    id,
    contractId,
    businessUnitId: currentBusinessUnit && currentBusinessUnit.id,
    active,
    effectiveDate: effectiveDate ? effectiveDate.toDate() : effectiveDate,
    endDate: endDate ? endDate.toDate() : endDate,
    refNum,
  });

  const onSave = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Saved successfully", { variant: "success" });
      if (afterSaveCallback) {
        afterSaveCallback();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`Error creating Ref Number: ${error?.message}`, {
        variant: "error",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="Ref Num"
          variant="outlined"
          value={refNum}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setRefNum(event.target.value);
          }}
          fullWidth
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={active}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setActive(event.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Active"
          />
        </FormGroup>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <DatePicker
          label="Effective Date"
          value={effectiveDate}
          onChange={(value: any) => {
            setEffectiveDate(value);
          }}
          sx={{ width: "100%" }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(value: any) => {
            setEndDate(value);
          }}
          sx={{ width: "100%" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "justify-between",
          gap: "1rem",
          marginBottom: "1rem",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          disabled={isLoading}
          fullWidth
        >
          Save
        </Button>
        {id && <DeleteButton id={id} afterSaveCallback={afterSaveCallback} />}
      </Box>
    </Box>
  );
}

type PropsDeleteButton = {
  id: number;
  afterSaveCallback?: () => void;
};

function DeleteButton({ id, afterSaveCallback }: PropsDeleteButton) {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, mutateAsync } = ContractRefNumAPI.useDelete(id);

  const handleDelete = async () => {
    try {
      await mutateAsync();
      enqueueSnackbar("Deleted successfully", { variant: "success" });
      if (afterSaveCallback) {
        afterSaveCallback();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`Error archiving Ref Number: ${error?.message}`, {
        variant: "error",
      });
    }
  };

  const throttledHandleDelete = React.useCallback(
    debounce(handleDelete, 1000),
    [],
  );

  return (
    <Button
      variant="contained"
      color="error"
      onClick={throttledHandleDelete}
      disabled={isLoading}
      fullWidth
    >
      Delete
    </Button>
  );
}
