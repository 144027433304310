import { AddressInput } from "../types";

export default class Address {
  public id?: number;
  public addressLine1: string;
  public addressLine2: string;
  public addressLine3: string;
  public city: string;
  public state: string;
  public postalCode: string;
  public country: string;
  public archived: boolean;
  public createdAt?: Date;
  public addressableId?: number;
  public addressableType?: string;

  constructor({
    id,
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    state,
    postalCode,
    country,
    archived,
    createdAt,
    addressableId,
    addressableType,
  }: AddressInput) {
    this.id = id;
    this.addressLine1 = addressLine1;
    this.addressLine2 = addressLine2;
    this.addressLine3 = addressLine3;
    this.city = city;
    this.state = state;
    this.postalCode = postalCode;
    this.country = country;
    this.archived = archived;
    this.createdAt = createdAt;
    this.addressableId = addressableId;
    this.addressableType = addressableType;
  }

  static createEmpty() {
    return new Address({
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      archived: false,
    });
  }
}
