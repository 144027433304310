import { Box, Typography } from "@mui/material";
import React from "react";

import ContractProductPriceAPI from "../api/ContractProductPrice.api";
import ContractProductPriceModel from "../models/ContractProductPrice.model";
import { Product } from "../types";
import ContractProductPriceForm from "./ContractProductPriceForm";
import Loading from "./Loading";
import ProductSearchBarAutocomplete from "./ProductSearchBarAutocomplete";

type Props = {
  id?: number;
  contractId: number;
  handleClose: () => void;
};

export default function ContractProductPriceModal({
  id,
  contractId,
  handleClose,
}: Props) {
  if (id) {
    return (
      <ContractProductPriceModalEdit
        id={id}
        contractId={contractId}
        handleClose={handleClose}
      />
    );
  }
  return (
    <ContractProductPriceModalNew
      contractId={contractId}
      handleClose={handleClose}
    />
  );
}

type PropsContractProductPriceModalEdit = {
  id: number;
  contractId: number;
  handleClose: () => void;
};

function ContractProductPriceModalEdit({
  id,
  contractId,
  handleClose,
}: PropsContractProductPriceModalEdit) {
  const { data } = ContractProductPriceAPI.useDetail(id.toString());

  if (!data) {
    return <Loading />;
  }

  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
        {data.productNdc}
      </Typography>
      <ContractProductPriceForm
        contractId={contractId}
        productId={data.productId}
        contractProductPrice={data}
        handleClose={handleClose}
      />
    </>
  );
}

type PropsContractProductPriceModalNew = {
  contractId: number;
  handleClose: () => void;
};

function ContractProductPriceModalNew({
  contractId,
  handleClose,
}: PropsContractProductPriceModalNew) {
  const [currentProduct, setCurrentProduct] = React.useState<Product>();
  return (
    <>
      <Box sx={{ marginBottom: "1rem" }}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2 }}
        >
          Add Product to Contract
        </Typography>
        <ProductSearchBarAutocomplete
          product={currentProduct}
          setCurrentProduct={(product) => {
            setCurrentProduct(product);
          }}
        />
      </Box>
      {currentProduct && (
        <ContractProductPriceForm
          contractId={contractId}
          productId={currentProduct.id}
          contractProductPrice={ContractProductPriceModel.createEmpty({
            contractId,
            productId: currentProduct.id,
          })}
          handleClose={handleClose}
        />
      )}
    </>
  );
}
