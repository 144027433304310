import { Box, Button, TextField, Typography } from "@mui/material";
import { isNil } from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import UserAPI from "../api/User.api";
import Layout from "../components/Layout";
import { useSessionContext } from "../contexts/Session.context";

export default function ProfileEdit() {
  const { session } = useSessionContext();
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          justifyContent: "flexStart",
          gap: "1rem",
        }}
      >
        <Box sx={{ display: "flex", flexShrink: 1, flexDirection: "column" }}>
          <Typography variant="h2" sx={{ marginBottom: "1rem" }}>
            Profile
          </Typography>
        </Box>

        {session && (
          <ProfileForm profileEmail={session.email} userId={session.userId} />
        )}
      </Box>
    </Layout>
  );
}

type PropsProfileForm = {
  profileEmail: string;
  userId: number;
};

function ProfileForm({ profileEmail, userId }: PropsProfileForm) {
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = React.useState<string>(profileEmail);
  const [password, setPassword] = React.useState<string>();
  const [passwordConfirmation, setPasswordConfirmation] =
    React.useState<string>();

  const { mutateAsync: mutateConfirmEmail } = UserAPI.useUpdateEmail({
    userId,
    email,
  });

  const { mutateAsync: mutateUpdatePassword } = UserAPI.useUpdatePassword({
    password,
    passwordConfirmation,
  });

  const sendConfirmationEmail = async () => {
    try {
      const res = await mutateConfirmEmail();
      enqueueSnackbar(res.message, { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const handlePasswordUpdate = async () => {
    try {
      const res = await mutateUpdatePassword();
      enqueueSnackbar(res.message, { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          marginBottom: "1 rem",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}
      >
        <TextField
          label="Email"
          variant="outlined"
          value={email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
          }}
          fullWidth
        />
        {email !== profileEmail && (
          <Box sx={{ display: "flex", flexShrink: 1, height: "100%" }}>
            <Button onClick={sendConfirmationEmail} variant="contained">
              Update
            </Button>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          marginTop: "1rem",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <TextField
          label="Password"
          variant="outlined"
          value={password}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
          }}
          fullWidth
          type="password"
        />
        <TextField
          label="Password Confirmation"
          variant="outlined"
          value={passwordConfirmation}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPasswordConfirmation(event.target.value);
          }}
          fullWidth
          type="password"
        />
      </Box>
      {password === passwordConfirmation &&
        !isNil(password) &&
        !isNil(passwordConfirmation) && (
          <Box sx={{ display: "flex", marginTop: "1rem" }}>
            <Button
              onClick={handlePasswordUpdate}
              variant="contained"
              fullWidth
            >
              Update Password
            </Button>
          </Box>
        )}
    </Box>
  );
}
