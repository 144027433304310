import { Box, Typography } from "@mui/material";
import React from "react";

import BusinessUnitCodes from "../components/BusinessUnitCodes";
import BusinessUnitForm from "../components/BusinessUnitForm";
import Layout from "../components/Layout";
import { useBusinessUnitContext } from "../contexts/BusinessUnit.context";

export default function OnboardBusinessUnit() {
  const { currentBusinessUnit } = useBusinessUnitContext();
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2" sx={{ marginBottom: "1rem" }}>
          Onboard Business Unit
        </Typography>

        {currentBusinessUnit && (
          <BusinessUnitForm businessUnit={currentBusinessUnit} />
        )}

        {currentBusinessUnit && (
          <BusinessUnitCodes businessUnit={currentBusinessUnit} />
        )}
      </Box>
    </Layout>
  );
}
