import { Autocomplete, TextField } from "@mui/material";
import React from "react";

type Props = {
  getOptionLabel: (arg: any) => string;
  label: string;
  inputValue: string;
  noOptionsText: string;
  onChange: (event: any, newValue: any | null) => void;
  onInputChange: (event: any, newInputValue: string) => void;
  options: any[];
  value: any;
};

export default function SearchBarAutocomplete({
  getOptionLabel,
  label,
  inputValue,
  noOptionsText,
  onChange,
  onInputChange,
  options,
  value,
}: Props) {
  return (
    <Autocomplete
      id="searchbar-autocomplete"
      sx={{ width: "100%" }}
      options={options}
      renderInput={(params) => <TextField {...params} label={label} />}
      getOptionLabel={(option) => {
        return typeof option === "string" ? option : option.label;
      }}
      onChange={onChange}
      onInputChange={onInputChange}
      value={value}
    />
  );
}
