import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, Modal, TableCell, Typography } from "@mui/material";
import React from "react";

import ContractWholesalerEligibilityAPI from "../api/ContractWholesalerEligibility.api";
import { ContractWholesalerEligibility, Order, TableColumn } from "../types";
import { extractDate } from "../utils";
import ContractWholesalerEligibilityModal from "./ContractWholesalerEligibilityModal";
import ListTable from "./ListTable";
import Loading from "./Loading";

const columns: TableColumn<ContractWholesalerEligibility>[] = [
  {
    field: "wholesalerName",
    label: "Wholesaler Name",
    orderBy: false,
  },
  { field: "effectiveDate", label: "Effective Date" },
  { field: "endDate", label: "End Date" },
];

function buildTableRow(
  contractWholesalerEligibility: ContractWholesalerEligibility,
): JSX.Element[] {
  return [
    <TableCell key={"wholesalerName"}>
      {contractWholesalerEligibility.wholesalerName}
    </TableCell>,
    <TableCell key={"extractDate"}>
      {extractDate(contractWholesalerEligibility.effectiveDate)}
    </TableCell>,
    <TableCell key={"endDate"}>
      {extractDate(contractWholesalerEligibility.endDate)}
    </TableCell>,
  ];
}

type Props = {
  contractId: number;
  showSelectedWholesalers: boolean;
  children: JSX.Element;
};

export default function ContractWholesalerManagement({
  contractId,
  showSelectedWholesalers,
  children,
}: Props) {
  const [contractWholesalerEligibilityId, setContractWholesalerEligibilityId] =
    React.useState<number>();

  const [page, setPage] = React.useState<number>(0);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof ContractWholesalerEligibility>("endDate"); // TODO

  const { data, refetch } = ContractWholesalerEligibilityAPI.useList({
    contractId,
    order,
    orderBy,
    page,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ContractWholesalerEligibility,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick =
    (contractWholesalerEligibility: ContractWholesalerEligibility) =>
    (event: unknown) => {
      // TODO open modal for editing, edit view will have link to model
      handleContractWholesalerEligibilityClick(contractWholesalerEligibility);
    };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setContractWholesalerEligibilityId(undefined);
    setOpen(false);
    refetch();
  };

  const handleContractWholesalerEligibilityClick = (
    contractWholesalerEligibility: ContractWholesalerEligibility,
  ) => {
    setContractWholesalerEligibilityId(contractWholesalerEligibility.id);
    setOpen(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        marginTop: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="h6">Wholesalers in Contract</Typography>
        {showSelectedWholesalers && (
          <IconButton
            aria-label="Add a wholesaler to contract"
            size="large"
            onClick={handleOpen}
          >
            <AddCircleIcon color="primary" />
          </IconButton>
        )}
      </Box>
      {children}
      {showSelectedWholesalers && (
        <>
          {data ? (
            <ListTable
              columns={columns}
              buildTableRow={buildTableRow}
              handleChangePage={handleChangePage}
              handleRowClick={handleRowClick}
              handleRequestSort={handleRequestSort}
              meta={data.meta}
              order={order}
              orderBy={orderBy}
              rows={data.data}
            />
          ) : (
            <Loading />
          )}
        </>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <ContractWholesalerEligibilityModal
            id={contractWholesalerEligibilityId}
            contractId={contractId}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </Box>
  );
}
