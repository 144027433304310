import { Box, Typography } from "@mui/material";
import React from "react";

import GroupMemberAPI from "../api/GroupMember.api";
import GroupMemberEligibilityModel from "../models/GroupMemberEligibility.model";
import GroupMemberEligibilityForm from "./GroupMemberEligibilityForm";
import Loading from "./Loading";
import MemberSearchBarAutocomplete from "./MemberSearchBarAutocomplete";

type Props = {
  id?: number;
  groupId: number;
  handleClose: () => void;
};

export default function GroupMemberEligibilityModal({
  id,
  groupId,
  handleClose,
}: Props) {
  if (id) {
    return (
      <GroupMemberEligibilityModalEdit
        id={id}
        groupId={groupId}
        handleClose={handleClose}
      />
    );
  }
  return (
    <GroupMemberEligibilityModalNew
      groupId={groupId}
      handleClose={handleClose}
    />
  );
}

type PropsGroupMemberEligibilityModalEdit = {
  id: number;
  groupId: number;
  handleClose: () => void;
};

function GroupMemberEligibilityModalEdit({
  id,
  groupId,
  handleClose,
}: PropsGroupMemberEligibilityModalEdit) {
  const { data } = GroupMemberAPI.useDetail(id.toString());

  if (!data) {
    return <Loading />;
  }

  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
        {data.memberFacilityName}
      </Typography>
      <GroupMemberEligibilityForm
        groupId={groupId}
        memberId={data.memberId}
        groupMemberEligibility={data}
        handleClose={handleClose}
      />
    </>
  );
}

type PropsGroupMemberEligibilityModalNew = {
  groupId: number;
  handleClose: () => void;
};

function GroupMemberEligibilityModalNew({
  groupId,
  handleClose,
}: PropsGroupMemberEligibilityModalNew) {
  const [currentMemberId, setCurrentMemberId] = React.useState<number>();
  return (
    <>
      <Box sx={{ marginBottom: "1rem" }}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2 }}
        >
          Add Member to Group
        </Typography>
        <MemberSearchBarAutocomplete
          setCurrentMember={(member) => {
            setCurrentMemberId(member.id);
          }}
        />
      </Box>
      {currentMemberId && (
        <GroupMemberEligibilityForm
          groupId={groupId}
          memberId={currentMemberId}
          groupMemberEligibility={GroupMemberEligibilityModel.createEmpty({
            groupId,
            memberId: currentMemberId,
          })}
          handleClose={handleClose}
        />
      )}
    </>
  );
}
