import { ProductWacPriceInput } from "../types";

type CreateEmptyProps = {
  productId: number;
};

export default class ProductWacPrice {
  public id?: number;
  public priceCents: number;
  public price: string;
  public effectiveDate?: Date | null;
  public endDate?: Date | null;
  public createdAt?: Date;
  public productId?: number;
  public archived: boolean;

  constructor({
    id,
    priceCents,
    price,
    effectiveDate,
    endDate,
    createdAt,
    productId,
    archived,
  }: ProductWacPriceInput) {
    this.id = id;
    this.priceCents = priceCents;
    this.price = price;
    this.effectiveDate = effectiveDate;
    this.endDate = endDate;
    this.createdAt = createdAt;
    this.productId = productId;
    this.archived = archived;
  }

  static createEmpty({ productId }: CreateEmptyProps) {
    return new ProductWacPrice({
      productId,
      priceCents: 0,
      price: "0.00",
      archived: false,
    });
  }
}
