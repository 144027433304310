import { Box, Chip, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import ContractAPI from "../api/Contract.api";
import ContractForm from "../components/ContractForm";
import Layout from "../components/Layout";
import Loading from "../components/Loading";

export default function ContractEdit() {
  const { id } = useParams();
  const { data, isLoading } = ContractAPI.useDetail(id);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Typography variant="h2">Contract Edit </Typography>
          {data && data?.archived && <Chip label="Archived" color="error" />}
        </Box>

        {isLoading ? <Loading /> : data && <ContractForm contract={data} />}
      </Box>
    </Layout>
  );
}
