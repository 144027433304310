import { css } from "@emotion/css";
import { AppBar, Box, Container } from "@mui/material";
import React from "react";

import welcomeBgImg from "../assets/welcome-bg.jpg";
import ProfileNavigationMenu from "./ProfileNavigationMenu";

type Props = {
  children: JSX.Element;
};

export default function BusinessUnitLayout({ children }: Props) {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar position="static">
        <Container maxWidth="xl">
          <div
            className={css`
              display: flex;
              flex-grow: 1;
              flex-direction: column;
              justify-content: center;
              align-items: end;
            `}
          >
            <ProfileNavigationMenu />
          </div>
        </Container>
      </AppBar>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "stretch",
        }}
        style={{
          backgroundImage: `url(${welcomeBgImg})`,
          backgroundSize: "cover",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
