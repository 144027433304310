import { Box, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import BulkEditAPI from "../api/BulkEdit.api";
import ArchiveButton from "../components/ArchiveButton";
import Layout from "../components/Layout";

export default function BulkEditShow() {
  const { id } = useParams();
  const { data } = BulkEditAPI.useDetail(id);
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h2">Bulk Edit</Typography>
        {data && (
          <Box>
            <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
              <TextField
                disabled
                label="File Name"
                variant="standard"
                value={data.filename}
                fullWidth
              />
            </Box>
            <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
              <TextField
                disabled
                label="State"
                variant="standard"
                value={data.state}
                fullWidth
              />
            </Box>
            {data.state === "error" && (
              <React.Fragment>
                <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
                  Error: {data.errorMessage}
                </Box>
                <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
                  <Link target="_blank" to={data.fileWithErrors}>
                    Download File With Errors
                  </Link>
                </Box>
              </React.Fragment>
            )}
            <Box sx={{ marginBottom: "1rem", marginTop: "1rem" }}>
              <Link target="_blank" to={data.file}>
                Download File
              </Link>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "4rem" }}>
              {data && <ArchiveBulkEditButton id={data.id} />}
            </Box>
          </Box>
        )}
      </Box>
    </Layout>
  );
}

type ArchiveBulkEditButtonProps = {
  id: number;
};

function ArchiveBulkEditButton({ id }: ArchiveBulkEditButtonProps) {
  const navigate = useNavigate();

  const { mutateAsync } = BulkEditAPI.useDelete(id);
  const { enqueueSnackbar } = useSnackbar();

  const onDelete = async () => {
    const res = await mutateAsync();
    if ("errors" in res) {
      res.errors.forEach((message: string) =>
        enqueueSnackbar(message, { variant: "error" }),
      );
    } else {
      enqueueSnackbar("Archived successfully", { variant: "success" });
      navigate("/bulk-edits");
    }
  };

  return <ArchiveButton onDelete={onDelete} />;
}
